<template>
  <div class="container">
    <div class="container-title">{{ rowData.itMeName }}</div>
    <div class="container-cheks">
      <div class="container-checklist">
        <div
          class="container-checklist-init"
          :class="checkIndex == index ? 'container-checklist-change' : ''"
          v-for="(item, index) in checkList"
          :key="index"
          @click="clickCheckChange(item, index)"
        >
          {{ item.label }}
        </div>
      </div>
      <el-date-picker
        :value-format="formatTime"
        v-model="time"
        :type="typeTime"
        placeholder="选择日期"
        v-if="dateShow"
        @change="timeCahnfgwe"
      >
      </el-date-picker>
    </div>
    <div
      class="container-checklist container-checklistv"
      v-if="checkIndex == 0"
    >
      <div
        class="container-checklist-init container-checklistv-init"
        :class="checkIndexV == index ? 'container-checklist-change' : ''"
        v-for="(item, index) in checkListV"
        :key="index"
        @click="clickCheckChangeV(item, index)"
      >
        {{ item.label }}
      </div>
    </div>
    <div id="threeChart" v-show="chartShow"></div>
  </div>
</template>

<script>
import { statisticsINverterDETailCHARTGET } from "@/api/inverterAddition";
export default {
  name: "OverviewOfInverterseDetailsLine",
  props: {
    rowData: {
      type: Object,
      default: () => {
        return {};
      },
      required: true,
    },
  },
  data() {
    return {
      checkList: [
        {
          label: "日",
          value: "1",
        },
        {
          label: "月",
          value: "2",
        },
        {
          label: "年",
          value: "3",
        },
        {
          label: "历年",
          value: "4",
        },
      ],
      checkIndex: 0,
      checkListValue: 1,
      time: "",
      checkListV: [
        {
          label: "功率",
          value: "p",
        },
        {
          label: "MPTT电压",
          value: "u",
        },
        {
          label: "MPTT电流",
          value: "a",
        },
        {
          label: "组串电流",
          value: "sa",
        },
      ],
      checkIndexV: 0,
      checkListVValue: "p",
      windowHeight: null,
      windowWidth: null,
      formatTime: "yyyy-MM-dd",
      typeTime: "date",
      dateShow: true,
      chartShow: false,
    };
  },

  mounted() {
    this.windowHeight = window.screen.height;
    this.windowWidth = window.screen.width;
    this.time = this.$utilsMethods.formatDate(new Date(), "YYYY/MM/dd", true);
    this.chartShow = false;
    this.statisticsINverterDETailCHARTAxios();
  },

  methods: {
    timeCahnfgwe(e) {
      // console.log(e, "=========timeCahnfgwe");
      this.time = e;
      this.chartShow = false;
      this.statisticsINverterDETailCHARTAxios();
    },
    async statisticsINverterDETailCHARTAxios() {
      let self = this;
      const data_back = await statisticsINverterDETailCHARTGET({
        type: self.checkListValue,
        sn: self.rowData.itSlaveId,
        dateStr: self.checkListValue == 4 ? "" : self.time,
        function: self.checkListValue == 1 ? self.checkListVValue : "",
      });
      console.log(data_back, "=========statisticsINverterDETailCHARTGET");
      const { code, data } = data_back;
      if (code === 200) {
        const { header, val } = data;
        self.chartShow = true;
        if (val && val.length) {
          let list = [];
          val.forEach((item) => {
            list.push({
              name: item.name,
              data: item.data,
              type: "line",
            });
          });
          self.$nextTick(() => {
            self.getEchartThree(header && header.length ? header : [], list);
          });
        }
      }
    },
    clickCheckChange(row, index) {
      let self = this;
      self.checkIndex = index;
      self.checkListValue = row.value;
      self.checkIndexV = 0;
      self.checkListVValue = "p";
      self.chartShow = false;
      self.$nextTick(() => {
        switch (index) {
          case 0:
            self.formatTime = "yyyy-MM-dd";
            self.typeTime = "date";
            self.time = self.$utilsMethods.formatDate(
              new Date(),
              "YYYY/MM/dd",
              true
            );
            self.dateShow = true;
            break;
          case 1:
            self.formatTime = "yyyy-MM";
            self.typeTime = "month";
            self.time = self.$utilsMethods.formatDate(
              new Date(),
              "YYYY/MM",
              true
            );
            self.dateShow = true;
            break;
          case 2:
            self.formatTime = "yyyy";
            self.typeTime = "year";
            self.time = self.$utilsMethods.formatDate(new Date(), "YYYY", true);
            self.dateShow = true;
            break;
          case 3:
            self.time = "";
            self.dateShow = false;
            break;
        }
        self.statisticsINverterDETailCHARTAxios();
      });
    },
    clickCheckChangeV(row, index) {
      this.checkIndexV = index;
      this.checkListVValue = row.value;
      this.chartShow = false;
      this.statisticsINverterDETailCHARTAxios();
    },
    getEchartThree(header, val) {
      console.log(header, val, "pppppppp");
      let myChart3 = this.$echarts.init(document.getElementById("threeChart"));
      myChart3.clear(); //先清掉图表，再重绘图表
      myChart3.setOption({
        tooltip: {
          trigger: "axis",
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: header,
          axisLabel: {
            color: "#fff",
            interval: 0, //使x轴文字显示全
          },
        },
        yAxis: [
          {
            axisTick: {
              show: false,
            },
            axisLine: {
              show: false,
            },
            name: "V",
            nameTextStyle: {
              color: "#06B4FC",
            },
            type: "value",
            splitLine: {
              lineStyle: {
                color: "#0B3A56",
              },
            },
            axisLabel: {
              color: "#06B4FC",
            },
          },
        ],
        series: val,
        grid: {
          left: this.nowWidthSize(50),
          top: this.nowHeightSize(30),
          right: this.nowWidthSize(25),
          bottom: this.nowHeightSize(25),
          containLabel: true,
        },
      });
      window.addEventListener("resize", function () {
        myChart3.resize();
      });
    },
    nowWidthSize(val) {
      let width = document.documentElement.clientWidth;
      let size = (width * val) / this.windowWidth;
      console.log(size, "size");
      return size;
    },
    nowHeightSize(val) {
      let height = document.documentElement.clientHeight;
      let size = (height * val) / this.windowHeight;
      console.log(size, "size");
      return size;
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  padding: 1.0417vw /* 20px -> 1.0417vw */ 0;
  &-title {
    font-family: AlibabaPuHuiTi-Medium, AlibabaPuHuiTi;
    font-weight: 500;
    color: #02b5ff;
    font-size: 1.4583vw /* 28px -> 1.4583vw */;
    padding: 1.0417vw /* 20px -> 1.0417vw */ 0;
    text-align: center;
  }
  &-cheks {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;
  }
  &-checklist {
    display: flex;
    flex-wrap: wrap;
    margin-right: 1.5625vw /* 30px -> 1.5625vw */;
    &-init {
      color: #02b5ff;
      font-size: 0.625vw /* 12px -> .625vw */;
      font-family: AlibabaPuHuiTi-Medium, AlibabaPuHuiTi;
      font-weight: 500;
      background: url("~@/assets/icon/icon1.png") no-repeat;
      background-size: 100% 100%;
      min-width: 2.3958vw /* 46px -> 2.3958vw */;
      height: 1.0417vw /* 20px -> 1.0417vw */;
      line-height: 1.0417vw /* 20px -> 1.0417vw */;
      text-align: center;
      cursor: pointer;
      margin-right: 0.5208vw /* 10px -> .5208vw */;
      padding: .5208vw /* 10px -> .5208vw */ 1.0417vw /* 20px -> 1.0417vw */;
      white-space: nowrap;
    }
    &-change {
      color: #ffffff !important;
    }
    &-init:last-child {
      margin-right: 0;
    }
  }
  &-checklistv {
    margin-bottom: 2.0833vw /* 40px -> 2.0833vw */;
    &-init {
      font-size: 1.0417vw /* 20px -> 1.0417vw */;
      min-width: 7.2917vw /* 140px -> 7.2917vw */;
      height: 2.6042vw /* 50px -> 2.6042vw */;
      line-height: 2.6042vw /* 50px -> 2.6042vw */;
      margin-right: 1.0417vw /* 20px -> 1.0417vw */;
      padding: .5208vw /* 10px -> .5208vw */ 1.0417vw /* 20px -> 1.0417vw */;
      white-space: nowrap;
    }
  }
}
#threeChart {
  width: 100%;
  height: 23.4375vw /* 450px -> 23.4375vw */;
}
::v-deep .distpicker-address-wrapper select {
  width: 10.3vw;
  height: 4.6vh;
  background: #0f2455;
  border: 1px solid #286ae8;
  color: #fff;
  margin-right: 1vw;
  font-size: calc(100vw * 16 / 1920);
}
::v-deep .el-input__inner {
  width: 100%;
  height: 2.0833vw /* 40px -> 2.0833vw */;
  font-size: calc(100vw * 16 / 1920);
  box-shadow: inset 0px 0px 26px 10px rgba(44, 98, 230, 0.28);
  border-radius: 6px;
  border: 1px solid #286ae8;
  background: none;
  color: #fff;
  &::placeholder {
    color: #fff;
  }
}
::v-deep .el-input__icon {
  line-height: 1.4063vw /* 27px -> 1.4063vw */;
}
</style>
