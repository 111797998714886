<template>
  <div class="container">
    <div class="left-title" v-if="$route.query.type == 12">总用能排行</div>
    <div
      class="left-title"
      style="width: 12.6vw"
      v-if="$route.query.type == 13"
    >
      市电太阳能用能排行
    </div>
    <div class="back" @click="backPrev">返回</div>
    <div class="right-container">
      <div class="right-top">
        <el-form ref="form" :model="form">
          <div
            style="display: flex; justify-content: space-between; width: 70vw"
          >
            <el-form-item label="全局搜索：">
              <el-input
                v-model="form.searchContent"
                placeholder="请输入电站名称"
                style="width: 15.6vw"
              ></el-input>
            </el-form-item>
          </div>

          <div style="display: flex; justify-content: space-between">
            <el-form-item label="报文时间：" v-show="$route.query.date != '1'">
              <el-date-picker
                style="width: 10.3vw; margin-right: 1vw"
                v-model="form.startDate"
                type="month"
                format="yyyy-MM"
                placeholder="年/月"
                @change="changeDate"
              >
              </el-date-picker>
            </el-form-item>
            <div
              style="
                display: flex;
                width: 100%;
                height: 4.6vh;
                margin-bottom: 2vh;
                justify-content: flex-end;
              "
            >
              <div class="query" @click="query">查询</div>
              <div class="query" @click="reset">重置</div>
              <div class="reset" @click="exportData">导出</div>
            </div>
          </div>
        </el-form>
      </div>
      <el-table
        :data="powerGeneration"
        :header-cell-style="headerStyle"
        :cell-style="rowStyle"
      >
        <el-table-column type="index" label="序号" width="55"></el-table-column>
        <el-table-column prop="psName" label="电站名称"></el-table-column>
        <el-table-column prop="area" label="区域"></el-table-column>
        <el-table-column prop="sumTe" label="总用电量"></el-table-column>
        <el-table-column prop="ste" label="市电"></el-table-column>
        <el-table-column prop="tte" label="太阳能"></el-table-column>
        <el-table-column prop="tratio" label="太阳能比例"></el-table-column>
        <el-table-column prop="dt" label="报文时间"></el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[8, 16, 24, 32]"
        :page-size="100"
        layout="sizes, prev, pager, next , total"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { getTotalEleTable, exportTotalEleTable } from "@/api/dataAnalysis";
export default {
  name: "totalEnergyConsumption",
  data() {
    return {
      form: {
        searchContent: "", // 全局搜索
        startDate: "", // 报文时间
      },
      powerGeneration: [],
      currentPage: 1,
      pageSize: 8,
      total: 0,
      month: "",
      year: "",
    };
  },
  methods: {
    headerStyle() {
      return "background:#0F2455;color:#fff;border: 1px solid #286AE8;text-align:center";
    },
    rowStyle() {
      return "background:#0F2455;color:#fff;border: 1px solid #286AE8;text-align:center";
    },
    handleSizeChange(val) {
      this.pageSize = val;
      if (this.$route.query.type == 12) {
        this.getList();
      }
    },
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage;
      if (this.$route.query.type == 12) {
        this.getList();
      }
    },
    formateDate(datetime) {
      function addDateZero(num) {
        return num < 10 ? "0" + num : num;
      }
      let d = new Date(datetime);
      let formatdatetime =
        d.getFullYear() + "-" + addDateZero(d.getMonth() + 1);
      return formatdatetime;
    },
    changeDate() {
      function addDateZero(num) {
        return num < 10 ? "0" + num : num;
      }
      let y = this.form.startDate;
      this.year = y.getFullYear();
      this.month = addDateZero(y.getMonth() + 1);
      console.log(this.month);
    },
    // 返回
    backPrev() {
      let type = this.$route.query.type;
      if (this.fromPage == "/energyConsumption") {
        this.$router.push("/energyConsumption");
      } else if (this.fromPage == "/index") {
        this.$router.push("/index");
      } else if (this.fromPage == "/energyConsumption") {
        this.$router.push("/energyConsumption");
      } else {
        this.$router.push({
          path: "/rankingTotalEnergy",
          query: { type },
        });
      }
    },
    getList() {
      if (this.form.startDate != "") {
        this.form.startDate = this.formateDate(this.form.startDate);
      } else {
        this.form.startDate = new Date();
        let y = this.form.startDate;
        this.year = y.getFullYear();
        this.month = addDateZero(y.getMonth() + 1);
      }
      function addDateZero(num) {
        return num < 10 ? "0" + num : num;
      }
      if (this.$route.query.date == "1") {
        this.form.startDate = new Date();
        let y = this.form.startDate;
        this.year = y.getFullYear();
        this.month = addDateZero(y.getMonth() + 1);
        let data = {
          month: this.month,
          year: this.year,
          pageSize: this.pageSize,
          pageNum: this.currentPage,
          type: this.$route.query.date,
          title: this.form.searchContent,
        };
        getTotalEleTable(data).then((res) => {
          console.log(res);
          this.powerGeneration = res.data;
          this.total = res.total;
        });
      } else {
        let arr = this.form.startDate.split("-");
        this.year = arr[0];
        this.month = arr[1];
        let data = {
          month: this.month,
          year: this.year,
          pageSize: this.pageSize,
          pageNum: this.currentPage,
          type: this.$route.query.date,
          title: this.form.searchContent,
        };
        getTotalEleTable(data).then((res) => {
          console.log(res);
          this.powerGeneration = res.data;
          this.total = res.total;
        });
      }
    },
    // 查詢
    query() {
      this.currentPage = 1;
      this.getList();
    },
    // 重置
    reset() {
      if (this.$route.query.date == "1") {
        this.form.searchContent = "";
        this.getList();
      } else {
        this.form.searchContent = "";
        this.form.startDate = "";
        this.getList();
      }
    },
    // 导出
    exportData() {
      let data = {
        title: this.form.searchContent,
        type: this.$route.query.date,
        month: this.month,
        year: this.year,
      };
      exportTotalEleTable(data).then((res) => {
        console.log(res, "的撒很激动i");
        let name = res.msg;
        if (res.code == 200) {
          this.$axios({
            url:
              "https://admin.pvshield.cn/guang/common/download?fileName=" +
              name +
              "&delete=true",
            methods: "GET",
            responseType: "blob",
            headers: {
              "Content-Type": "application/json",
            },
          }).then((res) => {
            console.log(res, "sdhuahui");
            const blob = new Blob([res.data], {
              type: "application/vnd.ms-excel",
            });
            console.log(blob, "----------0990");
            const fileName = name + ".xls";
            const linkNode = document.createElement("a");

            linkNode.download = fileName; //a标签的download属性规定下载文件的名称
            linkNode.style.display = "none";
            linkNode.href = URL.createObjectURL(blob); //生成一个Blob URL
            document.body.appendChild(linkNode);
            linkNode.click(); //模拟在按钮上的一次鼠标单击

            URL.revokeObjectURL(linkNode.href); // 释放URL 对象
            document.body.removeChild(linkNode);
          });
        }
      });
    },
  },
  mounted() {
    this.getList();
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.fromPage = from.path;
    });
  },
};
</script>

<style lang="scss" scoped>
.container::v-deep {
  margin-top: 2vh;
  width: 100%;
  position: relative;
  .left-title {
    position: absolute;
    left: 0;
    top: 0;
    background: url(@/assets/images/box22.png);
    background-size: 100% 100%;
    width: 11.1vw;
    height: 5.5vh;
    color: #fff;
    font-size: calc(100vw * 20 / 1920);
    text-align: center;
    line-height: 5.5vh;
    margin-right: 7.5vw;
  }
  .back {
    position: absolute;
    top: 8vh;
    background: url(@/assets/images/box22.png);
    background-size: 100% 100%;
    width: 11.1vw;
    height: 5.5vh;
    color: #fff;
    font-size: calc(100vw * 20 / 1920);
    text-align: center;
    line-height: 5.5vh;
    cursor: pointer;
  }
  .right-container {
    width: 70vw;
    position: absolute;
    left: 10vw;
    right: 0;
    margin: 0 auto;
    .right-top {
      margin: 0 auto;
      width: 70vw;
      //   margin-bottom: 3vh;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .el-form-item {
        display: flex;
        align-items: center;
        height: 4.6vh;
        .el-select-dropdown__item .selected,
        .el-select-dropdown__item {
          font-size: calc(100vw * 16 / 1920);
        }
        .el-input__inner {
          width: 100%;
          height: 4.6vh;
          font-size: calc(100vw * 16 / 1920);
          box-shadow: inset 0rem 0rem 0.43rem 0.17rem rgba(44, 98, 230, 0.28);
          border-radius: 6px;
          border: 0.02rem solid #286ae8;
          background: none;
          color: #fff;
          &::placeholder {
            color: #fff;
          }
        }
        .el-form-item__label {
          color: #fff;
          font-size: calc(100vw * 16 / 1920);
        }
      }
      .query,
      .reset {
        width: 7.2vw;
        height: 100%;
        background: url(@/assets/images/updown.png);
        background-size: 100% 100%;
        color: #02b5ff;
        font-size: calc(100vw * 16 / 1920);
        line-height: 4.6vh;
        text-align: center;
        cursor: pointer;
      }
      .query {
        margin-right: 1.5vw;
      }
    }
  }
}
::v-deep .el-form-item__label {
  width: 7.3vw;
  text-align: left;
  color: #fff;
  font-size: calc(100vw * 16 / 1920);
}
::v-deep .el-table__body {
  font-size: calc(100vw * 14 / 1920);
}
::v-deep .el-table {
  width: 99.9%;
  margin-bottom: 2.5vh;
  font-size: calc(100vw * 16 / 1920);
}
.el-table--border::after,
.el-table--group::after,
.el-table::before {
  display: none;
}
::v-deep .el-pagination {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1vh;
}
::v-deep .el-pagination .el-select .el-input .el-input__inner,
::v-deep .el-pagination .btn-next,
::v-deep .el-pagination .btn-prev,
::v-deep .el-pager li,
::v-deep .el-pager li.btn-quicknext,
::v-deep .el-pager li.btn-quickprev,
::v-deep .el-pagination__total {
  background: #0f2455;
  border-radius: 6px;
  border: 1px solid #286ae8;
  color: #fff;
  text-align: center;
}
::v-deep .el-pager li,
::v-deep .el-pagination .btn-next,
::v-deep .el-pagination .btn-prev {
  margin-right: 0.5vw;
}
::v-deep .el-pagination__total {
  margin-right: 0;
  padding: 0 0.8vw;
  box-sizing: border-box;
}
::v-deep .distpicker-address-wrapper select {
  width: 10.3vw;
  background: #0f2455;
  border: 1px solid #286ae8;
  color: #fff;
  margin-left: 1vw;
}
::v-deep .el-pager li.active {
  color: #409eff;
}
</style>
