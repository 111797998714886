function formateDate(datetime) {
    function addDateZero(num) {
      return num < 10 ? "0" + num : num;
    }
    let d = new Date(datetime);
    let formatdatetime =
      d.getFullYear() +
      "-" +
      addDateZero(d.getMonth() + 1) +
      "-" +
      addDateZero(d.getDate()) +
      " " +
      addDateZero(d.getHours()) +
      ":" +
      addDateZero(d.getMinutes()) +
      ":" +
      addDateZero(d.getSeconds());
    return formatdatetime;
  }

  function division(str) {
    var s1 = str/1
    return s1
  }

  function formateMonth(datetime) {
    function addDateZero(num) {
      return num < 10 ? "0" + num : num;
    }
    let d = new Date(datetime);
    let formatdatetime =
      d.getFullYear() + "-" + addDateZero(d.getMonth() + 1);
    return formatdatetime;
  }

  function formateYear(datetime) {
    let d = new Date(datetime);
    let formatdatetime = d.getFullYear();
    return formatdatetime;
  }

  
  

  export {formateDate,division,formateMonth,formateYear}
