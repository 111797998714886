import requests from './request'

// 获取设备
export function getElectricity(){
    return requests.get('/custom/yge/electricity')
}

// 累计发电及收益
export function getElectricityAndProfit(){
    return requests.get('/custom/yge/electricityALl')
}

// 装机容量
export function getCapacity(){
    return requests.get('/custom/yge/installedCapacity')
}

// 电站排名
export function getStationRanking(){
    return requests.get('/custom/yge/stationRanking')
}

// 节能减排
export function getReduceEnergy(){
    return requests.get('/custom/yge/emReduction')
}

// 发电量统计折线图
export function getElePic(){
    return requests.get('/custom/yge/electricity1')
}

// 发电量统计台账
export function getEleList(data){
    return requests.get('/custom/yge/electricity2?bTime=' + data.bTime + '&pageNum=' + data.pageNum + '&pageSize=' + data.pageSize + '&title=' + data.title,data)
}

// 等效时长图表
export function getHourTime(data){
    return requests.get('/custom/yge/photovoltaicDetail5?type=' + data.type + '&psId=' + data.psId + '&dateStr=' + data.dateStr,data)
}

// 电站发电量
export function getStationEle(data){
    return requests.get('/custom/yge/photovoltaicDetail4?type=' + data.type + '&psId=' + data.psId + '&dateStr=' + data.dateStr,data)
}

// 数据分析-电站发电量
export function getStationEle_(data){
    return requests.get('/custom/yge/photovoltaicDetail4?type=' + data.type + '&psId=' + data.psId + '&psCapacity=' + data.psCapacity + '&dateStr=' + data.dateStr,data)
}

// 并网功率
export function getConnectedPower(data){
    return requests.get('/custom/yge/photovoltaicDetail6?bTime=' + data.bTime + '&psId=' + data.psId,data)
}

// 绿色能源
export function getGreenPower(psId){
    return requests.get('/custom/yge/photovoltaicDetail1?psId=' + psId)
}

// 电站基本信息
export function getDefaultInfo(psId){
    return requests.get('/custom/yge/photovoltaicDetail?psId=' + psId)
}

// 电站总览
export function getStationOverview(psId){
    return requests.get('/custom/yge/photovoltaicDetail2?psId=' + psId)
}

// 实时数据、pr
export function getRealData(psId){
    return requests.get('/custom/yge/photovoltaicDetail3?psId=' + psId)
}

// 设备概况台账
export function getEquipmentList(title,bTime){
    return requests.get('/custom/yge/photovoltaicDetail7?title=' + title + '&bTime=' + bTime)
}

// 设备概况台账导出
export function exportEquipmentList(){
    return requests.get('/custom/yge/importPhotovoltaicDetail7')
}

// 设备监控
export function getDevice(psId){
    return requests.get('/custom/yge/deviceMonitor?psId=' + psId)
}

// 绿色能源视频管理
export function getVideoManage(){
    return requests.get('/system/video/getInfo')
}

