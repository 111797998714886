<template>
  <div class="container">
    <div class="left-title">企业信息管理</div>
    <div class="right-container">
      <div class="right-top">
        <el-form ref="form" :model="form">
          <div
            style="width: 66.9vw; display: flex; justify-content: space-between"
          >
            <el-form-item label="区域：">
              <el-input
              placeholder="请输入区域"
                v-model="form.region"
                style="width: 11vw"
              >
              </el-input>
            </el-form-item>
            <el-form-item label="企业名称：">
              <el-input
              placeholder="请输入企业名称"
                v-model="form.companyName"
                style="width: 15.6vw"
              ></el-input>
            </el-form-item>
            <div style="display: flex; height: 4.6vh">
              <div class="query" @click="addNew">添加</div>
              <div class="query" @click="query">查询</div>
              <div class="reset" @click="clearSearch">重置</div>
            </div>
          </div>
        </el-form>
      </div>
      <el-table
        :data="companyList"
        :header-cell-style="headerStyle"
        :cell-style="rowStyle"
      >
        <el-table-column type="index" label="序号" width="60"></el-table-column>
        <el-table-column prop="etName" label="企业名称"></el-table-column>
        <el-table-column prop="etProvince" label="区域"></el-table-column>
        <el-table-column prop="etAddress" label="详细地址"></el-table-column>
        <el-table-column label="企业电站数量" align="center">
          <template slot-scope="scope">
            <div
              style="color: #02b5ff; width: 100%; height: 100%; cursor: pointer"
              @click="goStationInfo(scope.row)"
            >
              {{ scope.row.gfPowerStationList.length }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button size="mini" type="text" @click="handleEdit(scope.row)"
              >编辑</el-button
            >
            <el-button size="mini" type="text" @click="handleDelete(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[8, 16, 24, 32]"
        :page-size="100"
        layout="sizes, prev, pager, next , total"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { getCompanyInfoList, deleteCompany } from "@/api/index";
export default {
  name: "systemSetting",
  data() {
    return {
      form: {
        region: "",
        companyName: "",
      },
      companyList: [],
      currentPage: 1,
      pageSize: 8,
      total: 0,
    };
  },
  methods: {
    headerStyle() {
      return "background:#0F2455;color:#fff;border: 1px solid #286AE8;text-align:center";
    },
    rowStyle() {
      return "background:#0F2455;color:#fff;border: 1px solid #286AE8;text-align:center";
    },
    handleSizeChange(val) {
      this.pageSize = val
      this.getList();
    },
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage;
      this.getList();
    },
    handleEdit(row) {
      console.log(row, 111);
      let content = JSON.stringify(row);
      this.$router.push({
        path: "/addCompanyInfo",
        query: {
          content: content,
        },
      });
    },
    // 获取公司列表
    getList() {
      let that = this;
      let data = {
        pageNum: that.currentPage,
        pageSize: that.pageSize,
        etProvince: that.form.region,
        etName: that.form.companyName,
      };
      getCompanyInfoList(data).then((res) => {
        that.companyList = res.rows;
        this.total = res.total;
      });
    },
    // 查询
    query() {
      console.log(11);
      this.currentPage = 1
      this.getList();
    },
    // 跳转到电站信息管理
    goStationInfo(row) {
      console.log(row, "mnb");
      this.$router.push({
        path: "/stationInfo",
        query: {
          id: row.id,
        },
      });
    },
    // 删除
    handleDelete(row) {
      let id = row.id;
      this.$confirm("是否确定删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deleteCompany(id).then((res) => {
          if (res.code == 200) {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
          }
          this.getList();
        });
      });
    },
    // 新增企业
    addNew() {
      this.$router.push("/addCompanyInfo");
    },
    // 重置
    clearSearch(){
      this.form.companyName = ''
      this.form.region = ''
      this.getList()
    }
  },
  mounted() {
    console.log(this.$router, 111);
    // if (location.href.indexOf("#reloaded") == -1) {
    //   location.href = location.href + "#reloaded";

    //   location.reload();
    // }
    this.getList();
  },
};
</script>

<style lang="scss" scoped>
.container::v-deep {
  margin-top: 2vh;
  width: 100%;
  position: relative;
  .left-title {
    position: absolute;
    left: 0;
    top: 0;
    background: url(@/assets/images/titleBg.png);
    background-size: 100% 100%;
    width: 11.1vw;
    height: 5.5vh;
    color: #fff;
    font-size: calc(100vw * 20 / 1920);
    text-align: center;
    line-height: 5.5vh;
    margin-right: 7.5vw;
  }
  .right-container {
    width: 66.4vw;
    position: absolute;
    left: 7vw;
    right: 0.1vw;
    margin: 0 auto;
    .right-top {
      margin: 0 auto;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .el-form-item {
        display: flex;
        align-items: center;
        height: 4.6vh;
        .el-select-dropdown__item .selected,
        .el-select-dropdown__item {
          font-size: calc(100vw * 16 / 1920);
        }
        .el-input__inner {
          width: 100%;
          height: 4.6vh;
          font-size: calc(100vw * 16 / 1920);
          box-shadow: inset 0rem 0rem 0.43rem 0.17rem rgba(44, 98, 230, 0.28);
          border-radius: 6px;
          border: 0.02rem solid #286ae8;
          background: none;
          color: #fff;
          &::placeholder {
            color: #fff;
          }
        }
        .el-form-item__label {
          width: 6vw;
          text-align: left;
          color: #fff;
          font-size: calc(100vw * 16 / 1920);
        }
      }
      .query,
      .reset {
        width: 7.2vw;
        height: 100%;
        background: url(@/assets/images/updown.png);
        background-size: 100% 100%;
        color: #02b5ff;
        font-size: calc(100vw * 16 / 1920);
        line-height: 4.6vh;
        text-align: center;
        cursor: pointer;
      }
      .query {
        margin-right: 1.5vw;
      }
    }
  }
}
::v-deep .el-table {
  width: 99.9%;
  margin-bottom: 2.5vh;
  font-size: calc(100vw * 16 / 1920);
}
::v-deep .el-table__body {
  font-size: calc(100vw * 14 / 1920);
}
// ::v-deep .el-table .cell {
//     overflow: hidden; // 溢出隐藏
//     white-space: nowrap; // 强制一行
//     text-overflow: ellipsis; // 文字溢出显示省略号
// }
.el-table--border::after,
.el-table--group::after,
.el-table::before {
  display: none;
}
::v-deep .el-pagination {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1vh;
}
::v-deep .el-pagination .el-select .el-input .el-input__inner,
::v-deep .el-pagination .btn-next,
::v-deep .el-pagination .btn-prev,
::v-deep .el-pager li,
::v-deep .el-pager li.btn-quicknext,
::v-deep .el-pager li.btn-quickprev,
::v-deep .el-pagination__total {
  background: #0f2455;
  border-radius: 6px;
  border: 1px solid #286ae8;
  color: #fff;
  text-align: center;
}
::v-deep .el-pager li,
::v-deep .el-pagination .btn-next,
::v-deep .el-pagination .btn-prev {
  margin-right: 0.5vw;
}
::v-deep .el-pagination__total {
  margin-right: 0;
  padding: 0 0.8vw;
  box-sizing: border-box;
}
::v-deep .el-pager li.active {
  color: #409EFF;
}
</style>
