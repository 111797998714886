<template>
  <div class="container">
    <div class="left-part">
      <div class="equipment-title" v-if="$route.query.id == undefined">
        抄表器录入
      </div>
      <div class="equipment-title" v-if="$route.query.id != undefined">
        抄表器编辑
      </div>
    </div>
    <div style="width: 80vw; height: 100%">
      <el-form :model="form" ref="form" :rules="rules">
        <div class="form-item">
          <el-form-item label="企业名称：" prop="etId">
            <el-select
              placeholder="请选择企业名称"
              v-model="form.etId"
              style="width: 20vw"
              @change="changeCompany"
            >
              <el-option
                v-for="(item, index) in companyList"
                :key="index"
                :label="item.etName"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <div class="tip">（必填）</div>
          <el-form-item label="电站名称：" prop="psId">
            <el-select
              placeholder="请选择电站名称"
              v-model="form.psId"
              style="width: 20vw"
              :disabled="disabled"
            >
              <el-option
                v-for="(item, index) in stationList"
                :key="index"
                :label="item.psName"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <div class="tip">（必填）</div>
        </div>
        <div class="form-item">
          <el-form-item label="表号：" prop="mrMeCard">
            <el-input
              v-model="form.mrMeCard"
              style="width: 20vw"
              placeholder="请输入表号"
            ></el-input>
          </el-form-item>
          <div class="tip">（必填）</div>
          <el-form-item label="倍率：" prop="mrMagnification">
            <el-input
              placeholder="请输入倍率"
              type="number"
              v-model="form.mrMagnification"
              style="width: 20vw"
            ></el-input>
          </el-form-item>
          <div class="tip">（必填）</div>
        </div>
        <!-- <div class="form-item">
          <el-form-item label="区域(省市县)：">
            <v-distpicker
              @province="provinceChange"
              @city="cityChange"
              @area="areaChange"
              :province="form.itProvince"
              :city="form.mrCity"
              :area="form.mrArea"
            ></v-distpicker>
          </el-form-item>
          <div class="tip">（必填）</div>
        </div> -->
        <!-- <div class="form-item"> -->
        <!-- <el-form-item label="详细地址：" prop="mrAddress">
            <el-input v-model="form.mrAddress" style="width: 20vw"></el-input>
          </el-form-item>
          <div class="tip">（必填）</div> -->

        <!-- </div> -->
        <div class="form-item">
          <el-form-item label="设备类型：" prop="mrType">
            <el-select
              v-model="form.mrType"
              style="width: 20vw"
              placeholder="请选择设备类型"
            >
              <el-option
                v-for="(item, index) in typeList"
                :key="index"
                :label="item.typeName"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <div class="tip">（必填）</div>
          <el-form-item label="上传频率：" prop="mrUf">
            <el-input
              v-model="form.mrUf"
              style="width: 20vw"
              placeholder="请输入上传频率"
            ></el-input>
          </el-form-item>
          <div class="tip">（必填）</div>
        </div>
        <div class="form-item" v-if="form.mrType == 1">
          <el-form-item label="电表类型：" prop="mrWhType">
            <el-select
              v-model="form.mrWhType"
              style="width: 20vw"
              placeholder="请选择电表类型"
            >
              <el-option label="总电表" value="1"></el-option>
              <el-option label="光伏电表" value="2"></el-option>
            </el-select>
          </el-form-item>
          <div class="tip">（必填）</div>
        </div>
        <div class="form-item">
          <el-form-item label="节点MAC码：" prop="mrMac">
            <el-input
              v-model="form.mrMac"
              style="width: 20vw"
              placeholder="请输入节点MAC码"
            ></el-input>
          </el-form-item>
          <div class="tip">（必填）</div>
          <el-form-item label="抄表器型号：" prop="deviceType">
            <el-switch
              v-model="form.deviceType"
              active-color="#409EFF"
              inactive-color="#C0C4CC"
            >
            </el-switch>
          </el-form-item>
          <div class="tip">（必填）</div>
        </div>
        <div class="form-item">
          <el-form-item label="从机ID：" prop="mrSaveId">
            <el-input
              v-model="form.mrSaveId"
              style="width: 20vw"
              placeholder="请输入从机ID"
            ></el-input>
          </el-form-item>
          <div class="tip">（必填）</div>
        </div>
        <div class="form-item">
          <el-form-item label="表规则：" prop="regularId">
            <el-select
              placeholder="请选择表规则"
              v-model="form.regularId"
              style="width: 20vw"
            >
              <el-option
                v-for="(item, index) in regularNameList"
                :key="index"
                :label="item.regulationName"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <div class="tip">（必填）</div>
        </div>
        <div class="form-item">
          <el-form-item label="表名称：" prop="mrMeName">
            <el-input
              v-model="form.mrMeName"
              style="width: 20vw"
              placeholder="请输入表名称"
            ></el-input>
          </el-form-item>
          <div class="tip">（必填）</div>
          <div
            class="add-btn"
            @click="addReader('form')"
            v-if="$route.query.id == undefined"
          >
            添加
          </div>
          <div
            class="add-btn"
            @click="modify('form')"
            v-if="$route.query.id != undefined"
          >
            修改
          </div>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import {
  getEquipmentType,
  getCompanyList,
  getStationList,
  addNewReader,
  getDetailReader,
  modifyReader,
  regulationSelectListGET,
} from "@/api/equipment";
import VDistpicker from "v-distpicker";
export default {
  name: "addMeterReading",
  components: {
    VDistpicker,
  },
  data() {
    return {
      form: {
        etId: "", // 企业名称
        psId: "", // 电站名称
        mrMeCard: "", //表号
        mrMagnification: 0, // 倍率
        mrType: "", // 设备类型
        mrUf: "", // 上传频率
        mrWhType: "", // 选择电表
        mrMac: "", // 节点MAC码
        mrSaveId: "", // 从机ID
        mrMeName: "", // 表名称
        deviceType: false, //抄表器型号
      },
      typeList: [], // 设备类型
      companyList: [], // 企业列表
      stationList: [], // 电站列表
      disabled: true, // 禁选电站名称
      regularNameList: [],
      rules: {
        etId: [
          { required: true, message: "企业名称不能为空", trigger: "blur" },
        ],
        psId: [
          { required: true, message: "电站名称不能为空", trigger: "blur" },
        ],
        mrType: [
          { required: true, message: "设备类型不能为空", trigger: "blur" },
        ],
        mrWhType: [
          { required: true, message: "电表类型不能为空", trigger: "blur" },
        ],
        mrSaveId: [
          { required: true, message: "从机ID不能为空", trigger: "blur" },
        ],
        mrMac: [
          { required: true, message: "节点MAC码不能为空", trigger: "blur" },
        ],
        mrMeName: [
          { required: true, message: "抄表名称不能为空", trigger: "blur" },
        ],
        mrMeCard: [
          { required: true, message: "抄表号不能为空", trigger: "blur" },
        ],
        mrMagnification: [
          { required: true, message: "倍率不能为空", trigger: "blur" },
        ],
        mrUf: [
          { required: true, message: "上传频率不能为空", trigger: "blur" },
        ],
        deviceType: [
          {
            required: true,
            message: "请选择抄表器型号",
            trigger: ["blur", "change"],
          },
        ],
        regularId: [
          {
            required: true,
            message: "请选择抄表器型号",
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },
  methods: {
    regularNameListV() {
      regulationSelectListGET().then((res) => {
        this.regularNameList = res.data;
      });
    },
    // 获取设备类型
    getTypeList() {
      getEquipmentType().then((res) => {
        console.log(res, "滴哦萨基哦");
        this.typeList = res.data;
      });
    },
    getCompany() {
      getCompanyList().then((res) => {
        this.companyList = res.data;
      });
    },
    changeCompany(e) {
      this.disabled = false;
      console.log(e);
      let id = e;
      getStationList(id).then((res) => {
        console.log(res, "圣安东尼奥就");
        this.stationList = res.data;
      });
    },
    // 添加
    addReader(form) {
      this.$refs[form].validate((valid) => {
        if (valid) {
          let params = {
            ...this.form,
          };
          params.deviceType = params.deviceType ? 1 : 2;
          addNewReader(params).then((res) => {
            if (res.code == 200) {
              this.$message({
                showClose: false,
                message: "添加成功",
                type: "success",
              });
              this.$router.push("/readerEquipment");
            }
          });
        } else {
          return false;
        }
      });
    },
    getDetail() {
      getDetailReader(this.$route.query.id).then((res) => {
        this.form = res.data;
        this.form.mrType = this.form.mrType * 1;
        this.$set(
          this.form,
          "deviceType",
          res.data.deviceType == 1 ? true : false
        );
        if (this.form.etId != "") {
          getStationList(this.form.etId).then((res) => {
            console.log(res, "圣安东尼奥就");
            this.stationList = res.data;
          });
        }
      });
    },
    // 修改
    modify(form) {
      this.$refs[form].validate((valid) => {
        if (valid) {
          let params = {
            ...this.form,
          };
          params.deviceType = params.deviceType ? 1 : 2;
          modifyReader(params).then((res) => {
            if (res.code == 200) {
              this.$message({
                showClose: false,
                message: "修改成功",
                type: "success",
              });
              this.$router.push("/readerEquipment");
            }
          });
        } else {
          return false;
        }
      });
    },
  },
  mounted() {
    this.getTypeList();
    this.getCompany();
    this.regularNameListV();
    if (this.$route.query.id != undefined) {
      this.disabled = false;
      this.getDetail();
    }
  },
};
</script>

<style lang="scss" scoped>
.container::v-deep {
  display: flex;
  width: 100%;
  height: 100%;
  padding-top: 2vh;
  box-sizing: border-box;
  .left-part {
    margin-right: 3vw;
    .equipment-title {
      background: url(@/assets/images/titleBg.png);
      background-size: 100% 100%;
      width: 11.1vw;
      height: 5.5vh;
      color: #fff;
      font-size: calc(100vw * 20 / 1920);
      text-align: center;
      line-height: 5.5vh;
      margin-bottom: 1.8vh;
    }
  }
  .form-item {
    display: flex;
    height: 4.6vh;
    margin-bottom: 2.7vh;
    .add-btn {
      background: url(@/assets/images/updown.png);
      background-size: 100% 100%;
      width: 7.2vw;
      height: 4.6vh;
      color: #fff;
      font-size: calc(100vw * 18 / 1920);
      text-align: center;
      line-height: 4.6vh;
      margin-left: 20.3vw;
      cursor: pointer;
    }
    .tip {
      line-height: 4.6vh;
      margin-left: 0.5vw;
      color: #fff;
      font-size: calc(100vw * 15 / 1920);
      margin-right: 4.6vw;
    }
    &:nth-child(1) {
      .el-input.is-disabled .el-input__inner {
        background: #0f2455;
        border: 1px solid #286ae8;
        &::placeholder {
          color: #fff;
          //   text-align: center;
        }
      }
    }
  }
}
.el-form-item::v-deep {
  display: flex;
  height: 4.6vh;
  align-items: center;
  .el-form-item__label {
    width: 7.5vw;
    text-align: left;
    color: #fff;
    font-size: calc(100vw * 16 / 1920);
  }
}
::v-deep .el-input__inner {
  height: 4.6vh;
  background: #0f2455;
  border: 1px solid #286ae8;
  color: #fff;
  font-size: calc(100vw * 16 / 1920);
  &::placeholder {
    color: #fff;
  }
}
::v-deep .distpicker-address-wrapper select {
  width: 10.3vw;
  background: #0f2455;
  border: 1px solid #286ae8;
  color: #fff;
  margin-right: 1vw;
  height: 4.6vh;
  font-size: calc(100vw * 16 / 1920) !important;
}
</style>
