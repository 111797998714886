<template>
  <div class="container">
    <div class="left-title" v-if="$route.query.type == 14">分时用能</div>
    <div class="left-title" v-if="$route.query.type == 15">分时电费</div>
    <div class="right-container" v-if="$route.query.type == 14">
      <div class="detail-top">
        <div class="detail-top-one">电表用能详情</div>
        <div class="detail-top-two" @click="closeDetail">关闭</div>
      </div>
      <el-table
        :data="detailTable"
        :header-cell-style="headerStyle"
        :cell-style="rowStyle"
        style="width: 99.9%"
      >
        <el-table-column type="index" label="序号" width="55"></el-table-column>
        <el-table-column prop="psName" label="电站名称"></el-table-column>
        <el-table-column prop="mrName" label="表名称"></el-table-column>
        <el-table-column label="表类型">
          <template slot-scope="scope">
            <span v-if="scope.row.mrWhType == '1'"> 总电表 </span>
            <span v-if="scope.row.mrWhType == '2'"> 光伏电表 </span>
          </template>
        </el-table-column>
        <el-table-column prop="sn" label="表号"></el-table-column>
        <el-table-column label="类型">
          <template slot-scope="scope">
            <span v-if="scope.row.t == '10000'"> 总电能 </span>
            <span v-if="scope.row.t == '10100'"> 尖电能 </span>
            <span v-if="scope.row.t == '10200'"> 峰电能 </span>
            <span v-if="scope.row.t == '10300'"> 平电能 </span>
            <span v-if="scope.row.t == '10400'"> 谷电能 </span>
          </template>
        </el-table-column>
        <el-table-column prop="v" label="总电能"></el-table-column>
        <el-table-column prop="dt" label="报文时间" v-if="$route.query.date == '1'"></el-table-column>
        <el-table-column prop="dtStr" label="报文时间" v-if="$route.query.date == '2'"></el-table-column>
      </el-table>
      <!-- <el-pagination
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-sizes="[12,24,36,48]"
            :page-size="100"
            layout="sizes, prev, pager, next , total"
            :total="total">
        </el-pagination> -->
    </div>
    <div class="right-container" v-if="$route.query.type == 15">
      <div class="detail-top">
        <div class="detail-top-one">电费详情</div>
        <div class="detail-top-two" @click="closeDetail">关闭</div>
      </div>
      <el-table
        :data="detailPriceDetail"
        :header-cell-style="headerStyle"
        :cell-style="rowStyle"
        style="width: 99.9%"
      >
        <el-table-column type="index" label="序号" width="55"></el-table-column>
        <el-table-column prop="psName" label="电站名称"></el-table-column>
        <el-table-column prop="mrName" label="电表名称"></el-table-column>
        <el-table-column label="表类型">
          <template slot-scope="scope">
            <span v-if="scope.row.mrWhType == '1'"> 总电表 </span>
            <span v-if="scope.row.mrWhType == '2'"> 光伏电表 </span>
          </template>
        </el-table-column>
        <el-table-column prop="sn" label="表号"></el-table-column>
        <el-table-column prop="t" label="电费类型">
          <template slot-scope="scope">
            <span v-if="scope.row.t == '10000'"> 总电费 </span>
            <span v-if="scope.row.t == '10100'"> 尖电费 </span>
            <span v-if="scope.row.t == '10200'"> 峰电费 </span>
            <span v-if="scope.row.t == '10300'"> 平电费 </span>
            <span v-if="scope.row.t == '10400'"> 谷电费 </span>
          </template>
        </el-table-column>
        <el-table-column prop="v" label="总电费"></el-table-column>
        <el-table-column prop="dt" label="报文时间" v-if="$route.query.date == '1'"></el-table-column>
        <el-table-column prop="dtStr" label="报文时间" v-if="$route.query.date == '2'"></el-table-column>
      </el-table>
      <!-- <el-pagination
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-sizes="[12,24,36,48]"
            :page-size="100"
            layout="sizes, prev, pager, next , total"
            :total="total">
        </el-pagination> -->
    </div>
  </div>
</template>

<script>
import {
  getTimeSharingTable,
  getTimeSharingPriceTable,
} from "@/api/dataAnalysis";
export default {
  name: "hourlyDetail",
  data() {
    return {
      detailTable: [],
      detailPriceDetail: [],
    };
  },
  methods: {
    headerStyle() {
      return "background:#0F2455;color:#fff;border: 1px solid #286AE8;text-align:center";
    },
    rowStyle() {
      return "background:#0F2455;color:#fff;border: 1px solid #286AE8;text-align:center";
    },
    // handleCurrentChange(currentPage) {
    //   this.currentPage = currentPage;
    // },
    // 关闭
    closeDetail() {
      let type = this.$route.query.type;
      this.$router.push({
        path: "/hourlyChargeAndEnergy",
        query: {
          type,
          title: this.$route.query.title,
          date: this.$route.query.date,
        },
      });
    },
    // 用能详情
    getEle() {
      let data = {
        pageNum:this.$route.query.pageNum,
        pageSize:this.$route.query.pageSize,
        title: this.$route.query.title,
        type: this.$route.query.date,
        bTime: this.$route.query.bTime,
        eTime: this.$route.query.eTime,
      };
      getTimeSharingTable(data).then((res) => {
        // this.detailTable = res.rows
        this.detailTable = res.rows[this.$route.query.index].chide;
      });
    },
    getPrice() {
      let data = {
        title: this.$route.query.title,
        type: this.$route.query.date,
        bTime: this.$route.query.bTime,
        eTime: this.$route.query.eTime,
        pageNum:this.$route.query.pageNum,
        pageSize:this.$route.query.pageSize,
      };
      getTimeSharingPriceTable(data).then((res) => {
        this.detailPriceDetail = res.rows[this.$route.query.index].chide;
      });
    },
  },
  mounted() {
    if (this.$route.query.type == 14) {
      this.getEle();
    } else {
      this.getPrice();
    }
  },
};
</script>

<style lang="scss" scoped>
.container::v-deep {
  margin-top: 2vh;
  width: 100%;
  position: relative;
  .left-title {
    position: absolute;
    left: 0;
    top: 0;
    background: url(@/assets/images/box22.png);
    background-size: 100% 100%;
    width: 11.1vw;
    height: 5.5vh;
    color: #fff;
    font-size: calc(100vw * 20 / 1920);
    text-align: center;
    line-height: 5.5vh;
    margin-right: 7.5vw;
  }
  .right-container {
    width: 68vw;
    position: absolute;
    left: 5vw;
    right: 0;
    margin: 0 auto;
    .detail-top {
      height: 4.6vh;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 2.8vh;
      .detail-top-one,
      .detail-top-two {
        width: 7.2vw;
        height: 100%;
        text-align: center;
        line-height: 4.6vh;
        font-size: calc(100vw * 18 / 1920);
      }
      .detail-top-one {
        background: url(@/assets/images/btnBg1.png);
        background-size: 100% 100%;
        color: #02b5ff;
      }
      .detail-top-two {
        background: url(@/assets/images/btnBg2.png);
        background-size: 100% 100%;
        color: #fff;
        cursor: pointer;
      }
    }
  }
}
::v-deep .el-table {
  width: 99.9%;
  margin-bottom: 2.5vh;
  font-size: calc(100vw * 16 / 1920);
}
::v-deep .el-table__body {
  font-size: calc(100vw * 14 / 1920);
}
.el-table--border::after,
.el-table--group::after,
.el-table::before {
  display: none;
}
.el-button {
  background: none;
  color: #02b5ff;
  border: none;
  text-decoration: underline;
}
::v-deep .el-pagination {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1vh;
}
::v-deep .el-pagination .el-select .el-input .el-input__inner,
::v-deep .el-pagination .btn-next,
::v-deep .el-pagination .btn-prev,
::v-deep .el-pager li,
::v-deep .el-pager li.btn-quicknext,
::v-deep .el-pager li.btn-quickprev,
::v-deep .el-pagination__total {
  background: #0f2455;
  border-radius: 6px;
  border: 1px solid #286ae8;
  color: #fff;
  text-align: center;
}
::v-deep .el-pager li,
::v-deep .el-pagination .btn-next,
::v-deep .el-pagination .btn-prev {
  margin-right: 0.5vw;
}
::v-deep .el-pagination__total {
  margin-right: 0;
  padding: 0 0.8vw;
  box-sizing: border-box;
}
::v-deep .el-pager li.active {
  color: #409EFF;
}
</style>
