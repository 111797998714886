<template>
  <div class="container">
    <div class="left-title">系统设置</div>
    <div class="right-container">
      <div class="right-top">
        <el-form ref="form" :model="form">
          <div
            style="width: 50vw; display: flex; justify-content: space-between"
          >
            <el-form-item label="姓名">
              <el-input
              placeholder="请输入姓名"
                v-model="form.nickName"
                style="width: 15.6vw"
              ></el-input>
            </el-form-item>
            <el-form-item label="企业：" style="margin-left: 5vh;">
              <el-input
                v-model="form.etName"
                placeholder="请输入企业"
                style="width: 15.6vw"
              ></el-input>
            </el-form-item>
          </div>

          <div style="display: flex; justify-content: space-between">
            <el-form-item label="手机号">
              <el-input
              placeholder="请输入手机号"
                v-model="form.userName"
                style="width: 15.6vw"
              ></el-input>
            </el-form-item>
            <div style="display: flex; height: 4.6vh">
              <div class="query" @click="addNew">添加</div>
              <div class="query" @click="query">查询</div>
              <div class="reset" @click="clearSearch">重置</div>
            </div>
          </div>
        </el-form>
      </div>
      <el-table
        :data="userList"
        :header-cell-style="headerStyle"
        :cell-style="rowStyle"
      >
        <el-table-column type="index" label="序号" width="58"></el-table-column>
        <el-table-column prop="nickName" label="姓名"></el-table-column>
        <el-table-column prop="etName" label="所属企业"></el-table-column>
        <el-table-column
          prop="roles[0].roleName"
          label="角色"
        ></el-table-column>
        <el-table-column label="性别">
          <template slot-scope="scope">
            <span v-if="scope.row.sex == 0">男</span>
            <span v-else-if="scope.row.sex == 1">女</span>
            <span v-else>未知</span>
          </template>
        </el-table-column>
        <el-table-column prop="userName" label="手机号"></el-table-column>
        <el-table-column prop="remark" label="备注"></el-table-column>
        <el-table-column prop="enTime" label="合同到期时间"></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button size="mini" type="text" @click="handlePsd(scope.row)"
              >修改密码</el-button
            >
            <el-button size="mini" type="text" @click="handleEdit(scope.row)"
              >编辑</el-button
            >
            <el-button size="mini" type="text" @click="handleDelete(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[8, 16, 24, 32]"
        :page-size="100"
        layout="sizes, prev, pager, next , total"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { getUserList, deleteUser, isSuperManager } from "@/api/jurisdiction";
export default {
  name: "userJurisdiction",
  data() {
    return {
      form: {
        nickName: "",
        userName: "", // 手机号
        etName:'', // 企业
      },
      userList: [],
      currentPage: 1,
      pageSize: 8,
      total: 0,
    };
  },
  methods: {
    headerStyle() {
      return "background:#0F2455;color:#fff;border: 1px solid #286AE8;text-align:center";
    },
    rowStyle() {
      return "background:#0F2455;color:#fff;border: 1px solid #286AE8;text-align:center";
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getList();
    },
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage;
      this.getList();
    },
    getList() {
      let data = {
        pageSize: this.pageSize,
        pageNum: this.currentPage,
        nickName: this.form.nickName,
        userName: this.form.userName,
        etName:this.form.etName, // 企业
      };
      getUserList(data).then((res) => {
        console.log(res, "阿什杜阿虎");
        this.userList = res.rows;
        this.total = res.total;
      });
    },
    // 查询
    query() {
      this.currentPage = 1
      this.getList();
    },
    clearSearch() {
      this.form.nickName = "";
      this.form.userName = "";
      this.form.etName = ""; //企业
      this.getList();
    },
    // 新增
    addNew() {
      isSuperManager().then((res) => {
        let manager = res.data;
        this.$router.push({
          path: "/addAuthorization",
          query: {
            manager: manager,
          },
        });
      });
    },
    // 编辑
    handleEdit(row) {
      isSuperManager().then((res) => {
        let manager = res.data;
        console.log(manager, "后端会");
        this.$router.push({
          path: "/addAuthorization",
          query: {
            id: row.userId,
            manager: manager,
          },
        });
      });
    },
    // 删除
    handleDelete(row) {
      this.$confirm("是否确定删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deleteUser(row.userId).then((res) => {
          if (res.code == 200) {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
          }
          this.getList();
        });
      });
    },
    // 修改密码
    handlePsd(row) {
      this.$router.push({
        path: "modifyPsd",
        query: {
          id: row.userId,
        },
      });
    },
  },
  mounted() {
    this.getList();
  },
};
</script>

<style lang="scss" scoped>
.container::v-deep {
  margin-top: 2vh;
  width: 100%;
  position: relative;
  .left-title {
    position: absolute;
    left: 0;
    top: 0;
    background: url(@/assets/images/titleBg.png);
    background-size: 100% 100%;
    width: 11.1vw;
    height: 5.5vh;
    color: #fff;
    font-size: calc(100vw * 20 / 1920);
    text-align: center;
    line-height: 5.5vh;
    margin-right: 4vw;
  }
  .right-container {
    width: 81vw;
    position: absolute;
    left: 3vw;
    right: 0;
    margin: 0 auto;
    .right-top {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .el-form-item {
        display: flex;
        align-items: center;
        height: 4.6vh;

        .el-input__inner {
          width: 100%;
          height: 4.6vh;
          font-size: calc(100vw * 16 / 1920);
          box-shadow: inset 0rem 0rem 0.43rem 0.17rem rgba(44, 98, 230, 0.28);
          border-radius: 6px;
          border: 0.02rem solid #286ae8;
          background: none;
          color: #fff;
          &::placeholder {
            color: #fff;
          }
        }
        .el-form-item__label {
          width: 6.5vw;
          text-align: left;
          color: #fff;
          font-size: calc(100vw * 16 / 1920);
        }
      }
      .query,
      .reset {
        width: 7.2vw;
        height: 100%;
        background: url(@/assets/images/updown.png);
        background-size: 100% 100%;
        color: #02b5ff;
        font-size: calc(100vw * 16 / 1920);
        line-height: 4.6vh;
        text-align: center;
        cursor: pointer;
      }
      .query {
        margin-right: 3vw;
      }
    }
  }
}
::v-deep .el-form {
  width: 65vw;
  padding-left: 10vw;
  box-sizing: border-box;
}
::v-deep .el-table {
  width: 99.9%;
  margin-bottom: 2.5vh;
  font-size: calc(100vw * 16 / 1920);
}
::v-deep .el-table__body {
  font-size: calc(100vw * 14 / 1920);
}
::v-deep .el-table__row {
  height: 4vh;
}
.el-table--border::after,
.el-table--group::after,
.el-table::before {
  display: none;
}
::v-deep .el-pagination {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1vh;
}
::v-deep .el-pagination .el-select .el-input .el-input__inner,
::v-deep .el-pagination .btn-next,
::v-deep .el-pagination .btn-prev,
::v-deep .el-pager li,
::v-deep .el-pager li.btn-quicknext,
::v-deep .el-pager li.btn-quickprev,
::v-deep .el-pagination__total {
  background: #0f2455;
  border-radius: 6px;
  border: 1px solid #286ae8;
  color: #fff;
  text-align: center;
}
::v-deep .el-pager li,
::v-deep .el-pagination .btn-next,
::v-deep .el-pagination .btn-prev {
  margin-right: 0.5vw;
}
::v-deep .el-pagination__total {
  margin-right: 0;
  padding: 0 0.8vw;
  box-sizing: border-box;
}
::v-deep .el-form-item__content {
  height: 4.6vh;
}
::v-deep .el-pager li.active {
  color: #409EFF;
}
</style>
