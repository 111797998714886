import requests from './request'

// 查询用户列表
export function getUserList(data){
    return requests.get('/system/user/gfList?pageNum=' + data.pageNum + '&pageSize=' + data.pageSize + '&userName=' + data.userName + '&nickName=' + data.nickName+ '&etName=' + data.etName,data)
}

// 新增用户
export function addUser(data){
    return requests.post('/system/user',data)
}

// 新增用户
export function getUserRoleList(){
    return requests.get('/system/user/')
}

// 修改用户
export function modifyCompanyInfo(data){
    return requests.put('/system/user',data)
}

// 删除用户
export function deleteUser(id){
    return requests.get('/system/user/del/' + id)
}

// 重置密码
export function resetPsd(data){
    return requests.put('/system/user/resetPwd',data)
}

// 查询用户列表
export function getCompanyDetail(id){
    return requests.get('/system/user/' + id)
}

// 查询角色列表
export function getRoleList(data){
    return requests.get('/system/role/list?pageNum=' + data.pageNum + '&pageSize=' + data.pageSize + '&roleName=' + data.roleName,data)
}

// 新增角色
export function addRole(data){
    return requests.post('/system/role',data)
}

// 获取菜单
export function getMenu(){
    return requests.get('/system/menu/myMenu')
}

// 根据id查权限
export function getMenuById(id){
    return requests.get('/system/menu/gfRoleMenu/' + id)
}

// 修改角色
export function modifyRole(data){
    return requests.put('/system/role',data)
}

// 根据id查看角色详情
export function getRoleDetail(id){
    return requests.get('/system/role/' + id)
}

// 删除角色
export function deleteRole(id){
    return requests.get('/system/role/del/' + id)
}

// 获取企业信息
export function getCompanyInfo(){
    return requests.get('/custom/enterprise/listAll')
}

// 判断是否为超级管理员
export function isSuperManager(){
    return requests.get('/custom/ccu/getCbu')
}

// 默认企业
export function getDefaultCompany(){
    return requests.get('/custom/ccu/getEcp')
}

// 默认企业（非超级管理员）
export function getDefaultCompanyNotM(){
    return requests.get('/custom/ccu/getEcpUser')
}

// 查询视频列表
export function getVideoList(data){
    return requests.get('/system/video/list?pageNum=' + data.pageNum + '&pageSize=' + data.pageSize + '&name=' + data.name,data)
}

// 删除视频
export function deleteVideo(id){
    return requests.get('/system/video/del/' + id)
}

// 新增视频
export function addNewVideo(data){
    return requests.post('/system/video',data)
}

// 获取视频详情
export function getVideoDetail(id){
    return requests.get('/system/video/' + id)
}

// 修改视频
export function modifyVideo(data){
    return requests.put('/system/video',data)
}

// 电站
export function customStationGET(params) {
    return requests.get(`/custom/station/listAll/${params.id}`)
}

