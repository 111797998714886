<template>
  <div class="container">
    <div class="form">
      <el-form :model="form" ref="form" :rules="rules">
        <el-form-item label="角色名称：" prop="roleName">
          <el-input v-model="form.roleName" placeholder="请输入角色名称"></el-input>
        </el-form-item>
        <el-form-item label="排序：" prop="roleSort">
          <el-input type="number" v-model="form.roleSort" placeholder="请输入排序"></el-input>
        </el-form-item>
        <!-- <el-form-item label="状态" prop="status">
          <el-input v-model="form.status"></el-input>
        </el-form-item> -->
        

        <el-form-item
          label="备注："
          style="display: flex; align-items: flex-start; height: 13.8vh"
        >
          <textarea v-model="form.remarks" placeholder="请输入备注"></textarea>
        </el-form-item>
        <el-tree
          :data="data"
          show-checkbox
          node-key="id"
          :props="defaultProps"
          :default-checked-keys="currentKey"
          ref="menu"
          @check-change="changeMenu"
        >
        </el-tree>
      </el-form>
    </div>
    <div
      class="submit-btn"
      @click="addRole('form')"
      v-if="$route.query.id == undefined"
    >
      添加
    </div>
    <div class="submit-btn" v-if="$route.query.id != undefined" @click="modify('form')">修改</div>
  </div>
</template>

<script>
import {
  addRole,
  getMenu,
  getMenuById,
  modifyRole,
  getRoleDetail,
} from "@/api/jurisdiction";
export default {
  name: "addRole",
  data() {
    return {
      currentKey: [], // 节点
      form: {
        roleName: "",
        // status: "",
        remark: "",
        roleSort: "",
        sex:''
      },
      menuIds: [], // 菜单id数组
      roleId: "",
      data: [],
      defaultProps: {
        children: "children",
        label: "label",
      },
      rules: {
        roleName: [
          { required: true, message: "角色名称不能为空", trigger: "blur" },
        ],
        roleSort: [
          { required: true, message: "排序不能为空", trigger: "blur" },
        ],
        
        // status:[
        //     { required: true, message: "状态不能为空", trigger: "blur" },
        // ],
      },
    };
  },
  methods: {
    // 获取菜单
    getThenMenu() {
      getMenu().then((res) => {
        this.data = res.data;
      });
    },
    changeMenu(e) {
      console.log(this.currentKey, "那就东南");
      console.log(111);
      console.log(e);
      console.log(this.getMenuAllCheckedKeys(), "NSA电脑卡升级");
      this.menuIds = this.getMenuAllCheckedKeys();
    },
    // 所有菜单节点数据
    getMenuAllCheckedKeys() {
      // 目前被选中的菜单节点
      let checkedKeys = this.$refs.menu.getCheckedKeys();
      // 半选中的菜单节点
      let halfCheckedKeys = this.$refs.menu.getHalfCheckedKeys();
      checkedKeys.unshift.apply(checkedKeys, halfCheckedKeys);
      return checkedKeys;
    },
    addRole(form) {
      this.$refs[form].validate((valid) => {
        if (valid) {
          if (this.menuIds.length != 0) {
            let data = {
              roleName: this.form.roleName,
              roleSort: this.form.roleSort,
              status: 0,
              menuIds: this.menuIds,
              deptIds: [],
              menuCheckStrictly: true,
              deptCheckStrictly: true,
            };
            addRole(data).then((res) => {
              console.log(res, "那我就打击我");
              if (res.code == 200) {
                this.$message({
                  showClose: false,
                  message: "添加成功",
                  type: "success",
                });
                this.$router.push("/roleManagement");
              }
            });
          } else {
            this.$message({
              showClose: false,
              message: "请选择权限内容",
              type: "warning",
            });
          }
        } else {
          return false;
        }
      });
    },
    // 根据id获取选中的权限
    getMenuById() {
      console.log("触发");
      getMenuById(this.roleId).then((res) => {
        console.log(res, "肯德基阿森纳角度看");
        this.currentKey = res.checkedKeys;
      });
    },
    // 获取详情
    getDetail() {
      getRoleDetail(this.roleId).then((res) => {
        console.log(res, "你就打死你尽快");
        this.form = res.data;
      });
    },
    // 修改
    modify(form) {
      this.$refs[form].validate((valid) => {
        if (valid) {
          if (this.menuIds.length != 0) {
            let data = {
              roleName: this.form.roleName,
              roleSort: this.form.roleSort,
              status: 0,
              menuIds: this.menuIds,
              deptIds: [],
              menuCheckStrictly: true,
              deptCheckStrictly: true,
              roleId:this.roleId
            };
            modifyRole(data).then((res) => {
              console.log(res, "那我就打击我");
              if (res.code == 200) {
                this.$message({
                  showClose: false,
                  message: "修改成功",
                  type: "success",
                });
                this.$router.push("/roleManagement");
              }
            });
          } else {
            this.$message({
              showClose: false,
              message: "请选择权限内容",
              type: "warning",
            });
          }
        } else {
          return false;
        }
      });
    },
  },
  mounted() {
    this.getThenMenu();
    if (JSON.stringify(this.$route.query) != "{}") {
      this.roleId = (this.$route.query.id) * 1;
      console.log(this.roleId);
      this.getDetail();
      this.getMenuById();
    }
  },
};
</script>

<style lang="scss" scoped>
.container::v-deep {
  width: 100%;
  position: relative;
  .form {
    position: absolute;
    top: 3.7vh;
    left: 18.75vw;
    .el-form-item {
      display: flex;
      height: 4.6vh;
      align-items: center;
      margin-bottom: 3vh;
      .el-form-item__label {
        width:7vw;
        text-align: left;
        color: #fff;
        font-size: calc(100vw * 16 / 1920);
      }
    }
    .el-input__inner {
      width: 20vw;
      height: 4.6vh;
      background: #0f2455;
      border: 1px solid #286ae8;
      color: #fff;
      font-size: calc(100vw * 16 / 1920);
      &::placeholder {
        color: #fff;
      }
    }
  }
  .submit-btn {
    position: absolute;
    top: 72vh;
    right: 18.75vw;
    color: #fff;
    width: 7.2vw;
    height: 4.6vh;
    background: url(@/assets/images/updown.png);
    background-size: 100% 100%;
    color: #02b5ff;
    font-size: calc(100vw * 18 / 1920);
    text-align: center;
    line-height: 4.6vh;
    cursor: pointer;
  }
}
.el-tree::v-deep {
  width: 100%;
  background: none;
  .el-tree-node__label {
    color: #fff;
    font-size: calc(100vw * 16 / 1920);
  }
  .el-tree-node is-expanded is-focusable {
    background: none;
  }
}
::v-deep .el-tree-node:focus > .el-tree-node__content,
::v-deep .el-tree-node:hover > .el-tree-node__content {
  background-color: transparent !important;
}
::v-deep .el-input__prefix {
  left: 18vw;
  font-size: calc(100vw * 20 / 1920);
}
::v-deep .el-input--prefix .el-input__inner {
  padding-left: 0.8vw;
}
textarea {
  width: 20vw;
  resize: none;
  background: #0f2455;
  border: 1px solid #286ae8;
  color: #fff;
  font-size: calc(100vw * 16 / 1920);
  border-radius: 6px;
  height: 13.8vh;
  padding: 0.7vw;
  box-sizing: border-box;
  outline: none;
  &::placeholder {
    color: #fff;
  }
}
::v-deep .el-tree {
  height: 25vh;
  overflow-y: scroll;
}
</style>
