<template>
  <div>
    <el-tabs v-model="activeName">
      <el-tab-pane label="基本资料" name="first">
        <div class="user_container">
          <div class="form_container">
            <el-form :model="form" ref="form" :rules="rules">
              <el-form-item label="用户名：">
                <el-input v-model="form.userName" disabled></el-input>
              </el-form-item>
              <el-form-item label="昵称：" prop="nickName">
                <el-input v-model="form.nickName"></el-input>
              </el-form-item>
              <el-form-item label="备注：">
                <el-input v-model="form.remark"></el-input>
              </el-form-item>
              <el-form-item label="头像：">
                <el-upload
                  class="avatar-uploader"
                  :headers="header"
                  action="https://admin.pvshield.cn/guang/common/upload"
                  :show-file-list="false"
                  :on-success="handleAvatarSuccess"
                >
                  <img v-if="form.img" :src="form.img" class="avatar" />
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
              </el-form-item>
            </el-form>
            <div class="submit_btn" @click="modifyUser('form')">修改</div>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="修改密码" name="second">
        <div class="user_container">
          <div class="form_container">
            <el-form :model="formSecond">
              <el-form-item label="旧密码：">
                <div
                  style="display: flex; width: 100%; position: relative"
                  v-if="openOldPsd"
                >
                  <el-input
                    type="text"
                    v-model="formSecond.oldPsd"
                    placeholder="请输入旧密码"
                    style="width: 100%; positiion: absolute; left: 0"
                  ></el-input>
                  <img
                    src="..//assets/images/openPsd.png"
                    style="
                      width: 1.5vw;
                      height: 2.5vh;
                      position: absolute;
                      right: 0.7vw;
                      top: 1vh;
                      cursor: pointer;
                    "
                    @click="openOldPsd = false"
                  />
                </div>
                <div
                  style="display: flex; width: 100%; position: relative"
                  v-if="!openOldPsd"
                >
                  <el-input
                    type="password"
                    v-model="formSecond.oldPsd"
                    placeholder="请输入旧密码"
                    style="
                      width: 100%;
                      positiion: absolute;
                      left: 0;
                      background: transparent;
                    "
                  ></el-input>
                  <img
                    src="../assets/images/closePsd.png"
                    style="
                      width: 1.5vw;
                      height: 2.5vh;
                      position: absolute;
                      right: 0.7vw;
                      top: 1vh;
                      cursor: pointer;
                    "
                    @click="openOldPsd = true"
                  />
                </div>
              </el-form-item>
              <el-form-item label="新密码：">
                <div
                  style="display: flex; width: 100%; position: relative"
                  v-if="openNewPsd"
                >
                  <el-input
                    type="text"
                    v-model="formSecond.newPsd"
                    placeholder="请输入新密码"
                    style="width: 100%; positiion: absolute; left: 0"
                  ></el-input>
                  <img
                    src="..//assets/images/openPsd.png"
                    style="
                      width: 1.5vw;
                      height: 2.5vh;
                      position: absolute;
                      right: 0.7vw;
                      top: 1vh;
                      cursor: pointer;
                    "
                    @click="openNewPsd = false"
                  />
                </div>
                <div
                  style="display: flex; width: 100%; position: relative"
                  v-if="!openNewPsd"
                >
                  <el-input
                    type="password"
                    v-model="formSecond.newPsd"
                    placeholder="请输入新密码"
                    style="
                      width: 100%;
                      positiion: absolute;
                      left: 0;
                      background: transparent;
                    "
                  ></el-input>
                  <img
                    src="../assets/images/closePsd.png"
                    style="
                      width: 1.5vw;
                      height: 2.5vh;
                      position: absolute;
                      right: 0.7vw;
                      top: 1vh;
                      cursor: pointer;
                    "
                    @click="openNewPsd = true"
                  />
                </div>
              </el-form-item>
              <el-form-item label="确认密码：">
                <div
                  style="display: flex; width: 100%; position: relative"
                  v-if="opennewPsdAgain"
                >
                  <el-input
                    type="text"
                    v-model="formSecond.newPsdAgain"
                    placeholder="请输入新密码"
                    style="width: 100%; positiion: absolute; left: 0"
                  ></el-input>
                  <img
                    src="..//assets/images/openPsd.png"
                    style="
                      width: 1.5vw;
                      height: 2.5vh;
                      position: absolute;
                      right: 0.7vw;
                      top: 1vh;
                      cursor: pointer;
                    "
                    @click="opennewPsdAgain = false"
                  />
                </div>
                <div
                  style="display: flex; width: 100%; position: relative"
                  v-if="!opennewPsdAgain"
                >
                  <el-input
                    type="password"
                    v-model="formSecond.newPsdAgain"
                    placeholder="请输入新密码"
                    style="
                      width: 100%;
                      positiion: absolute;
                      left: 0;
                      background: transparent;
                    "
                  ></el-input>
                  <img
                    src="../assets/images/closePsd.png"
                    style="
                      width: 1.5vw;
                      height: 2.5vh;
                      position: absolute;
                      right: 0.7vw;
                      top: 1vh;
                      cursor: pointer;
                    "
                    @click="opennewPsdAgain = true"
                  />
                </div>
              </el-form-item>
            </el-form>
            <div class="submit_btn" @click="modifyInfo">修改</div>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { getUserInfo, modifyUserPass, modifyUserInfo } from "@/api/index.js";
export default {
  name: "user",
  data() {
    return {
      activeName: "first",
      header: { Authorization: window.localStorage.getItem("token") },
      form: {
        userName: "", // 用户名
        nickName: "", // 昵称
        img: "", // 头像
        remark: "",
        id:""
      },
      rules: {
        userName: [
          { required: true, message: "用户名不能为空", trigger: "blur" },
        ],
        nickName: [
          { required: true, message: "昵称不能为空", trigger: "blur" },
        ],
      },
      formSecond: {
        oldPsd: "",
        newPsd: "",
        newPsdAgain: "",
      },
      openOldPsd: false,
      openNewPsd: false,
      opennewPsdAgain: false,
    };
  },
  methods: {
    handleAvatarSuccess(file) {
      console.log(file, 111);
      this.form.img = file.url;
    },
    getInfo() {
      getUserInfo().then((res) => {
        this.form.userName = res.user.userName;
        this.form.nickName = res.user.nickName;
        this.form.img = res.user.avatar;
        this.form.remark = res.user.remark;
        this.form.id = res.user.userId
      });
    },
    // 修改密码
    modifyInfo() {
      if (
        this.formSecond.newPsd == this.formSecond.newPsdAgain &&
        this.formSecond.newPsd != "" &&
        this.formSecond.oldPsd != ""
      ) {
        let data = {
          oldPassword: this.formSecond.oldPsd,
          newPassword: this.formSecond.newPsd,
        };
        console.log(data,"1111111")
        modifyUserPass(data).then((res) => {
          if (res.code == 200) {
            this.$message({
              showClose: false,
              message: "修改成功,请重新登录",
              type: "success",
            });
            this.$router.push('/')
          }
        });
      } else if (this.formSecond.newPsd != this.formSecond.newPsdAgain) {
        this.$message({
          showClose: false,
          message: "请确认输入的新密码一致",
          type: "error",
        });
      } else {
        console.log(this.formSecond.oldPsd, "1");
        console.log(this.formSecond.newPsd, "2");
        console.log(this.formSecond.newPsdAgain, "2");
        this.$message({
          showClose: false,
          message: "请填写完整",
          type: "error",
        });
      }
    },
    modifyUser(form) {
      this.$refs[form].validate((valid) => {
        if (valid) {
          let data = {
            userId:this.form.id,
            nickName:this.form.nickName,
            avatar:this.form.img,
            remark:this.form.remark
          }
            modifyUserInfo(data).then((res) => {
              console.log(res);
              if (res.code == 200) {
                this.$message({
                  showClose: false,
                  message: "修改成功,请重新登录",
                  type: "success",
                });
                this.$router.push('/')
              }
            });
        } else {
          return false;
        }
      });
    },
  },
  mounted() {
    this.getInfo();
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-tabs__nav-scroll {
  padding-left: 2vw;
  margin-top: 1.5vh;
  height: 5vh;
  line-height: 5vh;
  .el-tabs__item {
    font-size: calc(100vw * 17 / 1920);
    color: #17e0fe;
  }
  .el-tabs__active-bar {
    background: #17e0fe;
  }
}
.user_container::v-deep {
  padding: 2vh 2vw;
  width: 100%;
  box-sizing: border-box;
  .el-form-item {
    display: flex;
    align-items: center;
    height: 4.6vh;
    margin-bottom: 4vh;
    .el-form-item__label {
      width: 7vw;
      text-align: left;
      color: #fff;
      font-size: calc(100vw * 17 / 1920);
    }
    .el-input__inner {
      width: 16vw;
      height: 4.6vh;
      background: #0f2455;
      border: 1px solid #286ae8;
      color: #fff;
      background: transparent;
      font-size: calc(100vw * 16 / 1920);
    }
  }
  .el-form-item:nth-child(3) {
    align-items: flex-start;
    height: 16vh;
  }
  .submit_btn {
    width: 7.2vw;
    height: 4.6vh;
    background: url(@/assets/images/updown.png);
    background-size: 100% 100%;
    color: #02b5ff;
    font-size: calc(100vw * 18 / 1920);
    text-align: center;
    line-height: 4.6vh;
    margin-left: 14.5vw;
    margin-top: 15vh;
    cursor: pointer;
  }
}
::v-deep .avatar-uploader .el-upload {
  border: 1px solid #d9d9d9;
  // border-radius: 6px;
  cursor: pointer;
  position: relative;
  // overflow: hidden;
  width: 6.7vw;
  height: 13vh;
  border-radius: 50%;
  background: #999999;
}
::v-deep .avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
::v-deep .avatar-uploader-icon {
  font-size: calc(100vw * 40 / 1920);
  //   color: #8c939d;
  color: #fff;
  line-height: 13vh;
  text-align: center;
}
::v-deep .avatar {
  width: 6.7vw;
  height: 13vh;
  border-radius: 50%;
}
::v-deep input:-webkit-autofill,
::v-deep textarea:-webkit-autofill,
::v-deep select:-webkit-autofill {
  -webkit-text-fill-color: #ededed !important;

  -webkit-box-shadow: 0 0 0px 1000px transparent inset !important;

  background-color: transparent;

  background-image: none;

  transition: background-color 50000s ease-in-out 0s; //背景色透明  生效时长  过渡效果  启用时延迟的时间
}
input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  // 字体颜色
  -webkit-text-fill-color: #fff !important;
  // 背景颜色
  background-color: transparent !important;
  // 背景图片
  background-image: none !important;
  //设置input输入框的背景颜色为透明色
  -webkit-box-shadow: 0 0 0px 1000px transparent inset !important;
  transition: background-color 50000s ease-in-out 0s;
}
</style>
