<!--
 * @Author: ‘巴博尔’ 2164119982@qq.com
 * @Date: 2023-04-26 13:47:36
 * @LastEditors: ‘巴博尔’ 2164119982@qq.com
 * @LastEditTime: 2023-06-13 09:45:23
 * @FilePath: \guangfu-pc\src\pages\inverterFailure,vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="container">
    <div class="container-checklist">
      <div
        class="container-checklist-init"
        :class="checkIndex == index ? 'container-checklist-change' : ''"
        v-for="(item, index) in checkList"
        :key="index"
        @click="clickCheckChange(item, index)"
      >
        {{ item.label }}
      </div>
    </div>
    <div class="container-table">
      <table border="1">
        <tr>
          <td v-for="(item, index) in tableTHList" :key="index">{{ item }}</td>
        </tr>
        <template v-if="tableList && tableList.length">
          <tr v-for="(item, index) in tableList" :key="index">
            <td>{{ item.sn }}</td>
            <td>{{ item.inverterName }}</td>
            <td>{{ item.malfunctionInfo }}</td>
            <td>{{ item.malfunctionCode }}</td>
            <td>{{ item.updateDate }}</td>
          </tr>
        </template>
        <tr v-else>
          <td colspan="5">{{ tableEmptyText }}</td>
        </tr>
      </table>
    </div>
    <template v-if="total">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage"
        :page-sizes="[8, 16, 24, 32]"
        :page-size="pageSize"
        layout="sizes, prev, pager, next,total"
        :total="total"
      >
      </el-pagination>
    </template>
  </div>
</template>

<script>
import { inverterMalfunctionsGET } from "@/api/inverterAddition";
export default {
  name: "inverterFailure",

  data() {
    return {
      checkList: [
        {
          label: "恢复",
          value: "",
        },
        {
          label: "发生",
          value: "",
        },
        {
          label: "全部",
          value: "",
        },
      ],
      checkIndex: 2,
      tableTHList: ["设备SN号", "设备名称", "故障名称", "状态", "发生时间"],
      tableEmptyText: "暂无数据",
      tableList: [],
      currentPage: 1,
      total: 0,
      pageSize: 8,
    };
  },

  mounted() {
    this.inverterMalfunctionsAxios();
  },

  methods: {
    async inverterMalfunctionsAxios() {
      let self = this,
        type;
      switch (self.checkIndex) {
        case 0:
          type = 2;
          break;
        case 1:
          type = 1;
          break;
        case 2:
          type = 3;
          break;
      }
      let params = {
        pageNum: self.currentPage,
        pageSize: self.pageSize,
        type,
      };
      // params = JSON.stringify(params);
      const data_back = await inverterMalfunctionsGET(params);
      console.log(data_back, "========inverterMalfunctionsGET");
      const { code, rows, total } = data_back;
      if (code === 200) {
        self.total = total;
        self.tableList = rows && rows.length ? rows : [];
      }
    },
    listInit(currentPage = 1, pageSize = 8, total = 0, tableList = []) {
      this.currentPage = currentPage;
      this.pageSize = pageSize;
      this.total = total;
      this.tableList = tableList;
      this.inverterMalfunctionsAxios();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.total = 0;
      this.tableList = [];
      this.inverterMalfunctionsAxios();
    },
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage;
      this.total = 0;
      this.tableList = [];
      this.inverterMalfunctionsAxios();
    },
    clickCheckChange(row, index) {
      this.checkIndex = index;
      this.listInit();
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  padding: 1.0417vw /* 20px -> 1.0417vw */;
  &-checklist {
    display: flex;
    flex-wrap: wrap;
    &-init {
      color: #02b5ff;
      font-size: 1.0417vw /* 20px -> 1.0417vw */;
      font-family: AlibabaPuHuiTi-Medium, AlibabaPuHuiTi;
      font-weight: 500;
      background: url("~@/assets/icon/icon1.png") no-repeat;
      background-size: 100% 100%;
      width: 7.2917vw /* 140px -> 7.2917vw */;
      height: 2.6042vw /* 50px -> 2.6042vw */;
      line-height: 2.6042vw /* 50px -> 2.6042vw */;
      text-align: center;
      cursor: pointer;
    }
    &-change {
      color: #ffffff !important;
    }
    &-init:nth-child(2) {
      margin: 0 1.0417vw /* 20px -> 1.0417vw */;
    }
  }
  &-table {
    width: 100%;
    margin: 2.0833vw /* 40px -> 2.0833vw */ 0px;
    table {
      width: 100%;
      box-shadow: inset 0px 0px 1.0417vw /* 20px -> 1.0417vw */ 1.0417vw
        /* 20px -> 1.0417vw */ rgba(15, 53, 116, 0.6);
      border: 1px solid #286ae8;
      border-collapse: collapse;
      text-align: center;
      font-size: 0.8333vw /* 16px -> .8333vw */;
      font-family: AlibabaPuHuiTi-Regular, AlibabaPuHuiTi;
      font-weight: 400;
      color: #ffffff;
      td {
        padding-top: 0.8333vw /* 16px -> .8333vw */;
        padding-bottom: 0.8333vw /* 16px -> .8333vw */;
      }
      tr {
        cursor: pointer;
      }
    }
  }
}

::v-deep .el-pagination {
  display: flex;
  justify-content: flex-end;
  .el-select,
  .btn-prev,
  .btn-next,
  .el-pagination__total {
    box-shadow: inset 0rem 0rem 0.43rem 0.17rem rgba(44, 98, 230, 0.28);
    border-radius: 0.1rem;
    border: 0.02rem solid #286ae8;
    background: none;
    color: #fff;
  }
  .el-pager li {
    box-shadow: inset 0rem 0rem 0.43rem 0.17rem rgba(44, 98, 230, 0.28);
    border-radius: 0.1rem;
    border: 0.02rem solid #286ae8;
    background: none;
    .active {
      color: pink !important;
    }
  }
  .el-pager li.active {
    color: #0855b3;
  }
  .number {
    color: #fff;
    margin-right: 0.8vw;
    .active {
      color: pink;
    }
  }
  .el-input {
    width: 7vw;
  }
  .more,
  .btn-prev,
  .btn-next {
    color: #fff;
    margin-right: 0.8vw;
  }
  .el-input .el-input--mini .el-input--suffix {
    background: none;
  }
  .el-input__inner {
    background: none;
    border: none;
    color: #fff;
  }
}
</style>
