<template>
  <div>
    <div class="header">
      <div style="position:absolute;">
        <img src="../assets/images/sign.png"/>
        <span>光伏盾</span>
      </div>
      <div class="header-title">上海列卫智能科技有限公司</div>
    </div>
    <div class="content">
      <div class="content-container">
        <div class="title">账号密码登录</div>
        <el-input
          v-model="info.username"
          placeholder="请输入账号"
          style="width: 100%"
        ></el-input>
        <div
          style="display: flex; width: 100%; position: relative"
          v-if="openPsd"
        >
          <el-input
            type="text"
            v-model="info.password"
            placeholder="请输入密码"
            style="width: 100%; positiion: absolute; left: 0;bakcground:transparent"
          ></el-input>
          <img
            src="..//assets/images/openPsd.png"
            style="
              width: 1.5vw;
              height: 2.5vh;
              position: absolute;
              right: 0.7vw;
              top: 1vh;
              cursor: pointer;
            "
            @click="openPsd = false"
          />
        </div>
        <div
          style="display: flex; width: 100%; position: relative"
          v-if="!openPsd"
        >
          <el-input
            type="password"
            v-model="info.password"
            placeholder="请输入密码"
            style="width: 100%; positiion: absolute; left: 0;bakcground:transparent"
          ></el-input>
          <img
            src="..//assets/images/closePsd.png"
            style="
              width: 1.5vw;
              height: 2.5vh;
              position: absolute;
              right: 0.7vw;
              top: 1vh;
              cursor: pointer;
            "
            @click="openPsd = true"
          />
        </div>
        <div style="width: 100%; display: flex; justify-content: space-between">
          <el-input
            v-model="info.code"
            placeholder="请输入验证码"
            style="width: 9vw"
          ></el-input>
          <img :src="img" class="img" @click="changeCode" />
        </div>
        <div style="display:flex">
          <span style="width:5vw;cursor:pointer;font-size:calc(100vw * 13 / 1920);" @click="findPsd">忘记密码？</span>
          <el-checkbox v-model="checked" @change="autoLogin"
          >自动登录</el-checkbox
        >
        </div>
        <div class="login-btn" @click="login" @keyup.enter="keyDown(e)">
          登 录
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getCode, getLogin } from "@/api/index.js";
export default {
  name: "login",
  data() {
    return {
      info: {
        username: "", // 账号
        password: "", // 密码
        code: "", // 验证码
        uuid: "",
      },
      img: "",
      checked: false,
      openPsd: false, // 是否显示密码
    };
  },
  methods: {
    // 按回车登录
    keyDown(e) {
      // 回车则执行登录方法 enter键的ASCII是13
      if (e.keyCode == 13 || e.keyCode == 100) {
        this.login(); // 定义的登录方法
      }
    },

    // 获取验证码
    getCode() {
      getCode().then((res) => {
        this.img = "data:image/gif;base64," + res.img;
        this.info.uuid = res.uuid;
      });
    },
    // 切换验证码
    changeCode() {
      this.getCode();
    },
    autoLogin() {
      console.log(this.checked);
      if (this.checked == true) {
        sessionStorage.setItem("checked", this.checked);
        sessionStorage.setItem("userName", this.info.username);
        sessionStorage.setItem("password", this.info.password);
      }else {
        sessionStorage.removeItem('checked')
        sessionStorage.removeItem('userName')
        sessionStorage.removeItem('password')
      }
    },
    // 登录
    login() {
      if (
        this.info.username != "" &&
        this.info.password != "" &&
        this.info.code != ""
      ) {
        getLogin(this.info).then((res) => {
          if (res.code == 200) {
            localStorage.setItem("token", "Bearer " + res.token);
            this.$message({
              showClose: false,
              message: "登录成功",
              type: "success",
            });
            setTimeout(() => {
              this.$router.push("/index");
            }, 1000);
          }
        });
      } else {
        this.$alert("请填写完整", "提示", {
          confirmButtonText: "确定",
          showClose: false,
        });
      }
    },
    // 忘记密码
    findPsd(){
      this.$router.push('/forgetPsd')
    }
  },
  created() {
    localStorage.removeItem("token");
  },
  mounted() {
    this.getCode();
    this.info.username = sessionStorage.getItem("userName");
    this.info.password = sessionStorage.getItem("password");
    if (sessionStorage.getItem("checked") != undefined) {
      this.checked = true;
    }
    // 绑定监听事件
    window.addEventListener("keydown", this.keyDown);
  },
  destroyed() {
    // 销毁事件
    window.removeEventListener("keydown", this.keyDown, false);
  },
};
</script>

<style lang="scss" scoped>
.header {
  width: 100%;
  height: 7.4vh;
  background: #0c3688;
  position: relative;
  line-height: 7.4vh;
  img {
    position: absolute;
    left: 3vw;
    top: 1.8vh;
    width: 2.8vw;
    height: 4vh;
  }
  span {
    margin-left: 6.5vw;
    color: #fff;
    font-size: calc(100vw * 20 / 1920);
  }
  .header-title {
    color: #fff;
    font-size: calc(100vw * 20 / 1920);
    text-align: center;
  }
}
.content::v-deep {
  width: 100%;
  height: calc(100vh - 7.4vh);
  background: url(@/assets/images/login.png);
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .content-container {
    background: url(@/assets/images/loginBg.png);
    background-size: 100% 100%;
    width: 25vw;
    height: 45vh;
    color: #fff;
    padding: 3.5vh 4vw;
    box-sizing: border-box;
    .title {
      text-align: center;
      font-size: calc(100vw * 22 / 1920);
      margin-bottom: 3vh;
    }
    .img {
      width: 6vw;
      height: 4.6vh;
    }
    .el-input__inner {
      height: 4.6vh;
      font-size: calc(100vw * 16 / 1920);
      box-shadow: inset 0rem 0rem 0.43rem 0.17rem rgba(44, 98, 230, 0.28);
      border-radius: 6px;
      border: 0.02rem solid #286ae8;
      background: transparent;
      color: #fff;
      margin-bottom: 2vh;
      &::placeholder {
        color: #fff;
      }
    }
    .el-checkbox {
      color: #fff;
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .el-checkbox__input {
        height: 14px;
        .el-checkbox__inner {
          border: 1px solid #00c0ff;
          background: none;
        }
      }
      .el-checkbox__label {
        font-size: calc(100vw * 15 / 1920);
      }
    }
    .login-btn {
      // background: linear-gradient(90deg, #FFFF00 6%, #FFA500 25%, #F14444 45%, #D53567 55%, #9A109A 94%);
      background: linear-gradient(to right, #1290f1, #115ab0);
      height: 3.8vh;
      border-radius: 2vw;
      text-align: center;
      line-height: 3.5vh;
      margin-top: 2vh;
      cursor: pointer;
    }
  }
}
::v-deep .el-checkbox__input.is-checked + .el-checkbox__label {
  color: #fff;
}
::v-deep input:-webkit-autofill ,::v-deep textarea:-webkit-autofill,::v-deep select:-webkit-autofill {

	-webkit-text-fill-color: #ededed !important;

	-webkit-box-shadow: 0 0 0px 1000px transparent  inset !important;

    background-color:transparent;

    background-image: none;

     transition: background-color 50000s ease-in-out 0s; //背景色透明  生效时长  过渡效果  启用时延迟的时间

}


input:-webkit-autofill , textarea:-webkit-autofill, select:-webkit-autofill {
    // 字体颜色
    -webkit-text-fill-color: #fff !important;
    // 背景颜色
    background-color:transparent !important;
    // 背景图片
    background-image: none !important;
    //设置input输入框的背景颜色为透明色
   -webkit-box-shadow: 0 0 0px 1000px transparent  inset !important;  
    transition: background-color 50000s ease-in-out 0s;  
  }
</style>
