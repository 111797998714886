<template>
  <div class="container">
    <div class="container_">
      <!-- <el-amap
        class="amap-box"
        :amap-manager="amapManager"
        :vid="'amap-vue'"
        :zoom="zoom"
        :plugin="plugin"
        :center="center"
        :events="events"
      >
        <el-amap-marker
          v-for="(marker, index) in markers"
          :position="marker"
          :key="index"
        ></el-amap-marker>
      </el-amap> -->
      <div id="container" tabindex="0" class="allmap"></div>
    </div>
    <div class="left-container">
      <div class="one-container">
        <div class="left-title">告警总数</div>
        <div
          style="
            width: 24vw;
            height: 22vh;
            padding-top: 2.5vh;
            box-sizing: border-box;
          "
        >
          <div id="oneChart" style="width: 100%; height: 100%"></div>
        </div>
      </div>
      <div class="two-container">
        <div class="left-title">年度告警排行</div>
        <table style="cursor: pointer" @click="goAlarmRanking">
          <tr>
            <td style="text-align: center">序号</td>
            <td style="text-align: center">电站名称</td>
          </tr>
          <tr
            style="font-size: calc(100vw * 16 / 1920)"
            v-for="(item, index) in yearlyAlarm"
            :key="index"
          >
            <td style="text-align: center">{{ index + 1 }}</td>
            <td style="text-align: center">{{ item.psName }}</td>
          </tr>
        </table>
      </div>
      <div class="three-container">
        <div class="left-title">告警排行</div>
        <div
          style="
            width: 100%;
            height: 24vh;
            padding-top: 2.5vh;
            box-sizing: border-box;
          "
        >
          <div id="twoChart" style="width: 100%; height: 100%"></div>
        </div>
      </div>
    </div>
    <div class="right-container">
      <div class="four-container">
        <div class="right-title">总维修数</div>
        <div
          style="
            width: 24vw;
            height: 25vh;
            padding-top: 2.5vh;
            box-sizing: border-box;
          "
        >
          <div id="threeChart" style="width: 100%; height: 100%"></div>
        </div>
      </div>
      <div class="five-container">
        <div class="right-title" style="padding: 0.5vh 0 0 15vw">
          维修处理年度排行
        </div>
        <table style="cursor:pointer" @click="goRepair">
          <tr>
            <td>序号</td>
            <td>电站名称</td>
            <td>运维数</td>
          </tr>
          <tr
            style="font-size: calc(100vw * 16 / 1920)"
            v-for="(item, index) in repairList"
            :key="index"
          >
            <td>{{ item.moId }}</td>
            <td>{{ item.companyName }}</td>
            <td>{{ item.val }}</td>
          </tr>
        </table>
      </div>
      <div class="six-container">
        <div class="right-title" style="padding: 0.5vh 0 0 16.5vw">
          告警类型分布
        </div>
        <div
          style="
            width: 24vw;
            height: 21vh;
            padding-top: 2.5vh;
            box-sizing: border-box;
          "
        >
          <div id="fourChart" style="width: 100%; height: 100%"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getMap } from "@/api/home";
import {
  getAlarmToal,
  getAlarmYearly,
  getRepairYearly,
  getAlarmType,
  getRepairNum,
} from "@/api/intelligence";
import { AMapManager, lazyAMapApiLoaderInstance } from "vue-amap";
let amapManager = new AMapManager();
export default {
  name: "indexOverview",
  data() {
    let self = this;
    return {
      pointsList: [],
      windowWidth: 1920,
      windowHeight: 1080,
      alarmsNum: [], // 告警总数
      yearlyAlarm: [], // 年度告警排行
      repairList: [], // 维修处理年度排行
      alarmType: [], // 告警类型分布
      repairNum: [], // 维修数
      //地图开始
      //   address: null,
      //   searchKey: "",
      //   amapManager,
      //   markers: [],
      //   searchOption: {
      //     city: "全国",
      //     citylimit: true,
      //   },
      //   center: [121.329402, 31.228667],
      //   zoom: 0,
      //   lng: 0,
      //   lat: 0,
      //   loaded: false,
      //   markerEvent: {
      //     click(e) {
      //       // console.log(e, "打开吗");
      //     },
      //   },
      //   events: {
      //     init() {
      //       lazyAMapApiLoaderInstance.load().then(() => {
      //         self.initSearch();
      //       });
      //     },
      //     // 点击获取地址的数据
      //     click(e) {
      //       self.markers = [];
      //       let { lng, lat } = e.lnglat;
      //       self.lng = lng;
      //       self.lat = lat;
      //       self.center = [lng, lat];
      //       self.markers.push([lng, lat]);
      //       // 这里通过高德 SDK 完成。
      //       let geocoder = new AMap.Geocoder({
      //         radius: 1000,
      //         extensions: "all",
      //       });
      //       geocoder.getAddress([lng, lat], function (status, result) {
      //         if (status === "complete" && result.info === "OK") {
      //           if (result && result.regeocode) {
      //             console.log(result.regeocode.addressComponent);
      //             console.log(result.regeocode.addressComponent.province);
      //             console.log(result.regeocode.addressComponent.district);
      //             console.log(result.regeocode.formattedAddress);
      //             console.log("经度" + lng);
      //             console.log("纬度" + lat);
      //             self.areaPlant =
      //               result.regeocode.addressComponent.township +
      //               result.regeocode.addressComponent.neighborhood;
      //             self.address = result.regeocode.formattedAddress;
      //             self.searchKey = result.regeocode.formattedAddress;
      //             self.$nextTick();
      //           }
      //         }
      //       });
      //     },
      //   },
      //   // 一些工具插件
      //   plugin: [
      //     {
      //       // 定位
      //       pName: "Geolocation",
      //       events: {
      //         init(o) {
      //           // o是高德地图定位插件实例
      //           o.getCurrentPosition((status, result) => {
      //             if (result && result.position) {
      //               // 设置经度
      //               self.lng = result.position.lng;
      //               // 设置维度
      //               self.lat = result.position.lat;
      //               // 设置坐标
      //               self.center = [self.lng, self.lat];
      //               self.markers.push([self.lng, self.lat]);
      //               // load
      //               self.loaded = true;
      //               // 页面渲染好后
      //               self.$nextTick();
      //             }
      //           });
      //         },
      //         click(e) {
      //           console.log(e);
      //         },
      //       },
      //     },
      //     {
      //       // 地图类型
      //       pName: "MapType",
      //       defaultType: 1,
      //       events: {
      //         init(instance) {
      //           console.log(instance);
      //         },
      //       },
      //     },
      //   ],

      //   //地图结尾
    };
  },
  methods: {
    // 年度告警排行
    goAlarmRanking() {
      this.$router.push("/alarm");
    },
    // 维修处理
    goRepair(){
      this.$router.push('/repairManagement')
    },
    nowWidthSize(val) {
      let width = document.documentElement.clientWidth;
      let size = (width * val) / this.windowWidth;
      console.log(size, "size");
      return size;
    },
    nowHeightSize(val) {
      let height = document.documentElement.clientHeight;
      let size = (height * val) / this.windowHeight;
      console.log(size, "size");
      return size;
    },
    //地图开始
    // searchByHand() {
    //   if (this.searchKey !== "") {
    //     this.poiPicker.searchByKeyword(this.searchKey);
    //   }
    // },

    // initSearch() {
    //   let vm = this;
    //   let map = this.amapManager.getMap();
    //   AMapUI.loadUI(["misc/PoiPicker"], function (PoiPicker) {
    //     var poiPicker = new PoiPicker({
    //       input: "search",
    //       placeSearchOptions: {
    //         map: map,
    //         pageSize: 10,
    //       },
    //       suggestContainer: "searchTip",
    //       searchResultsContainer: "searchTip",
    //     });
    //     vm.poiPicker = poiPicker;
    //     // 监听poi选中信息
    //     poiPicker.on("poiPicked", function (poiResult) {
    //       // console.log(poiResult)
    //       let source = poiResult.source;
    //       let poi = poiResult.item;
    //       if (source !== "search") {
    //         poiPicker.searchByKeyword(poi.name);
    //       } else {
    //         poiPicker.clearSearchResults();
    //         vm.markers = [];
    //         let lng = poi.location.lng;
    //         let lat = poi.location.lat;
    //         const address = poi.cityname + poi.adname + poi.name;
    //         vm.center = [lng, lat];
    //         console.log(vm.center);
    //         vm.markers.push([lng, lat]);
    //         vm.lng = lng;
    //         vm.lat = lat;
    //         vm.address = address;
    //         vm.searchKey = address;
    //       }
    //     });
    //   });
    // },

    //地图结尾

    initMap() {
      // let map = new BMapGL.Map(this.$refs.allmap);
      // map.centerAndZoom(new BMapGL.Point(118.5, 27.5), 5); // 初始化地图,设置中心点坐标和地图级别
      // map.enableScrollWheelZoom(true); //开启鼠标滚轮缩放
      // map.setMapType(BMAP_EARTH_MAP); // 设置地图类型为地球模式

      var map = new AMap.Map("container", {
        center: [104.937478, 35.439575],
        mapStyle: "amap://styles/grey",
        zoom: 5,
      });
      // var points = [];
      console.log(this.pointsList, "和杜萨会");
      var points = this.pointsList;

      console.log(points, "的那瞬间");

      var count = points.length;
      console.log(count, "安徽队啊水");
      // 数据中增加权重信息，以权重高的点为中心进行聚合
      // 本示例中北京等城市中心点权重较高

      var _renderClusterMarker = function (context) {
        // 聚合中点个数
        var clusterCount = context.count;
        var div = document.createElement("div");
        // 聚合点配色
        var defaultColor = [
          "204,235,197",
          "168,221,181",
          "123,204,196",
          "78,179,211",
          "43,140,190",
        ];
        var bgColor = "";
        if (clusterCount >= 0 && clusterCount < 10) {
          bgColor = defaultColor[0];
        } else if (clusterCount >= 10 && clusterCount < 100) {
          bgColor = defaultColor[1];
        } else if (clusterCount >= 100 && clusterCount < 1000) {
          bgColor = defaultColor[2];
        } else if (clusterCount >= 1000 && clusterCount < 10000) {
          bgColor = defaultColor[3];
        } else if (clusterCount >= 10000) {
          bgColor = defaultColor[4];
        }
        div.style.backgroundColor = "rgba(" + bgColor + ",.5)";
        var size = Math.round(25 + Math.pow(clusterCount / count, 1 / 5) * 40);
        div.style.width = div.style.height = size + "px";
        div.style.border = "solid 1px rgba(" + bgColor + ",1)";
        div.style.borderRadius = size / 2 + "px";
        div.innerHTML = context.count;
        div.style.lineHeight = size + "px";
        div.style.color = "#ffffff";
        div.style.fontSize = "12px";
        div.style.textAlign = "center";
        context.marker.setOffset(new AMap.Pixel(-size / 2, -size / 2));
        context.marker.setContent(div);
      };
      var _renderMarker = function (context) {
        var content =
          '<div style="background-color: rgba(255,255,178,.9); height: 18px; width: 18px; border: 1px solid rgba(255,255,178,1); border-radius: 12px; box-shadow: rgba(0, 0, 0, 1) 0px 0px 3px;"></div>';
        var offset = new AMap.Pixel(-9, -9);
        context.marker.setContent(content);
        context.marker.setOffset(offset);
      };
      var cluster = new AMap.MarkerCluster(map, points, {
        gridSize: 60, // 聚合网格像素大小
        renderClusterMarker: _renderClusterMarker, // 自定义聚合点样式
        renderMarker: _renderMarker, // 自定义非聚合点样式
      });
    },

    // 第一张图
    getEchartOne() {
      let myChart1 = this.$echarts.init(document.getElementById("oneChart"));
      getAlarmToal().then((res) => {
        console.log(res, "是滴哦奥");
        this.alarmsNum = res.data;
        myChart1.setOption({
          legend: {
            left: this.nowWidthSize(145),
            bottom: this.nowHeightSize(20.5),
            icon: "circle",
            textStyle: {
              color: "#fff",
            },
          },
          series: [
            {
              type: "pie",
              radius: "50%",
              center: ["45%", "40%"],
              label: {
                normal: {
                  formatter: "{c}",
                  textStyle: {
                    color: "#17E0FE",
                  },
                },
              },
              color: ["#286AE8", "#06B4FC"],
              data: this.alarmsNum,
            },
          ],
        });
        let that = this;
        myChart1.on("click", function (param) {
          that.$router.push("/alarm");
        });
        window.addEventListener("resize", function () {
          myChart1.resize();
        });
      });
    },
    // 第二张图
    getEchartTwo() {
      let myChart2 = this.$echarts.init(document.getElementById("twoChart"));
      getAlarmYearly().then((res) => {
        let header = [];
        let val = [];
        for (let i in res.data) {
          header.push(res.data[i].psName);
          val.push(res.data[i].val);
        }
        myChart2.setOption({
          tooltip: {
            trigger: "axis",
            formatter: function (params) {
              return params[0].name + "：" + params[0].data + "kw";
            },
          },
          xAxis: [
            {
              type: "category",
              axisTick: { show: false },
              axisLine: {
                lineStyle: {
                  color: "#0B3A56",
                },
              },
              axisLabel: {
                color: "#06B4FC",
              },
              data: header,
            },
          ],
          yAxis: [
            {
              axisTick: {
                show: false,
              },
              axisLine: {
                show: false,
              },
              nameTextStyle: {
                color: "#06B4FC",
              },
              type: "value",
              splitLine: {
                lineStyle: {
                  color: "#0B3A56",
                },
              },
              axisLabel: {
                color: "#06B4FC",
              },
            },
          ],
          series: [
            {
              type: "bar",
              barGap: 0,
              barWidth: 20,
              itemStyle: {
                color: "#06B4FC",
              },
              emphasis: {
                focus: "series",
              },
              data: val,
            },
          ],
          grid: {
            left: this.nowWidthSize(60),
            top: this.nowHeightSize(50),
            right: this.nowWidthSize(30),
            bottom: this.nowHeightSize(40),
          },
        });
        let that = this;
        myChart2.on("click", function (param) {
          that.$router.push("/alarm");
        });
        window.addEventListener("resize", function () {
          myChart2.resize();
        });
      });
    },
    // 第三张图
    getEchartThree() {
      let myChart3 = this.$echarts.init(document.getElementById("threeChart"));
      getRepairNum().then((res) => {
        this.repairNum = res.data;
        myChart3.setOption({
          legend: {
            left: this.nowWidthSize(145),
            bottom: this.nowHeightSize(5),
            icon: "circle",
            textStyle: {
              color: "#fff",
            },
          },
          series: [
            {
              type: "pie",
              radius: "60%",
              center: ["45%", "40%"],
              label: {
                normal: {
                  formatter: "{c}",
                  textStyle: {
                    color: "#17E0FE",
                  },
                },
              },
              color: ["#286AE8", "#06B4FC"],
              data: this.repairNum,
            },
          ],
        });
        let that = this;
        myChart3.on("click", function (param) {
          that.$router.push("/repairManagement");
        });
        window.addEventListener("resize", function () {
          myChart3.resize();
        });
      });
    },
    getEchartFour() {
      let myChart4 = this.$echarts.init(document.getElementById("fourChart"));
      getAlarmType().then((res) => {
        this.alarmType = res.data;
        myChart4.setOption({
          legend: {
            left: this.nowWidthSize(145),
            bottom: this.nowHeightSize(5),
            icon: "circle",
            textStyle: {
              color: "#fff",
            },
          },
          series: [
            {
              type: "pie",
              radius: "60%",
              center: ["48%", "40%"],
              label: {
                normal: {
                  formatter: "{c}",
                  textStyle: {
                    color: "#17E0FE",
                  },
                },
              },
              color: ["#286AE8", "#06B4FC"],
              data: this.alarmsNum,
            },
          ],
        });
        let that = this;
        myChart4.on("click", function (param) {
          that.$router.push("/alarm");
        });
        window.addEventListener("resize", function () {
          myChart4.resize();
        });
      });
    },
    // 年度告警排行
    getYearly() {
      getAlarmYearly().then((res) => {
        this.yearlyAlarm = res.data;
      });
    },
    // 维修处理年度排行
    getRepair() {
      getRepairYearly().then((res) => {
        this.repairList = res.data;
      });
    },
  },
  mounted() {
    this.windowHeight = window.screen.height;
    this.windowWidth = window.screen.width;

    this.getYearly();
    this.getRepair();
    setTimeout(() => {
      this.getEchartOne();
    }, 500);
    this.getEchartTwo();
    this.getEchartThree();
    setTimeout(() => {
      this.getEchartFour();
    }, 500);
    this.initMap();
    getMap().then((res) => {
      if (res.code == 200) {
        this.pointsList = res.data;
        this.initMap();
      } else {
        this.initMap();
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.left-title {
  color: #fff;
  font-size: calc(100vw * 16 / 1920);
  padding: 0.5vh 0 0 3.5vw;
}
.right-title {
  color: #fff;
  font-size: calc(100vw * 16 / 1920);
  padding: 0.5vh 0 0 17vw;
}
table {
  padding: 3vh 2vw 0 3.8vw;
  box-sizing: border-box;
  width: 100%;
  height: 23vh;
  color: #fff;
  font-size: calc(100vw * 18 / 1920);
  text-align: center;
}
.container::v-deep {
  width: 100%;
  position: relative;
  .allmap {
    width: 100%;
    height: 100%;
  }

  .left-container {
    position: absolute;
    left: 0;
    top: 0.4vh;
    width: 24vw;
    height: 100%;
    .one-container,
    .two-container {
      position: absolute;
      left: 1.5vw;
      width: 100%;
      margin-bottom: 1.8vh;
    }
    .one-container {
      top: 1.3vh;
      background: url(@/assets/images/box3.png);
      background-size: 100% 100%;
      height: 24vh;
    }
    .two-container {
      top: 27.3vh;
      background: url(@/assets/images/box2.png);
      background-size: 100% 100%;
      height: 25.9vh;
    }
    .three-container {
      position: absolute;
      top: 55vh;
      left: 1.5vw;
      background: url(@/assets/images/box1.png);
      background-size: 100% 100%;
      width: 100%;
      height: 27.6vh;
    }
  }
  .right-container {
    position: absolute;
    top: 0.4vh;
    right: 0;
    width: 24vw;
    height: 100%;
    .four-container,
    .five-container,
    .six-container {
      position: absolute;
      right: 1.5vw;
      width: 100%;
    }
    .four-container {
      top: 1.3vh;
      height: 28.2vh;
      background: url(@/assets/images/box9.png);
      background-size: 100% 100%;
    }
    .five-container {
      top: 31vh;
      height: 26.3vh;
      background: url(@/assets/images/box10.png);
      background-size: 100% 100%;
    }
    .six-container {
      top: 59vh;
      height: 24vh;
      background: url(@/assets/images/box8.png);
      background-size: 100% 100%;
    }
  }
}
</style>

<!-- 地图style -->
<style lang="scss" scoped>
.container_ {
  width: 100%;
  height: 88.2vh;
  position: absolute;
  top: 0;
  left: 0;
}
::v-deep .amap-maptype-con,
::v-deep .amap-maptype-list,
::v-deep .amap-geolocation-con,
::v-deep .amap-logo,
::v-deep .amap-copyright {
  display: none !important;
}
::v-deep .amap-logo,
::v-deep .amap-copyright {
  display: none !important;
}
</style>
