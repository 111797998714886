<template>
  <div class="container">
    <div class="left-container">
      <div class="left-one">
        <div class="container-title">电站总览</div>
        <table @click="goManagementDetail" style="cursor: pointer">
          <tr>
            <td style="text-align: center">共计</td>
            <td style="text-align: center">光伏站</td>
            <td style="text-align: center">储能站</td>
          </tr>
          <tr>
            <td style="text-align: center; color: #17e0fe">
              {{ overview.sum }}
            </td>
            <td style="text-align: center">
              <img
                style="width: 1.6vw; height: 3vh"
                src="../assets/images/station.png"
              />
            </td>
            <td style="text-align: center">
              <img
                style="width: 1.6vw; height: 3vh"
                src="../assets/images/power.png"
              />
            </td>
          </tr>
          <tr>
            <td>站点数</td>
            <td style="color: #17e0fe">{{ overview.plsSum }}</td>
            <td>{{ overview.sesSum }}</td>
          </tr>
          <tr>
            <td>运行数</td>
            <td style="color: #17e0fe">{{ overview.plRunSum }}</td>
            <td style="color: #17e0fe">{{ overview.seRunSum }}</td>
          </tr>
          <tr>
            <td>在线率</td>
            <td style="color: #17e0fe">{{ overview.plOr }}</td>
            <td style="color: #17e0fe">{{ overview.seOr }}</td>
          </tr>
        </table>
      </div>
      <div class="left-two">
        <div class="container-title">电站概况</div>
        <table
          @click="goOverviewDetail"
          style="
            padding: 3vh 2vw 0 3.8vw;
            box-sizing: border-box;
            height: 23vh;
            cursor: pointer;
          "
        >
          <tr>
            <td style="text-align: left">装机容量(MWP)</td>
            <td style="text-align: right; color: #17e0fe">{{ summary.kw }}</td>
          </tr>
          <tr>
            <td style="text-align: left">逆变器(台)</td>
            <td style="text-align: right; color: #17e0fe">{{ summary.vsi }}</td>
          </tr>
          <tr>
            <td style="text-align: left">汇流箱(台)</td>
            <td style="text-align: right; color: #17e0fe">{{ summary.bbc }}</td>
          </tr>
          <tr>
            <td style="text-align: left">组串(组)</td>
            <td style="text-align: right; color: #17e0fe">{{ summary.bbs }}</td>
          </tr>
        </table>
      </div>
      <div class="left-three">
        <div class="container-title">设备统计</div>
        <div
          style="
            width: 24vw;
            height: 22vh;
            padding-top: 2.5vh;
            box-sizing: border-box;
          "
        >
          <div id="onePie" style="width: 100%; height: 100%"></div>
        </div>
      </div>
    </div>
    <div class="middle-container">
      <div class="allmap-box">
        <div id="allmap" ref="allmap" class="allmap"></div>
        <div class="go-map-btn" @click="goMap">点击查看全景</div>
      </div>
      <div class="bottom">
        <div class="bottom-title">能源趋势</div>
        <div class="month-btn">
          <div
            v-for="(item, index) in btnList"
            :key="index"
            :class="btnIndex == index ? 'btn-content active' : 'btn-content'"
            @click="changeDate(index)"
          >
            {{ item.name }}
          </div>
        </div>
        <div
          style="position: absolute; left: 1.2vw; width: 41.4vw; height: 24vh"
        >
          <div id="oneBar" style="width: 100%; height: 100%"></div>
        </div>
      </div>
    </div>
    <div class="right-container">
      <div class="right-one">
        <div class="right-title">工单统计</div>
        <div class="right-one-content">
          <div class="right-one-content-item">
            <div class="item-top">{{ workNum.sum }}</div>
            <div class="item-bottom">工单总数</div>
          </div>
          <div class="right-one-content-item">
            <div class="item-top">{{ workNum.cpSum }}</div>
            <div class="item-bottom">已完成</div>
          </div>
          <div class="right-one-content-item">
            <div class="item-top">{{ workNum.unSum }}</div>
            <div class="item-bottom">未完成</div>
          </div>
        </div>
      </div>
      <div class="right-two">
        <div class="right-title">环境概况</div>
        <div class="right-two-content" @click="goEnvironmentDetail">
          <div class="right-two-item">
            <div style="flex: 38%">辐射量</div>
            <div style="flex: 35%">
              <img src="@/assets/images/si.png" />
            </div>
            <div>{{ environment.rq }}SI</div>
          </div>
          <div class="right-two-item">
            <div style="flex: 38%">风速</div>
            <div style="flex: 35%">
              <img src="@/assets/images/s.png" />
            </div>
            <div>{{ environment.ws }}/s</div>
          </div>
          <div class="right-two-item">
            <div style="flex: 38%">温度</div>
            <div style="flex: 35%">
              <img src="@/assets/images/c.png" />
            </div>
            <div>{{ environment.te }}/C</div>
          </div>
        </div>
      </div>
      <div class="right-three">
        <div class="right-title">报警系统</div>
        <div
          class="right-one-content"
          style="cursor: pointer"
          @click="goAlarmDetail"
        >
          <div class="right-one-content-item">
            <div class="item-bottom">报警系统</div>
            <div class="item-top">{{ alarmSystem.sum }}</div>
          </div>
          <div class="right-one-content-item">
            <div class="item-bottom">已处理</div>
            <div class="item-top">{{ alarmSystem.cpSum }}</div>
          </div>
          <div class="right-one-content-item">
            <div class="item-bottom">未处理</div>
            <div class="item-top">{{ alarmSystem.unSum }}</div>
          </div>
        </div>
      </div>
      <div class="right-four">
        <div class="right-title">节能减排</div>
        <div class="four-content">
          <div class="four-content-item">
            <img src="../assets/images/car.png" />
            <div class="num">{{ reduceList.tce }}吨</div>
            <div class="tip">节约标准煤</div>
          </div>
          <div class="four-content-item">
            <img src="../assets/images/co2.png" />
            <div class="num">{{ reduceList.reduction }}吨</div>
            <div class="tip">CO2减排量</div>
          </div>
          <div class="four-content-item">
            <img src="../assets/images/tree.png" />
            <div class="num">{{ reduceList.plantation }}棵</div>
            <div class="tip">等效植树量</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getOverviewOfPower,
  getSummaryOfPower,
  getEquipmentTotal,
  getWorkNum,
  getEnvironment,
  getAlarmTotal,
  getReduce,
  getEnergy,
} from "@/api/home";
export default {
  name: "index",
  data() {
    return {
      windowWidth: 1920,
      windowHeight: 1080,
      overview: {}, // 电站总览
      summary: {}, // 电站概况
      map: "",
      equipmentList: [],
      btnList: [
        { name: "月", value: "2" },
        { name: "年", value: "3" },
        { name: "历年", value: "4" },
      ],
      btnIndex: 0,
      workNum: {}, // 工单统计
      environment: {}, // 环境概况
      alarmSystem: {}, // 报警系统
      reduceList: {}, // 节能减排
    };
  },
  methods: {
    // 电站总览
    goManagementDetail() {
      this.$router.push("/stationManagement");
    },
    // 电站概况
    goOverviewDetail() {
      this.$router.push("/stationOverview");
    },
    // 环境概况
    goEnvironmentDetail() {
      this.$router.push("/repairManagement");
    },
    // 报警系统
    goAlarmDetail() {
      this.$router.push("/alarm");
    },
    nowWidthSize(val) {
      let width = document.documentElement.clientWidth;
      let size = (width * val) / this.windowWidth;
      console.log(size, "size");
      return size;
    },
    nowHeightSize(val) {
      let height = document.documentElement.clientHeight;
      let size = (height * val) / this.windowHeight;
      console.log(size, "size");
      return size;
    },
    // 切换月、年、历年
    changeDate(index) {
      this.btnIndex = index;
      this.getEchartTwo();
    },
    // 地图跳转全景地图
    goMap() {
      this.$router.push({
        path: "/map",
      });
    },
    // 地图
    initMap() {
      let map = new BMapGL.Map(this.$refs.allmap);
      // map.centerAndZoom(new BMapGL.Point(118.5, 27.5), 5); // 初始化地图,设置中心点坐标和地图级别
      map.centerAndZoom(new BMapGL.Point(118, 5)); // 初始化地图,设置中心点坐标和地图级别
      map.enableScrollWheelZoom(true); //开启鼠标滚轮缩放
      map.setMapType(BMAP_EARTH_MAP); // 设置地图类型为地球模式
    },
    // 饼图
    getEchartOne() {
      let myChart1 = this.$echarts.init(document.getElementById("onePie"));
      getEquipmentTotal().then((res) => {
        this.equipmentList = res.data;
        myChart1.setOption({
          legend: {
            right: this.nowWidthSize(22),
            top: this.nowHeightSize(45),
            icon: "circle",
            orient: "vertical",
            textStyle: {
              color: "#fff",
            },
          },
          series: [
            {
              type: "pie",
              radius: ["25%", "50%"],
              center: ["45%", "48%"],
              label: {
                normal: {
                  formatter: "{c}",
                  textStyle: {
                    color: "#17E0FE",
                  },
                },
              },
              color: ["#06B4FC", "#1B66A6", "#0876FC"],
              data: this.equipmentList,
            },
          ],
        });
        let that = this;
        myChart1.on("click", function (param) {
          that.$router.push("/stationManagement");
        });
        window.addEventListener("resize", function () {
          myChart1.resize();
        });
      });
    },
    // 柱状图
    getEchartTwo() {
      let myChart2 = this.$echarts.init(document.getElementById("oneBar"));
      let type = this.btnList[this.btnIndex].value;
      getEnergy(type).then((res) => {
        let header = res.data.header;
        console.log(header, "手机东京阿松");
        let val1 = [];
        let val2 = [];
        for (let i in res.data.val) {
          console.log(res.data.val);
          if (res.data.val[i].type == "A1") {
            val1 = res.data.val[i].data;
          } else {
            val2 = res.data.val[i].data;
          }
        }
        console.log(val2, "h");
        myChart2.setOption({
          tooltip: {
            trigger: "axis",
          },
          legend: {
            top: this.nowHeightSize(10),
            icon: "circle",
            textStyle: {
              color: "#fff",
            },
            data: ["市电", "太阳能"],
          },
          xAxis: [
            {
              name: "日",
              nameTextStyle: {
                color: "#06B4FC",
              },
              type: "category",
              axisTick: { show: false },
              axisLine: {
                lineStyle: {
                  color: "#0B3A56",
                },
              },
              axisLabel: {
                color: "#06B4FC",
              },
              data: header,
            },
          ],
          yAxis: [
            {
              name: "kw/h",
              axisTick: {
                show: false,
              },
              axisLine: {
                show: false,
              },
              nameTextStyle: {
                color: "#06B4FC",
              },
              type: "value",
              splitLine: {
                lineStyle: {
                  color: "#0B3A56",
                },
              },
              axisLabel: {
                color: "#06B4FC",
              },
            },
          ],
          series: [
            {
              name: "市电",
              type: "bar",
              barGap: 0,
              itemStyle: {
                color: "#06B4FC",
              },
              emphasis: {
                focus: "series",
              },
              data: val1,
            },
            {
              name: "太阳能",
              type: "bar",
              barGap: 0,
              itemStyle: {
                color: "#286AE8",
              },
              emphasis: {
                focus: "series",
              },
              data: val2,
            },
          ],
          grid: {
            left: this.nowWidthSize(110),
            top: this.nowHeightSize(50),
            right: this.nowWidthSize(119),
            bottom: this.nowHeightSize(49),
          },
        });
      });
      let that = this;
      myChart2.on("click", function (param) {
        that.$router.push({
          path: "/totalEnergyConsumption",
          query: {
            type: 13,
            date: 1,
          },
        });
        window.addEventListener("resize", function () {
          myChart2.resize();
        });
      });
    },
    // 电站总览
    getOne() {
      getOverviewOfPower().then((res) => {
        this.overview = res.data;
      });
    },
    // 电站概况
    getTwo() {
      getSummaryOfPower().then((res) => {
        this.summary = res.data;
      });
    },
    // 工单数量
    getThree() {
      getWorkNum().then((res) => {
        this.workNum = res;
      });
    },
    // 环境概况
    getFour() {
      getEnvironment().then((res) => {
        this.environment = res;
      });
    },
    // 报警系统
    getFive() {
      getAlarmTotal().then((res) => {
        this.alarmSystem = res;
      });
    },
    // 节能减排
    getSix() {
      getReduce().then((res) => {
        this.reduceList = res;
      });
    },
  },
  mounted() {
    this.windowHeight = window.screen.height;
    this.windowWidth = window.screen.width;
    this.initMap();
    this.getEchartOne();
    this.getEchartTwo();
    this.getOne();
    this.getTwo();
    this.getThree();
    this.getFour();
    this.getFive();
    this.getSix();
  },
};
</script>

<style lang="scss" scoped>
.container-title {
  color: #fff;
  font-size: calc(100vw * 16 / 1920);
  padding: 0.5vh 0 0 3.5vw;
  box-sizing: border-box;
}
.right-title {
  position: absolute;
  right: 4vw;
  top: 0.5vh;
  color: #fff;
  font-size: calc(100vw * 16 / 1920);
}
.right-one-content {
  padding-top: 6.8vh;
  box-sizing: border-box;
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: #fff;
  .right-one-content-item {
    line-height: 3.5vh;
    text-align: center;
    .item-top {
      font-size: calc(100vw * 22 / 1920);
      color: #17e0fe;
    }
    .item-bottom {
      font-size: calc(100vw * 16 / 1920);
    }
  }
}
table {
  padding-top: 3vh;
  box-sizing: border-box;
  width: 100%;
  height: 24vh;
  color: #fff;
  font-size: calc(100vw * 16 / 1920);
  text-align: center;
  td {
    vertical-align: middle !important;
  }
}
.container::v-deep {
  width: 100%;
  height: 100%;
  padding: 2vh 1vw 0 1vw;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  .middle-container {
    width: 47.3vw;
    margin: 0 1vw;
    .allmap-box {
      width: 100%;
      height: 55.4vh;
      margin-bottom: 1.8vh;
      position: relative;
      .allmap {
        width: 100%;
        height: 55.5vh;
        position: absolute;
        top: 0;
        left: 0;
        .anchorBL {
          display: none !important;
        }
      }
      .go-map-btn {
        border-radius: 3px;
        position: absolute;
        bottom: 0;
        right: 0;
        z-index: 999;
        width: 7vw;
        height: 2.5vh;
        font-size: calc(100vw * 15 / 1920);
        color: #fff;
        line-height: 2.5vh;
        text-align: center;
        cursor: pointer;
      }
    }
    .bottom {
      position: relative;
      width: 100%;
      height: 24vh;
      background: url(@/assets/images/box4.png);
      background-size: 100% 100%;
      display: flex;
      .bottom-title {
        position: absolute;
        top: 7.5vh;
        left: 0.4vw;
        color: #fff;
        font-size: calc(100vw * 15 / 1920);
        writing-mode: vertical-lr;
        text-align: center;
        letter-spacing: 5px;
      }
      .month-btn {
        display: flex;
        position: absolute;
        right: 3.1vw;
        top: 1.2vh;
        width: 16vh;
        height: 1.8vh;
        .btn-content {
          z-index: 99;
          background: url(@/assets/images/btnBg1.png);
          background-size: 100% 100%;
          width: 2.3vw;
          height: 100%;
          margin-right: 0.3vw;
          color: #fff;
          line-height: 1.8vh;
          text-align: center;
          font-size: calc(100vw * 11 / 1920);
          cursor: pointer;
        }
        .active {
          background: url(@/assets/images/btnBg2.png);
          background-size: 100% 100%;
        }
      }
    }
  }
  .left-container {
    width: 23.8vw;
    .left-one,
    .left-two {
      width: 100%;
      margin-bottom: 1.8vh;
    }
    .left-one {
      background: url(@/assets/images/box1.png);
      background-size: 100% 100%;
      height: 27.6vh;
    }
    .left-two {
      background: url(@/assets/images/box2.png);
      background-size: 100% 100%;
      height: 25.9vh;
    }
    .left-three {
      background: url(@/assets/images/box3.png);
      background-size: 100% 100%;
      width: 100%;
      height: 24vh;
      margin-bottom: 1.8vh;
    }
  }
  .right-container {
    width: 24.6vw;
    .right-one,
    .right-two,
    .right-three {
      width: 100%;
      margin-bottom: 1.8vh;
      position: relative;
    }
    .right-one,
    .right-three {
      height: 15.7vh;
    }
    .right-one {
      background: url(@/assets/images/box5.png);
      background-size: 100% 100%;
    }
    .right-two {
      background: url(@/assets/images/box6.png);
      background-size: 100% 100%;
      height: 20.3vh;
      .right-two-content {
        position: absolute;
        left: 0;
        right: 0;
        width: 14.5vw;
        margin: 6.5vh auto;
        cursor: pointer;
        .right-two-item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: calc(100vw * 16 / 1920);
          color: #fff;
          height: 3vh;
          margin-bottom: 1.4vh;
          img {
            width: 1.6vw;
            height: 3vh;
          }
        }
      }
    }
    .right-three {
      background: url(@/assets/images/box7.png);
      background-size: 100% 100%;
    }
    .right-four {
      position: relative;
      width: 100%;
      height: 24vh;
      background: url(@/assets/images/box8.png);
      background-size: 100% 100%;
      .four-content {
        padding-top: 8.6vh;
        display: flex;
        justify-content: space-around;
        align-items: center;
        .four-content-item {
          text-align: center;
          img {
            width: 2.8vw;
            height: 5vh;
          }
          .num {
            margin: 0.3vh 0 0.2vh 0;
            color: #17e0fe;
            font-size: calc(100vw * 18 / 1920);
          }
          .tip {
            color: #fff;
            font-size: calc(100vw * 16 / 1920);
          }
        }
      }
    }
  }
}
</style>
