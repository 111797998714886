<template>
  <div class="container">
    <div class="left-part">
      <div class="equipment-title" v-if="$route.query.id == undefined">节点录入</div>
      <div class="equipment-title" v-if="$route.query.id != undefined">节点编辑</div>
    </div>
    <div style="width: 80vw; height: 100%">
      <el-form :model="form" ref="form" :rules="rules">
        <div class="form-item">
          <el-form-item label="企业名称：" prop="etId">
            <el-select
            placeholder="请选择企业名称"
              @change="changeCompany"
              v-model="form.etId"
              style="width: 20vw"
            >
              <el-option
                v-for="(item, index) in companyList"
                :key="index"
                :label="item.etName"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <div class="tip">（必填）</div>
          <el-form-item label="电站名称：" prop="psId">
            <el-select
              placeholder="请选择电站名称"
              v-model="form.psId"
              style="width: 20vw"
              :disabled="disabled"
            >
              <el-option
                v-for="(item, index) in stationList"
                :key="index"
                :label="item.psName"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <div class="tip">（必填）</div>
        </div>
        <div class="form-item">
          <el-form-item label="最后刷新时间：">
            <el-input
              v-model="form.lastRefreshTime"
              disabled
              placeholder="系统默认时间，无需添加"
              style="width: 20vw"
            ></el-input>
          </el-form-item>
          <!-- <div class="tip">（必填）</div> -->
        </div>
        <div class="form-item">
          <el-form-item label="节点名称：" prop="ndName">
            <el-input v-model="form.ndName" style="width: 20vw" placeholder="请输入节点名称"></el-input>
          </el-form-item>
          <div class="tip">（必填）</div>
          <el-form-item label="总流量：">
            <el-input v-model="form.ndTotalFlow" style="width: 20vw" placeholder="请输入总流量"></el-input>
          </el-form-item>
        </div>
        <div class="form-item">
          <!-- <div class="form-item-label">
                    <div style="font-size:calc(100vw * 18 / 1920);">节点：</div>
                    <div style="font-size:calc(100vw * 12 / 1920);">节点MAC码</div>
                </div> -->
          <el-form-item label="节点MAC码：" prop="ndMac">
            <el-input v-model="form.ndMac" style="width: 20vw" placeholder="请输入节点MAC码"></el-input>
          </el-form-item>
          <div class="tip">（必填）</div>
          <el-form-item label="已使用流量：">
            <el-input v-model="form.ndUsedFlow" style="width: 20vw" placeholder="请输入已使用流量"></el-input>
          </el-form-item>
        </div>
        <div class="form-item">
          <el-form-item label="SIM卡号：" prop="ndSmcard">
            <el-input v-model="form.ndSmcard" style="width: 20vw" placeholder="请输入SIM卡号"></el-input>
          </el-form-item>
          <div class="tip">（必填）</div>
          <el-form-item label="数据上传方式：" prop="dup">
            <el-select
            placeholder="请输入数据上传方式"
              v-model="form.dup"
              style="width: 20vw"
            >
              <el-option
                label="4G"
                value="4G"
              ></el-option>
              <el-option
                label="WIFI"
                value="WIFI"
              ></el-option>
              <el-option
                label="NB"
                value="NB"
              ></el-option>
              <el-option
                label="LORA"
                value="LORA"
              ></el-option>
              <el-option
                label="以太网"
                value="以太网"
              ></el-option>
            </el-select>
          </el-form-item>
          <div class="tip">（必填）</div>
        </div>
        <div class="form-item">
          <el-form-item label="SIM卡状态：" prop="ndSmSta">
            <el-select v-model="form.ndSmSta" style="width: 20vw" placeholder="请选择SIM卡状态">
              <el-option label="正常" value="0"></el-option>
              <el-option label="异常" value="1"></el-option>
            </el-select>
          </el-form-item>
          <div class="tip">（必填）</div>
          <div
            class="add-btn"
            @click="add('form')"
            v-if="$route.query.id == undefined"
          >
            添加
          </div>
          <div
            class="add-btn"
            @click="modify('form')"
            v-if="$route.query.id != undefined"
          >
            修改
          </div>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import {
  addNode,
  getCompanyList,
  getStationList,
  getNodeDetail,
  modifyNode,
} from "@/api/node";
import VDistpicker from "v-distpicker";
export default {
  name: "addNode",
  components: {
    VDistpicker,
  },
  data() {
    return {
      form: {
        etId: "", // 企业名称
        openTime: "", // 开通时间
        psId: "", // 电站名称
        termTime: "", // 有效期
        itProvince: "", // 省
        ndCity: "", // 市
        ndArea: "", // 区
        ndAddress: "", // 地址
        lastRefreshTime: "", // 最后刷新时间
        ndName: "", // 节点名称
        ndTotalFlow: "", // 总流量
        ndUsedFlow: "", // 已使用流量
        ndSmcard: "", // SIM卡号
        ndSmSta: "", // SIM卡状态
        ndMac: "",
        dup:""   // 数据上传方式
      },
      disabled: true, // 电站名称
      companyList: [],
      stationList: [],
      rules: {
        etId: [
          { required: true, message: "企业名称不能为空", trigger: "blur" },
        ],
        psId: [
          { required: true, message: "电站名称不能为空", trigger: "blur" },
        ],
        ndName: [
          { required: true, message: "节点名称不能为空", trigger: "blur" },
        ],
        ndMac: [
          { required: true, message: "节点MAC码不能为空", trigger: "blur" },
        ],
        ndSmcard: [
          { required: true, message: "SIM卡号不能为空", trigger: "blur" },
        ],
        ndSmSta: [
          { required: true, message: "SIM卡状态不能为空", trigger: "blur" },
        ],
        dup: [
          { required: true, message: "数据上传方式不能为空", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    provinceChange(data) {
      //省
      console.log(data);
      this.form.itProvince = data.value;
      this.form.ndCity = '';
      this.form.ndArea = '';
    },
    cityChange(data) {
      //市
      this.form.ndCity = data.value;
      this.form.ndArea = '';
    },
    areaChange(data) {
      //区、县
      this.form.ndArea = data.value;
    },
    // 获取企业信息列表
    getCompany() {
      getCompanyList().then((res) => {
        console.log(res, "上的卡号");
        this.companyList = res.data;
      });
    },
    // 切换企业名称
    changeCompany(e) {
      this.disabled = false;
      console.log(e);
      let id = e;
      getStationList(id).then((res) => {
        console.log(res, "圣安东尼奥就");
        this.stationList = res.data;
      });
    },
    // 新增
    add(form) {
      this.$refs[form].validate((valid) => {
        if (valid) {
          this.form.remark = "";
          addNode(this.form).then((res) => {
            if (res.code == 200) {
              this.$message({
                showClose: false,
                message: "添加成功",
                type: "success",
              });
              this.$router.push("/nodeEntry");
            }
          });
        } else {
          return false;
        }
      });
    },
    // 获取详情
    getDetail() {
      getNodeDetail(this.$route.query.id).then((res) => {
        console.log(res);
        this.form = res.data;
        this.form.etId = (this.form.etId) *1
        if (this.form.etId != "") {
          getStationList(this.form.etId).then((res) => {
            console.log(res, "圣安东尼奥就");
            this.stationList = res.data;
          });
        }
      });
    },
    // 修改
    modify(form) {
      this.$refs[form].validate((valid) => {
        if (valid) {
          this.form.remark = "";
          this.form.id = this.$route.query.id * 1;
          modifyNode(this.form).then((res) => {
            if (res.code == 200) {
              this.$message({
                showClose: false,
                message: "修改成功",
                type: "success",
              });
              this.$router.push("/nodeEntry");
            } else {
              console.log(res, "我的卡基本的接口");
            }
          });
        } else {
          return false;
        }
      });
    },
  },
  mounted() {
    this.getCompany();
    if (this.$route.query.id != undefined) {
      this.disabled = false;
      this.getDetail();
    }
  },
};
</script>

<style lang="scss" scoped>
.container::v-deep {
  display: flex;
  width: 100%;
  padding-top: 2vh;
  box-sizing: border-box;
  .left-part {
    margin-right: 3vw;
    .equipment-title {
      background: url(@/assets/images/titleBg.png);
      background-size: 100% 100%;
      width: 11.1vw;
      height: 5.5vh;
      color: #fff;
      font-size: calc(100vw * 20 / 1920);
      text-align: center;
      line-height: 5.5vh;
      margin-bottom: 1.8vh;
    }
  }
  .form-item {
    display: flex;
    height: 4.6vh;
    margin-bottom: 3.2vh;
    .add-btn {
      background: url(@/assets/images/updown.png);
      background-size: 100% 100%;
      width: 7.2vw;
      height: 4.6vh;
      color: #fff;
      font-size: calc(100vw * 18 / 1920);
      text-align: center;
      line-height: 4.6vh;
      margin-left: 20.3vw;
      cursor: pointer;
    }
    .tip {
      line-height: 4.6vh;
      margin-left: 0.5vw;
      color: #fff;
      font-size: calc(100vw * 15 / 1920);
      margin-right: 4.6vw;
    }
    &:nth-child(2) {
      .el-input.is-disabled .el-input__inner {
        background: #0f2455;
        border: 1px solid #286ae8;
        &::placeholder {
          text-align: center;
        }
      }
    }
  }
}
.el-form-item::v-deep {
  display: flex;
  height: 4.6vh;
  align-items: center;
  .el-form-item__label {
    width: 7.5vw;
    text-align: left;
    color: #fff;
    font-size: calc(100vw * 16 / 1920);
  }
}
::v-deep .el-input__inner {
  height: 4.6vh;
  background: #0f2455;
  border: 1px solid #286ae8;
  color: #fff;
  font-size: calc(100vw * 16 / 1920);
  &::placeholder {
    color: #fff;
  }
}
::v-deep .el-input__prefix {
  left: 18vw;
  font-size: calc(100vw * 20 / 1920);
}
::v-deep .el-input--prefix .el-input__inner {
  padding-left: 0.8vw;
}
::v-deep .el-select .el-input.is-disabled .el-input__inner {
  background: #0f2455;
  border: 1px solid #286ae8;
  &::placeholder {
    color: #fff;
  }
}
::v-deep .distpicker-address-wrapper select {
  width: 10.3vw;
  background: #0f2455;
  border: 1px solid #286ae8;
  color: #fff;
  margin-right: 1vw;
  height: 4.6vh;
  font-size: calc(100vw * 16 / 1920) !important;
}
</style>
