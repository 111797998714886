import requests from './request'

// 电站经纬度地图
export function getMap(){
    return requests.get('/custom/home/data/getLenLat')
}

// 电站总览
export function getOverviewOfPower(){
    return requests.get('/custom/home/data/power')
}

// 电站概述
export function getSummaryOfPower(){
    return requests.get('/custom/home/data/outline')
}

// 设备统计
export function getEquipmentTotal(){
    return requests.get('/custom/home/data/esta')
}

// 工单统计
export function getWorkNum(){
    return requests.get('/custom/home/data/orderAll')
}

// 环境概况
export function getEnvironment(){
    return requests.get('/custom/home/data/environmental')
}

// 报警统计
export function getAlarmTotal(){
    return requests.get('/custom/home/data/alertSta')
}

// 节能减排
export function getReduce(){
    return requests.get('/custom/home/data/lowLowEnergyLight')
}

// 电站管理
export function getStationList(data){
    return requests.get('/custom/enterprise/listSta?pageNum=' + data.pageNum + '&pageSize=' + data.pageSize + '&etProvince=' + data.etProvince + '&etCity=' + data.etCity + '&etArea=' + data.etArea + '&etName=' + data.etName,data)
}

// 电站概况
export function getStationOverview(data){
    return requests.get('/custom/station/getGfPowerStationList?pageNum=' + data.pageNum + '&pageSize=' + data.pageSize + '&psProvince=' + data.psProvince + '&psCity=' + data.psCity + '&psArea=' + data.psArea + '&psBetime=' + data.psBetime + '&title=' + data.title + '&psSta=' + data.psSta,data)
}

// 电站概况导出
export function getStationOverviewExport(data){
    return requests.get('/custom/station/GfPowerStationExport?title=' + data.title + '&psBetime=' + data.psBetime + '&psSta=' + data.psSta + '&psProvince=' + data.psProvince + '&psCity=' + data.psCity + '&psArea=' + data.psArea,data)
}

// 电量报表查询
export function getEleTable(data){
    return requests.get('/custom/cbq/edList?pageNum=' + data.pageNum + '&pageSize=' + data.pageSize + '&meTime=' + data.meTime + '&title=' + data.title + '&sn=' + data.sn,data)
}

// 用户帐单
export function getUserBill(data){
    return requests.get('/custom/cbq/edDetail?meTime=' + data.meTime + '&sn=' + data.sn,data)
}

// 能源趋势
export function getEnergy(type){
    return requests.get('/custom/home/data/trendOfEnergy?type=' + type )
}

// 电站离线率图表
export function getStationOffLine(type,dateStr){
    return requests.get('/custom/ps/listChart?type=' + type + '&dateStr=' + dateStr)
}

// 电站离线率台账
export function getStationOffLineTable(data){
    return requests.get('/custom/ps/list?pageNum=' + data.pageNum + '&pageSize=' + data.pageSize + '&etName=' + data.etName + '&psName=' + data.psName + '&bTime=' + data.bTime + '&eTime=' + data.eTime,data)
}

// 电站离线率导出
export function exportStationOffLineTable(data){
    return requests.get('/custom/ps/export?etName=' + data.etName + '&psName=' + data.psName + '&bTime=' + data.bTime + '&eTime=' + data.eTime,data)
}

// 节点离线时长图表
export function getNodeOffLine(type,psId,dateStr){
    return requests.get('/custom/rate/listChart?type=' + type + '&psId=' + psId + '&dateStr=' + dateStr)
}

// 电站离线时长台账
export function getNodeOffLineTable(data){
    return requests.get('/custom/rate/lists?pageNum=' + data.pageNum + '&pageSize=' + data.pageSize + '&etName=' + data.etName + '&psName=' + data.psName + '&bTime=' + data.bTime + '&eTime=' + data.eTime + '&ndName=' + data.ndName + '&psId=' + data.psId,data)
}

// 电站离线时长导出
export function exportNodeOffLineTable(data){
    return requests.get('/custom/rate/exportLists?etName=' + data.etName + '&psName=' + data.psName + '&bTime=' + data.bTime + '&eTime=' + data.eTime + '&ndName=' + data.ndName + '&psId=' + data.psId,data)
}