<template>
  <div>
    <div class="header">
      <div style="position: absolute">
        <img src="../assets/images/sign.png" />
        <span>光伏盾</span>
      </div>
      <div class="header-title">上海列卫智能科技有限公司</div>
    </div>
    <div class="content">
      <div class="content-container">
        <div class="title">修改密码</div>
        <el-form :model="form" ref="form" :rules="rules">
          <el-form-item label="账号：" prop="username">
            <el-input
              type="text"
              v-model="form.username"
              placeholder="请输入账号"
            ></el-input>
          </el-form-item>
          <el-form-item label="验证码：" prop="code">
            <div style="display: flex">
              <el-input
                style="width: 13vw"
                type="text"
                v-model="form.code"
                placeholder="请输入验证码"
              ></el-input>
              <button :disabled="isSend" class="getCode" @click="getCode">
                {{ btnContent }}
              </button>
            </div>
          </el-form-item>
          <el-form-item label="新密码：" prop="newPsd">
            <div
              style="display: flex; width: 100%; position: relative"
              v-if="openNewPsd"
            >
              <el-input
                type="text"
                v-model="form.newPsd"
                placeholder="请输入新密码"
                style="width: 100%; positiion: absolute; left: 0"
              ></el-input>
              <img
                src="../assets/images/openPsd.png"
                style="
                  width: 1.5vw;
                  height: 2.5vh;
                  position: absolute;
                  right: 0.7vw;
                  top: 1vh;
                  cursor: pointer;
                "
                @click="openNewPsd = false"
              />
            </div>
            <div
              style="display: flex; width: 100%; position: relative"
              v-if="!openNewPsd"
            >
              <el-input
                type="password"
                v-model="form.newPsd"
                placeholder="请输入新密码"
                style="
                  width: 100%;
                  positiion: absolute;
                  left: 0;
                  background: transparent;
                "
              ></el-input>
              <img
                src="../assets/images/closePsd.png"
                style="
                  width: 1.5vw;
                  height: 2.5vh;
                  position: absolute;
                  right: 0.7vw;
                  top: 1vh;
                  cursor: pointer;
                "
                @click="openNewPsd = true"
              />
            </div>
          </el-form-item>
          <el-form-item label="确认密码：" prop="newPsdAgain">
            <div
              style="display: flex; width: 100%; position: relative"
              v-if="opennewPsdAgain"
            >
              <el-input
                type="text"
                v-model="form.newPsdAgain"
                placeholder="请输入新密码"
                style="width: 100%; positiion: absolute; left: 0"
              ></el-input>
              <img
                src="..//assets/images/openPsd.png"
                style="
                  width: 1.5vw;
                  height: 2.5vh;
                  position: absolute;
                  right: 0.7vw;
                  top: 1vh;
                  cursor: pointer;
                "
                @click="opennewPsdAgain = false"
              />
            </div>
            <div
              style="display: flex; width: 100%; position: relative"
              v-if="!opennewPsdAgain"
            >
              <el-input
                type="password"
                v-model="form.newPsdAgain"
                placeholder="请输入新密码"
                style="
                  width: 100%;
                  positiion: absolute;
                  left: 0;
                  background: transparent;
                "
              ></el-input>
              <img
                src="../assets/images/closePsd.png"
                style="
                  width: 1.5vw;
                  height: 2.5vh;
                  position: absolute;
                  right: 0.7vw;
                  top: 1vh;
                  cursor: pointer;
                "
                @click="opennewPsdAgain = true"
              />
            </div>
          </el-form-item>
        </el-form>
        <div
          class="login-btn"
          @click="modify('form')"
          @keyup.enter="keyDown(e)"
        >
          修 改
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getCodeM, modifyPassword } from "@/api/index";
export default {
  name: "forgetPsd",
  data() {
    let checkPhone = (rule, value, callback) => {
      let reg = /^1[345789]\d{9}$/;
      if (!reg.test(value)) {
        callback(new Error("请输入11位手机号"));
      } else {
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.info.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      form: {
        username: "",
        newPsd: "",
        newPsdAgain: "",
        code: "",
      },
      rules: {
        username: [
          {
            required: true,
            type: "number",
            validator: checkPhone,
            message: "请输入正确的手机号",
            trigger: "blur",
          },
        ],
        code: [{ required: true, message: "验证码不能为空", trigger: "blur" }],
        newPsd: [
          { required: true, message: "新密码不能为空", trigger: "blur" },
        ],
        newPsdAgain: [
          { required: true, message: "确认密码不能为空", trigger: "blur" },
        ],
      },
      btnContent: "获取验证码",
      isSend: false, //禁用
      totalTime: 60,
      timer: "", //定时器
      openNewPsd: false,
      opennewPsdAgain: false,
    };
  },
  methods: {
    // 按回车登录
    keyDown(e) {
      // 回车则执行登录方法 enter键的ASCII是13
      if (e.keyCode == 13 || e.keyCode == 100) {
        this.login(); // 定义的登录方法
      }
    },
    getCode() {
      if (this.isSend) return;
      let useName = this.form.username;
      getCodeM(useName).then((res) => {
        console.log(res, "是基地啊家死哦");
      });
      this.isSend = true;
      this.btnContent = this.totalTime + "s后重新发送";
      this.timer = setInterval(() => {
        this.totalTime--;
        this.btnContent = this.totalTime + "s后重新发送";
        if (this.totalTime < 0) {
          clearInterval(this.timer);
          this.btnContent = "重新发送验证码";
          this.totalTime = 60;
          this.isSend = false;
        }
      }, 1000);
    },
    // 修改密码
    modify(form) {
      if (this.form.newPsd == this.form.newPsdAgain) {
        this.$refs[form].validate((valid) => {
          if (valid) {
            let data = {
              userName: this.form.username,
              password: this.form.newPsd,
              email: this.form.code,
            };
            modifyPassword(data).then((res) => {
              if (res.code == 200) {
                this.$message({
                  showClose: false,
                  message: "修改成功",
                  type: "success",
                });
                this.$router.push("/login");
              }
            });
          } else {
            return false;
          }
        });
      } else {
        this.$message({
          showClose: false,
          message: "两次密码输入不正确",
          type: "warning",
        });
      }
    },
  },
  created() {},
  mounted() {
    // 绑定监听事件
    window.addEventListener("keydown", this.keyDown);
  },
  destroyed() {
    // 销毁事件
    window.removeEventListener("keydown", this.keyDown, false);
  },
};
</script>

<style lang="scss" scoped>
.header {
  width: 100%;
  height: 7.4vh;
  background: #0c3688;
  position: relative;
  line-height: 7.4vh;
  img {
    position: absolute;
    left: 3vw;
    top: 1.8vh;
    width: 2.8vw;
    height: 4vh;
  }
  span {
    margin-left: 6.5vw;
    color: #fff;
    font-size: calc(100vw * 20 / 1920);
  }
  .header-title {
    color: #fff;
    font-size: calc(100vw * 20 / 1920);
    text-align: center;
  }
}
.content::v-deep {
  width: 100%;
  height: calc(100vh - 7.4vh);
  background: url(@/assets/images/login.png);
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .content-container {
    background: url(@/assets/images/loginBg.png);
    background-size: 100% 100%;
    width: 29vw;
    height: 45vh;
    color: #fff;
    padding: 3.5vh 0.5vw 3.5vh 2vw;
    box-sizing: border-box;
    .getCode {
      width: 7vw;
      height: 4.6vh;
      color: #fff;
      cursor: pointer;
      background: #286ae8;
      text-align: center;
      border-radius: 8px;
      font-size: calc(100vw * 14 / 1920);
    }
    .el-form-item {
      display: flex;
      height: 4.6vh;
      line-height: 4.6vh;
      .el-form-item__label {
        width: 5.9vw;
        text-align: left;
        color: #fff;
        font-size: calc(100vw * 17 / 1920);
      }
    }
    .title {
      text-align: center;
      font-size: calc(100vw * 22 / 1920);
      margin-bottom: 3vh;
    }
    .img {
      width: 6vw;
      height: 4.6vh;
    }
    .el-input__inner {
      width: 12.5vw;
      height: 4.6vh;
      font-size: calc(100vw * 16 / 1920);
      box-shadow: inset 0rem 0rem 0.43rem 0.17rem rgba(44, 98, 230, 0.28);
      border-radius: 6px;
      border: 0.02rem solid #286ae8;
      background: transparent;
      color: #fff;
      margin-bottom: 2vh;
      &::placeholder {
        color: #fff;
      }
    }
    .login-btn {
      width: 15vw;
      margin: 0 auto;
      // background: linear-gradient(90deg, #FFFF00 6%, #FFA500 25%, #F14444 45%, #D53567 55%, #9A109A 94%);
      background: linear-gradient(to right, #1290f1, #115ab0);
      height: 3.8vh;
      border-radius: 2vw;
      text-align: center;
      line-height: 3.5vh;
      margin-top: 2vh;
      cursor: pointer;
    }
  }
}
::v-deep .el-checkbox__input.is-checked + .el-checkbox__label {
  color: #fff;
}
::v-deep input:-webkit-autofill,
::v-deep textarea:-webkit-autofill,
::v-deep select:-webkit-autofill {
  -webkit-text-fill-color: #ededed !important;

  -webkit-box-shadow: 0 0 0px 1000px transparent inset !important;

  background-color: transparent;

  background-image: none;

  transition: background-color 50000s ease-in-out 0s; //背景色透明  生效时长  过渡效果  启用时延迟的时间
}

input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  // 字体颜色
  -webkit-text-fill-color: #fff !important;
  // 背景颜色
  background-color: transparent !important;
  // 背景图片
  background-image: none !important;
  //设置input输入框的背景颜色为透明色
  -webkit-box-shadow: 0 0 0px 1000px transparent inset !important;
  transition: background-color 50000s ease-in-out 0s;
}
</style>
