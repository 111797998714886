<template>
  <div class="container">
    <div class="station-container">
      <div class="left-title">电站概况</div>
      <div class="right-container">
        <div class="right-top">
          <el-form ref="form" :model="form">
            <div
              style="width: 62vw; display: flex; justify-content: space-between"
            >
              <el-form-item label="区域(省市县)：">
                <v-distpicker
                  @province="provinceChange"
                  @city="cityChange"
                  @area="areaChange"
                  :province="form.province"
                  :city="form.city"
                  :area="form.area"
                ></v-distpicker>
              </el-form-item>
              <el-form-item label="合同起始日期：">
                <el-date-picker
                  style="width: 10.3vw"
                  v-model="form.startTime"
                  type="date"
                  placeholder="选择日期"
                  :picker-options="pickerOptions1"
                >
                </el-date-picker>
              </el-form-item>
            </div>
            <div style="display: flex; height: 4.6vh">
              <el-form-item label="全局搜索：">
                <el-input
                  style="width: 15.6vw; margin-right: 1vw"
                  v-model="form.searchContent"
                  placeholder="请输入企业名称或电站名称"
                ></el-input>
              </el-form-item>
              <el-form-item label="运行状态：">
                <el-select
                  style="width: 15.6vw; margin-right: 1.3vw"
                  v-model="form.status"
                  placeholder="请选择状态"
                >
                  <el-option label="在线" value="0"></el-option>
                  <el-option label="离线" value="1"></el-option>
                </el-select>
              </el-form-item>
              <div style="display: flex">
                <div class="query" @click="query">查询</div>
                <div class="query" @click="reset">重置</div>
                <div class="reset" @click="exportData">导出</div>
              </div>
            </div>
          </el-form>
        </div>
        <div class="table">
          <el-table
            :data="tableData"
            :header-cell-style="headerStyle"
            :cell-style="rowStyle"
          >
            <el-table-column type="index" label="序号" width="55">
            </el-table-column>
            <el-table-column prop="psName" label="电站名称"> </el-table-column>
            <el-table-column prop="psProvince" label="电站区域">
            </el-table-column>
            <el-table-column prop="psCapacity" label="装机容量">
            </el-table-column>
            <el-table-column label="电站类型">
              <template slot-scope="scope">
                <span v-if="scope.row.psType == 0"> 光伏站 </span>
                <span v-if="scope.row.psType == 1"> 储能站 </span>
              </template>
            </el-table-column>
            <el-table-column label="节点状态">
              <template slot-scope="scope">
                <span v-if="scope.row.ndSmSta == 0"> 正常 </span>
                <span v-if="scope.row.ndSmSta == 1"> 异常 </span>
              </template>
            </el-table-column>
            <el-table-column label="运行状态">
              <template slot-scope="scope">
                <span v-if="scope.row.psSta == 0"> 在线 </span>
                <span v-if="scope.row.psSta == 1"> 离线 </span>
              </template>
            </el-table-column>
            <el-table-column prop="psBetime" label="合同起始日期">
            </el-table-column>
            <el-table-column fixed="right" label="服务日志">
              <template slot-scope="scope">
                <el-button
                  @click="handleClick(scope.row.id)"
                  type="text"
                  size="small"
                  >查看</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <el-dialog
          style="top:5%"
            title="详细信息"
            :visible.sync="dialogVisible"
            width="45%"
          >
            <div class="dialog_form">
              <div class="dialog_form_item">
                <div class="dialog_label">联系人</div>
                <div class="detail_content">{{ detail.etEc }}</div>
              </div>
              <div class="dialog_form_item">
                <div class="dialog_label">联系电话</div>
                <div class="detail_content">{{ detail.etPhone }}</div>
              </div>
              <div class="dialog_form_item">
                <div class="dialog_label">所属企业</div>
                <div class="detail_content">{{ detail.etName }}</div>
              </div>
              <div class="dialog_form_item">
                <div class="dialog_label">创建日期</div>
                <div class="detail_content">{{ detail.createTime }}</div>
              </div>
              <div class="dialog_form_item">
                <div class="dialog_label">合同起始日期</div>
                <div class="detail_content">{{ detail.psBetime }}</div>
              </div>
              <div class="dialog_form_item">
                <div class="dialog_label">合同到期日期</div>
                <div class="detail_content">{{ detail.psEntime }}</div>
              </div>
              <div class="dialog_form_address">
                <div class="dialog_label">电话地址</div>
                <div class="detail_content">{{ detail.psAddress }}</div>
              </div>
              <div class="dialog_form_note">
                <div class="dialog_label">备注</div>
                <div class="detail_content">{{ detail.remark }}</div>
              </div>
            </div>
          </el-dialog>
        </div>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="currentPage"
          :page-sizes="[8, 16, 24, 32]"
          :page-size="pageSize"
          layout="sizes, prev, pager, next , total"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { getStationOverview, getStationOverviewExport } from "@/api/home";
import VDistpicker from "v-distpicker";
export default {
  name: "stationOverview",
  components: { VDistpicker },
  data() {
    return {
      pickerOptions1: {
        disabledDate: (time) => {
          return time.getTime() > Date.now();
        },
      },
      form: {
        province: "", // 省
        city: "", // 市
        area: "", // 区
        startTime: "", // 合同起始日期
        searchContent: "", // 全局搜索
        status: "", // 节点状态
      },
      currentPage: 1,
      pageSize: 8,
      total: 0,
      tableData: [],
      dialogVisible: false,
      detail: {},
    };
  },
  methods: {
    handleSizeChange(val) {
      this.pageSize = val;
      this.getList();
    },
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage;
      this.getList();
    },
    provinceChange(data) {
      //省
      console.log(data);
      this.form.province = data.value;
      this.form.city = '';
      this.form.area = '';
    },
    cityChange(data) {
      //市
      this.form.city = data.value;
      this.form.area = '';
    },
    areaChange(data) {
      //区、县
      this.form.area = data.value;
    },
    headerStyle() {
      return "background:#0F2455;color:#fff;border: 1px solid #286AE8;text-align:center;font-size:calc(100vw * 15 / 1920);";
    },
    rowStyle() {
      return "background:#0F2455;color:#fff;border: 1px solid #286AE8;text-align:center;font-size:calc(100vw * 14 / 1920);";
    },
    formateDate(datetime) {
      function addDateZero(num) {
        return num < 10 ? "0" + num : num;
      }
      let d = new Date(datetime);
      let formatdatetime =
        d.getFullYear() +
        "-" +
        addDateZero(d.getMonth() + 1) +
        "-" +
        addDateZero(d.getDate());
      return formatdatetime;
    },
    getList() {
      this.form.startTime = this.formateDate(this.form.startTime);
      let data = {
        pageNum: this.currentPage,
        pageSize: this.pageSize,
        psProvince: this.form.province,
        psCity: this.form.city,
        psArea: this.form.area,
        psBetime: this.form.startTime,
        title: this.form.searchContent,
        psSta: this.form.status,
      };
      getStationOverview(data).then((res) => {
        console.log(res, "是大户萨湖");
        this.tableData = res.rows;
        this.total = res.total;
      });
    },
    // 查询
    query() {
      this.currentPage = 1
      this.getList();
    },
    // 重置
    reset() {
      this.form.province = "";
      this.form.city = "";
      this.form.area = "";
      this.form.startTime = "";
      this.form.searchContent = "";
      this.form.status = "";
      this.getList();
    },
    // 导出
    exportData() {
      let data = {
        title: this.form.searchContent,
        psBetime: this.form.startTime,
        psSta: this.form.status,
        psProvince: this.form.province,
        psCity: this.form.city,
        psArea: this.form.area,
      };
      getStationOverviewExport(data).then((res) => {
        console.log(res, "的撒很激动i");
        let name = res.msg;
        if (res.code == 200) {
          this.$axios({
            url:
              "https://admin.pvshield.cn/guang/common/download?fileName=" +
              name +
              "&delete=true",
            methods: "GET",
            responseType: "blob",
            headers: {
              "Content-Type": "application/json",
            },
          }).then((res) => {
            console.log(res, "sdhuahui");
            const blob = new Blob([res.data], {
              type: "application/vnd.ms-excel",
            });
            console.log(blob, "----------0990");
            const fileName = name + ".xls";
            const linkNode = document.createElement("a");

            linkNode.download = fileName; //a标签的download属性规定下载文件的名称
            linkNode.style.display = "none";
            linkNode.href = URL.createObjectURL(blob); //生成一个Blob URL
            document.body.appendChild(linkNode);
            linkNode.click(); //模拟在按钮上的一次鼠标单击

            URL.revokeObjectURL(linkNode.href); // 释放URL 对象
            document.body.removeChild(linkNode);
          });
        }
      });
    },
    // 查看
    handleClick(id) {
      this.dialogVisible = true;
      for (let i in this.tableData) {
        if (id == this.tableData[i].id) {
          this.detail = this.tableData[i];
        }
      }
    },
  },
  mounted() {
    this.getList();
  },
};
</script>

<style lang="scss" scoped>
.container::v-deep {
  margin-top: 2vh;
  width: 100%;
  position: relative;
  .station-container {
    display: flex;
    .left-title {
      background: url(@/assets/images/titleBg.png);
      background-size: 100% 100%;
      width: 11.1vw;
      height: 5.5vh;
      color: #fff;
      font-size: calc(100vw * 20 / 1920);
      text-align: center;
      line-height: 5.5vh;
      margin-right: 7.5vw;
    }
    .right-container {
      width: 62.5vw;
      position: absolute;
      left: 0;
      right: 0;
      margin: 0 auto;
      // height: 28.7vh;
      // background: #fff;
      .right-top {
        width: 62.5vw;
        margin-bottom: 3vh;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .el-form {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
        }
        .el-form-item,
        .el-form {
          display: flex;
          align-items: center;
          margin-bottom: 0;

          .el-form-item {
            margin-bottom: 2vh;
            .el-date-editor.el-input,
            .el-date-editor.el-input__inner {
              width: 15vw;
            }
            .el-form-item__label {
              color: #fff;
              padding-right: 0;
              text-align: left;
              font-size: calc(100vw * 16 / 1920);
            }
            .el-input__inner {
              width: 100%;
              height: 4.6vh;
              font-size: calc(100vw * 16 / 1920);
              box-shadow: inset 0px 0px 26px 10px rgba(44, 98, 230, 0.28);
              border-radius: 6px;
              border: 1px solid #286ae8;
              background: none;
              color: #fff;
              &::placeholder {
                color: #fff;
              }
            }
          }
          .el-select-dropdown__item .selected,
          .el-select-dropdown__item {
            font-size: calc(100vw * 16 / 1920);
          }
        }
        .query,
        .reset {
          width: 7.5vw;
          height: 100%;
          background: url(@/assets/images/updown.png);
          background-size: 100% 100%;
          color: #02b5ff;
          font-size: calc(100vw * 16 / 1920);
          line-height: 4.6vh;
          text-align: center;
          cursor: pointer;
        }
        .query {
          margin-right: 1.5vw;
        }
      }
      .el-pagination {
        display: flex;
        justify-content: flex-end;
        .el-select,
        .btn-prev,
        .btn-next,
        .el-pagination__total {
          box-shadow: inset 0px 0px 26px 10px rgba(44, 98, 230, 0.28);
          border-radius: 6px;
          border: 1px solid #286ae8;
          background: none;
          color: #fff;
        }
        .el-pager li {
          box-shadow: inset 0px 0px 26px 10px rgba(44, 98, 230, 0.28);
          border-radius: 6px;
          border: 1px solid #286ae8;
          background: none;
          .active {
            color: pink !important;
          }
        }
        .el-pager li.active {
          color: #0855b3;
        }
        .number {
          color: #fff;
          margin-right: 0.8vw;
          .active {
            color: pink;
          }
        }
        .el-input {
          width: 7vw;
        }
        .more,
        .btn-prev {
          color: #fff;
          margin-right: 0.8vw;
        }
        .el-pagination__total {
          margin-left: 0.8vw;
        }
        .el-input .el-input--mini .el-input--suffix {
          background: none;
        }
        .el-input__inner {
          background: none;
          border: none;
          color: #fff;
        }
      }
    }
  }
}
::v-deep .el-table {
  width: 99.9%;
  margin-bottom: 2.5vh;
  text-align: center;
  font-size: calc(100vw * 18 / 1920);
}
.el-form-item::v-deep {
  display: flex;
  height: 4.6vh;
  align-items: center;
  .el-form-item__label {
    width: 6.7vw;
    text-align: left;
    color: #fff;
    font-size: calc(100vw * 16 / 1920);
  }
  .el-input__inner {
    padding-right: 2vw;
  }
}
::v-deep .el-select .el-input .el-select__caret {
  margin-right: 0.5vw;
}
::v-deep .el-input__prefix {
  left: 8.5vw;
  font-size: calc(100vw * 20 / 1920);
}
::v-deep .el-input--prefix .el-input__inner {
  padding-left: 0.8vw;
}
::v-deep .el-pagination__total {
  margin-right: 0;
  padding: 0 0.8vw;
  box-sizing: border-box;
}
::v-deep .el-pagination {
  margin-bottom: 1vh;
}
.el-table--border::after,
.el-table--group::after,
.el-table::before {
  display: none !important;
}
::v-deep .el-table__fixed-right::before {
  height: 0;
}
::v-deep .el-button {
  text-decoration: underline;
}
::v-deep .distpicker-address-wrapper select {
  width: 10.3vw;
  height: 4.6vh;
  background: #0f2455;
  border: 1px solid #286ae8;
  color: #fff;
  margin-right: 1vw;
  font-size: calc(100vw * 16 / 1920);
}
.dialog_form {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  .dialog_form_item {
    width: 33%;
    height: 6vh;
    line-height: 6vh;
    font-size: calc(100vw * 15 / 1920);
    display: flex;
    .dialog_label {
      width: 5.5vw;
    }
    .detail_content {
      font-size: calc(100vw * 13 / 1920);
    }
  }
  .dialog_form_address,
  .dialog_form_note {
    width: 100%;
    font-size: calc(100vw * 15 / 1920);
    display: flex;
    .dialog_label {
      width: 5.5vw;
    }
    .detail_content {
      font-size: calc(100vw * 13 / 1920);
    }
  }
  .dialog_form_address {
    height: 6vh;
    line-height: 6vh;
  }
  .dialog_form_note {
    margin-top: 1.5vh;
    .detail_content {
      width: 50%;
      min-height: 15vh;
      max-height: 25vh;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      border: 1px solid #bbb;
      padding: 0.5vh 0.5vw;
    }
  }
}
::v-deep .el-dialog__title {
  font-weight: 550;
}
</style>
