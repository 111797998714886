<template>
  <div>
    <Header v-if="$route.path != '/login' && $route.path != '/' && $route.path != '/forgetPsd'" />
    <router-view v-if="$route.path == '/login' || $route.path == '/' || $route.path == '/forgetPsd'"></router-view>
  </div>
</template>

<script>
import Header from '@/components/header.vue'

export default {
  components:{Header}
}
</script>


<style>
* {
  margin: 0;
  padding: 0;
}
body,html {
  width: 100%;
  height: 100%;
  background: #100C2A;
}
</style>
