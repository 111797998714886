<template>
  <div class="container">
    <div class="station-container">
      <div class="left-title">发电量统计详情</div>
      <div class="back" @click="backPrev">返回</div>
      <div class="right-container">
        <div class="right-top">
          <el-form ref="form" :model="form">
            <el-form-item label="电站名称：">
              <el-input
                v-model="form.searchContent"
                placeholder="请输入电站名称"
                style="width: 15.6vw"
              ></el-input>
            </el-form-item>
            <div
              style="
                width: 62.5vw;
                display: flex;
                justify-content: space-between;
              "
            >
              <el-form-item label="报文时间(月)：">
                <el-date-picker
                  style="width: 15vw"
                  v-model="form.startTime"
                  type="date"
                  :picker-options="pickerOptions1"
                >
                </el-date-picker>
              </el-form-item>
              <div style="display: flex; height: 4.6vh">
                <div class="query" @click="query">查询</div>
                <div class="query" @click="reset">重置</div>
                <!-- <div class="reset" @click="exportData">导出</div> -->
              </div>
            </div>
          </el-form>
        </div>
        <el-table
          :data="detail"
          :header-cell-style="headerStyle"
          :cell-style="rowStyle"
        >
          <el-table-column
            type="index"
            label="序号"
            width="50"
          ></el-table-column>
          <el-table-column prop="psName" label="电站名称"></el-table-column>
          <el-table-column prop="num" label="总发电量"></el-table-column>
          <el-table-column prop="priceNum" label="总收益"></el-table-column>
          <el-table-column prop="date" label="报文时间"></el-table-column>
        </el-table>
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[8, 16, 24, 32]"
          :page-size="100"
          layout="sizes, prev, pager, next , total"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { getEleList } from "@/api/green";
export default {
  name: "powerDetail",
  data() {
    return {
      pickerOptions1: {
        disabledDate: (time) => {
          return time.getTime() > Date.now();
        },
      },
      form: {
        searchContent: "", // 全局搜索
        startTime: "", // 报文时间
      },
      detail: [],
      currentPage: 1,
      pageSize: 8,
      total: 0,
      type: "",
    };
  },
  methods: {
    headerStyle() {
      return "background:#0F2455;color:#fff;border: 1px solid #286AE8;text-align:center";
    },
    rowStyle() {
      return "background:#0F2455;color:#fff;border: 1px solid #286AE8;text-align:center";
    },
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage;
      getList();
    },
    // 返回
    backPrev() {
      let type = this.type;
      if (this.fromPage == "/operationOverview") {
        this.$router.push("/operationOverview");
      } else if(this.fromPage == '/stringOfflineRate' || this.fromPage == '/power'){
        this.$router.push('/equipmentOverview')
      }else {
        this.$router.push({
          path: "/rankingTotalEnergy",
          query: { type },
        });
      }
    },
    formateDate(datetime) {
      function addDateZero(num) {
        return num < 10 ? "0" + num : num;
      }
      let d = new Date(datetime);
      let formatdatetime =
        d.getFullYear() +
        "-" +
        addDateZero(d.getMonth() + 1) +
        "-" +
        addDateZero(d.getDate());
      return formatdatetime;
    },
    getList() {
      if (
        this.form.searchContent == "" &&
        this.$route.query.title != undefined
      ) {
        this.form.searchContent = this.$route.query.title;
      } else {
        this.form.searchContent = "";
      }
      let data = {
        pageNum: this.currentPage,
        pageSize: this.pageSize,
        bTime: this.form.startTime,
        title: this.form.searchContent,
      };
      getEleList(data).then((res) => {
        this.detail = res.data;
        this.total = res.total;
        for (let i in this.detail) {
          this.detail[i].date = this.form.startTime;
        }
      });
    },
    query() {
      this.currentPage = 1
      this.getList();
    },
    reset() {
      this.form.searchContent = "";
      this.form.startTime = "";
      this.getList();
    },
  },
  mounted() {
    this.type = this.$route.query.type;
    let date = new Date();
    if (this.form.startTime == "") {
      this.form.startTime = this.formateDate(date);
    }
    this.getList();
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      console.log(from,'的教授及殴打')
      vm.fromPage = from.path;
    });
  },
};
</script>

<style lang="scss" scoped>
.container::v-deep {
  margin-top: 2vh;
  width: 100%;
  // height: 100%;
  position: relative;
  .station-container {
    display: flex;
    .left-title {
      position: absolute;
      left: 0;
      background: url(@/assets/images/titleBg.png);
      background-size: 100% 100%;
      width: 11.1vw;
      height: 5.5vh;
      color: #fff;
      font-size: calc(100vw * 20 / 1920);
      text-align: center;
      line-height: 5.5vh;
      margin-right: 7.5vw;
    }
    .back {
      position: absolute;
      top: 8vh;
      background: url(@/assets/images/titleBg.png);
      background-size: 100% 100%;
      width: 11.1vw;
      height: 5.5vh;
      color: #fff;
      font-size: calc(100vw * 20 / 1920);
      text-align: center;
      line-height: 5.5vh;
      cursor: pointer;
    }
    .right-container {
      width: 62.5vw;
      position: absolute;
      left: 0;
      right: 0;
      margin: 0 auto;
      .right-top {
        margin-bottom: 3vh;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .el-form-item {
          display: flex;
          align-items: center;
          height: 4.6vh;
          .el-select-dropdown__item .selected,
          .el-select-dropdown__item {
            font-size: calc(100vw * 16 / 1920);
          }
          .el-input__inner {
            width: 100%;
            height: 4.6vh;
            font-size: calc(100vw * 16 / 1920);
            box-shadow: inset 0rem 0rem 0.43rem 0.17rem rgba(44, 98, 230, 0.28);
            border-radius: 0.1rem;
            border: 0.02rem solid #286ae8;
            background: none;
            color: #fff;
            border-radius: 6px;
          }
          .el-form-item__label {
            color: #fff;
            font-size: calc(100vw * 16 / 1920);
          }
        }
        .query,
        .reset {
          width: 7.2vw;
          height: 100%;
          background: url(@/assets/images/updown.png);
          background-size: 100% 100%;
          color: #02b5ff;
          font-size: calc(100vw * 16 / 1920);
          line-height: 4.6vh;
          text-align: center;
          cursor: pointer;
        }
        .query {
          margin-right: 1.5vw;
        }
      }
    }
  }
}
::v-deep .el-table {
  width: 99.9%;
  margin-bottom: 2.5vh;
  font-size: calc(100vw * 16 / 1920);
}
::v-deep .el-table__body {
  font-size: calc(100vw * 14 / 1920);
}
::v-deep .el-form-item__label {
  width: 7.5vw;
  text-align: left;
  color: #fff;
  font-size: calc(100vw * 16 / 1920);
}
::v-deep .el-range-input {
  background: none;
}
::v-deep .el-date-editor .el-range-separator {
  color: #fff;
  font-size: calc(100vw * 17 / 1920);
  line-height: 4.3vh;
  height: 4.6vh;
}
::v-deep .el-date-editor .el-range__icon {
  font-size: calc(100vw * 17 / 1920);
  line-height: 4.6vh;
  height: 4.6vh;
}
::v-deep .el-form-item__content {
  height: 4.6vh;
}
::v-deep .el-date-editor .el-range-input {
  font-size: calc(100vw * 16 / 1920);
  color: #fff;
  &::placeholder {
    color: #fff;
  }
}
::v-deep .el-pagination {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1vh;
}
::v-deep .el-pagination .el-select .el-input .el-input__inner,
::v-deep .el-pagination .btn-next,
::v-deep .el-pagination .btn-prev,
::v-deep .el-pager li,
::v-deep .el-pager li.btn-quicknext,
::v-deep .el-pager li.btn-quickprev,
::v-deep .el-pagination__total {
  background: #0f2455;
  border-radius: 6px;
  border: 1px solid #286ae8;
  color: #fff;
  text-align: center;
}
::v-deep .el-pager li,
::v-deep .el-pagination .btn-next,
::v-deep .el-pagination .btn-prev {
  margin-right: 0.5vw;
}
::v-deep .el-pagination__total {
  margin-right: 0;
  padding: 0 0.8vw;
  box-sizing: border-box;
}
::v-deep .el-select .el-input.is-disabled .el-input__inner {
  background: #0f2455;
  border: 1px solid #286ae8;
  &::placeholder {
    color: #fff;
  }
}
.el-table--border::after,
.el-table--group::after,
.el-table::before {
  display: none;
}
::v-deep .distpicker-address-wrapper select {
  width: 10.3vw;
  background: #0f2455;
  border: 1px solid #286ae8;
  color: #fff;
  margin-right: 1vw;
}
::v-deep .el-pager li.active {
  color: #409eff;
}
</style>
