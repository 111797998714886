<template>
  <div class="container">
    <div class="left-part">
      <div class="equipment-title" v-if="$route.query.id == undefined">
        逆变器录入
      </div>
      <div class="equipment-title" v-if="$route.query.id != undefined">
        逆变器编辑
      </div>
    </div>
    <div style="width: calc(100% - 13.1vw); height: 100%">
      <el-form :model="form" ref="form" :rules="rules">
        <div class="form-item">
          <el-form-item label="企业名称：" prop="etId">
            <el-select
              placeholder="请选择企业名称"
              v-model="form.etId"
              style="width: 20vw"
              @change="changeCompany"
            >
              <el-option
                v-for="(item, index) in companyList"
                :key="index"
                :label="item.etName"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <div class="tip">（必填）</div>
          <el-form-item label="电站名称：" prop="psId">
            <el-select
              placeholder="请输入电站名称"
              v-model="form.psId"
              style="width: 20vw"
              :disabled="disabled"
              @change="changeStation"
            >
              <el-option
                v-for="(item, index) in stationList"
                :key="index"
                :label="item.psName"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <div class="tip">（必填）</div>
        </div>
        <div class="form-item">
          <el-form-item label="表名称：" prop="itMeName">
            <el-select
              placeholder="请选择逆变器电表名称"
              v-model="form.itMeName"
              style="width: 20vw"
              :disabled="disabledMe"
              clearable=""
              @change="changeName"
            >
              <el-option
                v-for="(item, index) in meList"
                :key="index"
                :label="item.mrMeName"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <!-- <div class="tip">（必填）</div> -->
        </div>
        <div class="form-item">
          <el-form-item label="区域(省市县)：">
            <v-distpicker
              @province="provinceChange"
              @city="cityChange"
              @area="areaChange"
              :province="form.itProvince"
              :city="form.itCity"
              :area="form.itArea"
            ></v-distpicker>
          </el-form-item>
          <!-- <div class="tip">（必填）</div> -->
        </div>
        <div class="form-item">
          <el-form-item label="详细地址：" prop="itAddress">
            <el-input
              v-model="form.itAddress"
              style="width: 20vw"
              placeholder="请输入详细地址"
            ></el-input>
          </el-form-item>
          <div class="tip">（必填）</div>
          <el-form-item label="表号：" prop="itMeCard">
            <el-input
              v-model="form.itMeCard"
              disabled
              placeholder="根据表名称自动录入"
              style="width: 20vw"
            ></el-input>
          </el-form-item>
          <div class="tip">（必填）</div>
        </div>
        <div class="form-item">
          <el-form-item label="逆变器名称：" prop="itName">
            <el-input
              v-model="form.itName"
              style="width: 20vw"
              placeholder="请输入逆变器名称"
            ></el-input>
          </el-form-item>
          <div class="tip">（必填）</div>
          <el-form-item label="组串数：" prop="itSicount">
            <el-input
              type="number"
              v-model="form.itSicount"
              style="width: 20vw"
              placeholder="请输入组串数"
            ></el-input>
          </el-form-item>
          <div class="tip">（必填）</div>
        </div>
        <div class="form-item">
          <el-form-item label="从机ID：" prop="itSlaveId">
            <el-input
              v-model="form.itSlaveId"
              style="width: 20vw"
              placeholder="请输入从机ID"
            ></el-input>
          </el-form-item>
          <div class="tip">（必填）</div>
          <el-form-item label="组件数：" prop="itAmcount">
            <el-input
              type="number"
              v-model="form.itAmcount"
              style="width: 20vw"
              placeholder="请输入组件数"
            ></el-input>
          </el-form-item>
          <div class="tip">（必填）</div>
        </div>
        <div class="form-item">
          <el-form-item label="容量：" prop="itCapacity">
            <el-input
              type="number"
              v-model="form.itCapacity"
              style="width: 20vw"
              placeholder="请输入容量"
            ></el-input>
          </el-form-item>
          <div class="tip">（必填）</div>
          <el-form-item label="组件瓦数/w：" prop="itAmWattage">
            <el-input
              type="number"
              v-model="form.itAmWattage"
              style="width: 20vw"
              placeholder="请输入组件瓦数"
            ></el-input>
          </el-form-item>
          <div class="tip">（必填）</div>
        </div>
        <div class="form-item">
          <el-form-item label="节点MAC码：" prop="itMac">
            <el-input
              v-model="form.itMac"
              style="width: 20vw"
              
            ></el-input>
          </el-form-item>
          <div class="tip">（必填）</div>
          <el-form-item label="上传频率：" prop="itUf">
            <el-input
              v-model="form.itUf"
              style="width: 20vw"
              placeholder="请输入上传频率"
            ></el-input>
          </el-form-item>
          <div class="tip">（必填）</div>
        </div>
        <div class="form-item">
          <el-form-item label="设备类型：" prop="itType">
            <el-select
              v-model="form.itType"
              style="width: 20vw"
              placeholder="请选择设备类型"
            >
              <!-- <el-option
                v-for="(item, index) in typeList"
                :key="index"
                :label="item.typeName"
                :value="item.id"
              ></el-option> -->
              <el-option label="逆变器" value="3"></el-option>
            </el-select>
          </el-form-item>
          <div class="tip">（必填）</div>
          <!-- <div style="display: flex" v-if="form.itType == 1">
            <el-form-item label="电表类型：" prop="itWhType">
              <el-select v-model="form.itWhType" style="width: 20vw">
                <el-option label="总电表" value="1"></el-option>
                <el-option label="光伏电表" value="2"></el-option>
              </el-select>
            </el-form-item>
            <div class="tip">（必填）</div>
          </div> -->
        </div>
        <div class="form-item" style="margin-left: 36.5vw">
          <div
            class="add-btn"
            @click="addNew('form')"
            v-if="$route.query.id == undefined"
          >
            添加
          </div>
          <div
            class="add-btn"
            @click="modify('form')"
            v-if="$route.query.id != undefined"
          >
            修改
          </div>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import {
  getCompanyList,
  getStationList,
  getEquipmentType,
  getWattHourMeter,
  addNewInverter,
  modifyInverter,
  getInverterDetail,
} from "@/api/equipment";
import VDistpicker from "v-distpicker";
export default {
  name: "addInverterEquipment",
  components: {
    VDistpicker,
  },
  data() {
    return {
      form: {
        itMeName: null, // 表名称
        etId: "", // 企业名称
        psId: "", // 电站名称
        itMeCard: "", // 表号
        itProvince: "", // 省
        itCity: "", // 市
        itArea: "", // 区
        itAddress: "", // 地址
        itName: "", // 逆变器名称
        itSicount: 0, // 组串数
        itType: "", // 设备类型
        itAmcount: 0, // 组件数
        itCapacity: 0, // 容量
        itAmWattage: 0, // 组件瓦数
        itUf: "", // 上传频率
        itMac: "", // 节点MAC码
        itSlaveId: "", // 从机ID
        itWhType: "", // 电表类型
      },
      companyList: [], // 企业列表
      disabled: true, // 电站名称禁用
      disabledMe: true, // 表名称禁用
      stationList: [], // 电站列表
      typeList: [], // 设备类型列表
      meList: [], // 逆变器电表列表
      rules: {
        etId: [
          { required: true, message: "企业名称不能为空", trigger: "blur" },
        ],
        psId: [
          { required: true, message: "电站名称不能为空", trigger: "blur" },
        ],
        itAddress: [
          { required: true, message: "详细地址不能为空", trigger: "blur" },
        ],
        itName: [
          { required: true, message: "逆变器名称不能为空", trigger: "blur" },
        ],
        itSicount: [
          { required: true, message: "组串数不能为空", trigger: "blur" },
        ],
        itType: [
          { required: true, message: "设备类型不能为空", trigger: "blur" },
        ],
        itAmcount: [
          { required: true, message: "组件数不能为空", trigger: "blur" },
        ],
        itCapacity: [
          { required: true, message: "容量不能为空", trigger: "blur" },
        ],
        itAmWattage: [
          { required: true, message: "组件瓦数不能为空", trigger: "blur" },
        ],
        itUf: [
          { required: true, message: "上传频率不能为空", trigger: "blur" },
        ],
        itMac: [
          { required: true, message: "节点MAC码不能为空", trigger: "blur" },
        ],
        itSlaveId: [
          { required: true, message: "从机ID不能为空", trigger: "blur" },
        ],
        itWhType: [
          { required: true, message: "电表类型不能为空", trigger: "blur" },
        ],
        // itMeName: [
        //   { required: true, message: "表名称不能为空", trigger: "blur" },
        // ],
        // itMeCard: [
        //   { required: true, message: "表号不能为空", trigger: "blur" },
        // ],
      },
    };
  },
  methods: {
    provinceChange(data) {
      //省
      console.log(data);
      this.form.itProvince = data.value;
      this.form.itCity = "";
      this.form.itArea = "";
    },
    cityChange(data) {
      //市
      this.form.itCity = data.value;
      this.form.itArea = "";
    },
    areaChange(data) {
      //区、县
      this.form.itArea = data.value;
    },
    // 获取企业列表
    getCompany() {
      getCompanyList().then((res) => {
        this.companyList = res.data;
      });
    },
    // 获取设备类型
    getTypeList() {
      getEquipmentType().then((res) => {
        console.log(res, "滴哦萨基哦");
        this.typeList = res.data;
      });
    },
    // 选择企业名称时触发
    changeCompany(e) {
      this.disabled = false;
      console.log(e);
      let id = e;
      getStationList(id).then((res) => {
        console.log(res, "圣安东尼奥就");
        this.stationList = res.data;
      });
    },
    // 选择电站名称时触发
    changeStation(e) {
      this.disabledMe = false;
      let id = e;
      getWattHourMeter(id).then((res) => {
        this.meList = res.data;
      });
    },
    // 选择表名称时触发显示对应表号
    changeName(e) {
      let obj = {};
      console.log(e);
      this.form.itMeCard = ''
      this.form.itMac = ''
      obj = this.meList.find((item) => {
        return item.id === e;
      });
      this.form.itMeCard = obj.mrMeCard;
      this.form.itMac = obj.mrMac;
    },
    // 新增
    addNew(form) {
      this.$refs[form].validate((valid) => {
        if (valid) {
          addNewInverter(this.form).then((res) => {
            if (res.code == 200) {
              this.$message({
                showClose: false,
                message: "添加成功",
                type: "success",
              });
              this.$router.push("/inverterEquipment");
            }
          });
        } else {
          return false;
        }
      });
    },
    getDetail() {
      getInverterDetail(this.$route.query.id).then((res) => {
        this.form = res.data;
        this.form.itType = "3";
        // this.form.itType = this.form.itType * 1;
        this.form.itMeName = this.form.itMeName ? parseInt(this.form.itMeName) : '';
        if (this.form.etId != "") {
          getStationList(this.form.etId).then((res) => {
            console.log(res, "圣安东尼奥就");
            this.stationList = res.data;
          });
        }
        if (this.form.psId != "") {
          getWattHourMeter(this.form.psId).then((res) => {
            this.meList = res.data;
          });
        }
      });
    },
    // 修改
    modify(form) {
      this.$refs[form].validate((valid) => {
        if (valid) {
          modifyInverter(this.form).then((res) => {
            if (res.code == 200) {
              this.$message({
                showClose: false,
                message: "修改成功",
                type: "success",
              });
              this.$router.push("/inverterEquipment");
            }
          });
        } else {
          return false;
        }
      });
    },
  },
  mounted() {
    this.getCompany();
    this.getTypeList();
    if (this.$route.query.id != undefined) {
      this.disabled = false;
      this.disabledMe = false;
      this.getDetail();
    }
  },
};
</script>

<style lang="scss" scoped>
.container::v-deep {
  display: flex;
  width: 100%;
  height: 100%;
  padding-top: 2vh;
  box-sizing: border-box;
  .left-part {
    margin-right: 2vw;
    .equipment-title {
      background: url(@/assets/images/titleBg.png);
      background-size: 100% 100%;
      width: 11.1vw;
      height: 5.5vh;
      color: #fff;
      font-size: calc(100vw * 20 / 1920);
      text-align: center;
      line-height: 5.5vh;
      margin-bottom: 1.8vh;
    }
  }
  .form-item {
    display: flex;
    height: 4.6vh;
    margin-bottom: 2.7vh;
    .add-btn {
      background: url(@/assets/images/updown.png);
      background-size: 100% 100%;
      width: 7.2vw;
      height: 4.6vh;
      color: #fff;
      font-size: calc(100vw * 18 / 1920);
      text-align: center;
      line-height: 4.6vh;
      margin-left: 20.3vw;
      cursor: pointer;
    }
    .tip {
      line-height: 4.6vh;
      margin-left: 0.5vw;
      color: #fff;
      font-size: calc(100vw * 15 / 1920);
      margin-right: 4.6vw;
      width: 3.8vw;
    }
    &:nth-child(4),
    &:nth-child(8) {
      .el-input.is-disabled .el-input__inner {
        background: #0f2455;
        border: 1px solid #286ae8;
        &::placeholder {
          text-align: center;
        }
      }
    }
  }
}
.el-form-item::v-deep {
  display: flex;
  height: 4.6vh;
  align-items: center;
  .el-form-item__label {
    width: 8vw;
    text-align: left;
    color: #fff;
    font-size: calc(100vw * 16 / 1920);
  }
}
::v-deep .el-input__inner {
  height: 4.6vh;
  background: #0f2455;
  border: 1px solid #286ae8;
  color: #fff;
  font-size: calc(100vw * 16 / 1920);
  &::placeholder {
    color: #fff;
  }
}
::v-deep .el-input__prefix {
  left: 18vw;
  font-size: calc(100vw * 20 / 1920);
}
::v-deep .el-input--prefix .el-input__inner {
  padding-left: 0.8vw;
}
::v-deep .el-select .el-input.is-disabled .el-input__inner {
  background: #0f2455;
  border: 1px solid #286ae8;
  &::placeholder {
    color: #fff;
  }
}
::v-deep .distpicker-address-wrapper select {
  width: 10.3vw;
  background: #0f2455;
  border: 1px solid #286ae8;
  color: #fff;
  margin-right: 1vw;
  height: 4.6vh;
  font-size: calc(100vw * 16 / 1920) !important;
}
</style>
