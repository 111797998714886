import Vue from "vue";
import VueRouter from "vue-router";

import Login from "@/pages/login";
import Index from "@/pages/index";
import IndexOverview from "@/pages/indexOverview";
import OperationOverview from "@/pages/operationOverview";
import EnergyConsumption from "@/pages/energyConsumption";
import StationManagement from "@/pages/stationManagement";
import Map from "@/pages/map";
import StationOverview from "@/pages/stationOverview";
import UserBill from "@/pages/userBill";
import EquipmentType from "@/pages/equipmentType";
import AddEquipment from "@/pages/addEquipment";
import NodeEntry from "@/pages/nodeEntry";
import AddNode from "@/pages/addNode";
import AddMeterReading from "@/pages/addMeterReading";
import RealTimeInverter from "@/pages/realTimeInverter";
import EquipmentOverview from "@/pages/equipmentOverview";
import ElectricityReport from "@/pages/electricityReport";
import RankingTotalEnergy from "@/pages/rankingTotalEnergy";
import NodeOfflineRate from "@/pages/nodeOfflineRate";
import HourlyChargeAndEnergy from "@/pages/hourlyChargeAndEnergy";
import HourlyDetail from "@/pages/hourlyDetail";
import AccountDetails from "@/pages/accountDetails";
import EquipmentMonitoring from "@/pages/equipmentMonitoring";
import ComparisonOfPowerTable from "@/pages/comparisonOfPowerTable";
import Power from "@/pages/power";
import InverterPowerGeneration from "@/pages/inverterPowerGeneration";
import StringOfflineRate from "@/pages/stringOfflineRate";
import DirectPower from "@/pages/directPower";
import LossAnalysis from "@/pages/lossAnalysis";
import Alarm from "@/pages/alarm";
import RepairManagement from "@/pages/repairManagement";
import InverterTemperature from "@/pages/inverterTemperature";
import PowerDetail from "@/pages/powerDetail";
import AddInverterEquipment from "@/pages/addInverterEquipment";
import ReaderEquipment from "@/pages/readerEquipment";
import PriceSetting from "@/pages/priceSetting";
import AddPrice from "@/pages/addPrice";
import AddCompanyInfo from "@/pages/addCompanyInfo";
import AddStationInfo from "@/pages/addStationInfo";
import AddAuthorization from "@/pages/addAuthorization";
import CompanyInfo from "@/pages/companyInfo";
import StationInfo from "@/pages/stationInfo";
import RealTimeMeterReader from "@/pages/realTimeMeterReader";
import UserJurisdiction from "@/pages/userJurisdiction";
import RoleManagement from "@/pages/roleManagement";
import AddRole from "@/pages/addRole";
import TotalEnergyConsumption from "@/pages/totalEnergyConsumption";
import inverterEquipment from "@/pages/inverterEquipment";
import ModifyPsd from "@/pages/modifyPsd";
import user from "@/pages/user";
import ForgetPsd from "@/pages/forgetPsd";
import Video from "@/pages/video";
import OverviewOfInverterse from "@/pages/OverviewOfInverterse";
import inverterFailure from "@/pages/inverterFailure";
import readerRules from "@/pages/readerRules";
import addReaderRules from "@/pages/addReaderRules";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: Login,
  },
  {
    path: "/login",
    component: Login,
  },
  {
    path: "/index",
    component: Index,
  },
  {
    path: "/indexOverview",
    component: IndexOverview,
  },
  {
    path: "/stationManagement",
    component: StationManagement,
  },
  {
    path: "/operationOverview",
    component: OperationOverview,
  },
  {
    path: "/energyConsumption",
    component: EnergyConsumption,
  },
  {
    path: "/map",
    component: Map,
  },
  {
    path: "/stationOverview",
    component: StationOverview,
  },
  {
    path: "/userBill",
    component: UserBill,
  },
  {
    path: "/equipmentType",
    component: EquipmentType,
  },
  {
    path: "/addEquipment",
    component: AddEquipment,
  },
  {
    path: "/nodeEntry",
    component: NodeEntry,
  },
  {
    path: "/addNode",
    component: AddNode,
  },
  {
    path: "/addMeterReading",
    component: AddMeterReading,
  },
  {
    path: "/realTimeInverter",
    component: RealTimeInverter,
  },
  {
    path: "/equipmentOverview",
    component: EquipmentOverview,
  },
  {
    path: "/electricityReport",
    component: ElectricityReport,
  },
  {
    path: "/rankingTotalEnergy",
    component: RankingTotalEnergy,
  },
  {
    path: "/nodeOfflineRate",
    component: NodeOfflineRate,
  },
  {
    path: "/hourlyChargeAndEnergy",
    component: HourlyChargeAndEnergy,
  },
  {
    path: "/hourlyDetail",
    component: HourlyDetail,
  },
  {
    path: "/accountDetails",
    component: AccountDetails,
  },
  {
    path: "/equipmentMonitoring",
    component: EquipmentMonitoring,
  },
  {
    path: "/comparisonOfPowerTable",
    component: ComparisonOfPowerTable,
  },
  {
    path: "/power",
    component: Power,
  },
  {
    path: "/inverterPowerGeneration",
    component: InverterPowerGeneration,
  },
  {
    path: "/stringOfflineRate",
    component: StringOfflineRate,
  },
  {
    path: "/directPower",
    component: DirectPower,
  },
  {
    path: "/lossAnalysis",
    component: LossAnalysis,
  },
  {
    path: "/alarm",
    component: Alarm,
  },
  {
    path: "/repairManagement",
    component: RepairManagement,
  },
  {
    path: "/inverterTemperature",
    component: InverterTemperature,
  },
  {
    path: "/powerDetail",
    component: PowerDetail,
  },
  {
    path: "/addInverterEquipment",
    component: AddInverterEquipment,
  },
  {
    path: "/readerEquipment",
    component: ReaderEquipment,
  },
  {
    path: "/priceSetting",
    component: PriceSetting,
  },
  {
    path: "/addPrice",
    component: AddPrice,
  },
  {
    path: "/addCompanyInfo",
    component: AddCompanyInfo,
  },
  {
    path: "/addStationInfo",
    component: AddStationInfo,
  },
  {
    path: "/addAuthorization",
    component: AddAuthorization,
  },
  {
    path: "/companyInfo",
    component: CompanyInfo,
  },
  {
    path: "/stationInfo",
    component: StationInfo,
  },
  {
    path: "/realTimeMeterReader",
    component: RealTimeMeterReader,
  },
  {
    path: "/userJurisdiction",
    component: UserJurisdiction,
  },
  {
    path: "/roleManagement",
    component: RoleManagement,
  },
  {
    path: "/addRole",
    component: AddRole,
  },
  {
    path: "/totalEnergyConsumption",
    component: TotalEnergyConsumption,
  },
  {
    path: "/inverterEquipment",
    component: inverterEquipment,
  },
  {
    path: "/modifyPsd",
    component: ModifyPsd,
  },
  {
    path: "/user",
    component: user,
  },
  {
    path: "/forgetPsd",
    component: ForgetPsd,
  },
  {
    path: "/video",
    component: Video,
  },
  {
    path: "/OverviewOfInverterse",
    component: OverviewOfInverterse,
  },
  {
    path: "/inverterFailure",
    component: inverterFailure,
  },
  {
    path: "/readerRules",
    component: readerRules,
  },
  {
    path: "/addReaderRules",
    component: addReaderRules,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.path === "/login" || to.path === "/" || to.path === "/forgetPsd")
    return next();
  const token = localStorage.getItem("token");
  if (!token) return next("/login");
  next();
});

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

export default router;
