<template>
  <div class="container">
    <div class="equipment-title">逆变器</div>
    <div class="form-content">
      <el-form :model="form">
        <div style="display: flex">
          <el-form-item label="SN：">
            <el-input v-model="form.SN" style="width: 16.5vw" placeholder="请输入表号"></el-input>
          </el-form-item>
          <el-form-item label="上报时间：">
            <el-date-picker
              style="width: 11vw; margin-right: 2vw"
              v-model="form.beginTime"
              type="date"
              format="yyyy-MM-dd"
              :picker-options="pickerOptions0"
              placeholder="开始日期"
            >
            </el-date-picker>
            <el-date-picker
              style="width: 11vw"
              v-model="form.endTime"
              type="date"
              format="yyyy-MM-dd"
              :picker-options="pickerOptions1"
              placeholder="结束日期"
            >
            </el-date-picker>
          </el-form-item>
        </div>
        <div style="display: flex">
          <el-form-item label="MAC：">
            <el-input v-model="form.MAC" style="width: 16.5vw" placeholder="请输入MAC码"></el-input>
          </el-form-item>
          <div class="add-btn" @click="query">查询</div>
          <div class="add-btn" @click="reset">重置</div>
        </div>
      </el-form>
    </div>
    <div class="table-detail">
      <el-table
        :data="inverter"
        :header-cell-style="headerStyle"
        :cell-style="rowStyle"
      >
        <el-table-column type="index" label="ID"></el-table-column>
        <el-table-column prop="eq" label="设备"></el-table-column>
        <el-table-column label="链接状态">
          <template slot-scope="scope">
            <span v-if="scope.row.cnt == 1">正常</span>
            <span v-if="scope.row.cnt == 0">异常</span>
          </template>
        </el-table-column>
        <el-table-column prop="mac" label="mac" width="120"></el-table-column>
        <el-table-column prop="sn" label="sn" width="120"></el-table-column>
        <el-table-column prop="allPower" label="总发电量"></el-table-column>
        <el-table-column prop="allTime" label="总发电时间"></el-table-column>
        <el-table-column prop="allTime" label="总发电时间"></el-table-column>
        <el-table-column
          prop="pvU"
          label="PV电压"
          width="220"
        ></el-table-column>
        <el-table-column
          prop="pvL"
          label="PV电流"
          width="220"
        ></el-table-column>
        <el-table-column label="运行状态">
          <template slot-scope="scope">
            <span v-if="scope.row.runState == 0"> 待机 </span>
            <span v-if="scope.row.runState == 1"> 正常 </span>
            <span v-if="scope.row.runState == 2"> 故障 </span>
          </template>
        </el-table-column>
        <el-table-column prop="temp" label="机器内部温度"></el-table-column>
        <el-table-column prop="rl" label="A相电压"></el-table-column>
        <el-table-column prop="todayPower" label="当日发电量"></el-table-column>
        <el-table-column prop="allP" label="输出功率"></el-table-column>
        <el-table-column
          width="200"
          prop="dateTime"
          label="上报时间"
        ></el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[5, 10, 15, 20]"
        :page-size="100"
        layout="sizes, prev, pager, next , total"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { getInverterData } from "@/api/equipment";
export default {
  name: "realTimeInverter",
  data() {
    return {
      pickerOptions0: {
        disabledDate: (time) => {
            return time.getTime() > Date.now();
        },
      },
      pickerOptions1: {
        disabledDate: (time) => {
          return (
            time.getTime() < this.form.beginTime || time.getTime() > Date.now()
          );
        },
      },
      form: {
        beginTime: "", // 上报时间
        endTime: "", // 上报时间
        MAC: "", // MAC
        SN: "", // SN
      },
      inverter: [],
      currentPage: 1,
      pageSize: 5,
      total: 0,
    };
  },
  methods: {
    handleSizeChange(val) {
      this.pageSize = val;
      this.getData();
    },
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage;
      this.getData();
    },
    headerStyle(column) {
      console.log(typeof columnIndex);
      if (column.columnIndex == 4 || column.columnIndex == 9) {
        return "background:#0F2455;color:#fff;border-bottom:1px solid #286AE8;border-right:1px solid #286AE8;text-align:center;font-size:calc(100vw * 15 / 1920);";
      } else {
        return "background:#0F2455;color:#fff;border-bottom:1px solid #286AE8;text-align:center;font-size:calc(100vw * 15 / 1920);";
      }
    },
    rowStyle(column) {
      if (column.columnIndex == 4 || column.columnIndex == 9) {
        return "background:#0F2455;color:#fff;border-bottom:none;border-right:1px solid #286AE8;text-align:center;font-size:calc(100vw * 14 / 1920);";
      } else {
        return "background:#0F2455;color:#fff;border-bottom:none;text-align:center;font-size:calc(100vw * 14 / 1920);";
      }
    },
    formateDate(datetime) {
      function addDateZero(num) {
        return num < 10 ? "0" + num : num;
      }
      let d = new Date(datetime);
      let formatdatetime =
        d.getFullYear() +
        "-" +
        addDateZero(d.getMonth() + 1) +
        "-" +
        addDateZero(d.getDate());
      return formatdatetime;
    },
    // 获取数据
    getData() {
      if(this.form.beginTime != ''){
        this.form.beginTime = this.formateDate(this.form.beginTime);
      }else {
        this.form.beginTime = this.formateDate(date)
      }
      if(this.form.endTime != ''){
        this.form.endTime = this.formateDate(this.form.endTime);
      }else {
        this.form.endTime = this.formateDate(date)
      }
      let data = {
        pageNum: this.currentPage,
        pageSize: this.pageSize,
        mac: this.form.MAC,
        sn: this.form.SN,
        bTime: this.form.beginTime,
        eTime: this.form.endTime,
      };
      getInverterData(data).then((res) => {
        console.log(res);
        this.inverter = res.rows;
        for (let i in this.inverter) {
          this.inverter[i].eq = "逆变器";
        }
        this.total = res.total;
      });
    },
    // 查询
    query() {
      this.currentPage = 1
      this.getData();
    },
    reset() {
      this.form.beginTime = "";
      this.form.endTime = "";
      this.form.MAC = "";
      this.form.SN = "";
      this.getData();
    },
  },
  mounted() {
    let date = new Date();
    this.form.beginTime = this.formateDate(date);
    this.form.endTime = this.formateDate(date);
    this.getData();
  },
};
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  height: 100%;
  padding-top: 2vh;
  box-sizing: border-box;
  position: relative;
  .equipment-title {
    position: absolute;
    left: 0;
    background: url(@/assets/images/titleBg.png);
    background-size: 100% 100%;
    width: 11.1vw;
    height: 5.5vh;
    color: #fff;
    font-size: calc(100vw * 20 / 1920);
    text-align: center;
    line-height: 5.5vh;
  }
  .form-content {
    position: absolute;
    left: 18.7vw;
    display: flex;
    flex-wrap: wrap;
    .add-btn {
      width: 7.2vw;
      height: 4.6vh;
      background: url(@/assets/images/updown.png);
      background-size: 100% 100%;
      margin-right: 1.5vw;
      text-align: center;
      line-height: 4.6vh;
      margin-top: 0.5vh;
      color: #02b5ff;
      font-size: calc(100vw * 18 / 1920);
      cursor: pointer;
    }
  }
  .table-detail {
    position: absolute;
    top: 8.5vw;
    left: 0;
    right: 0;
    width: 84.2vw;
    margin: 0 auto;
    .el-table {
      border: 1px solid #286ae8;
      margin-bottom: 2.7vh;
      margin-top: 1.5vh;
    }
  }
}
.el-form-item::v-deep {
  display: flex;
  height: 4.6vh;
  align-items: center;
  margin-right: 2vw;
  .el-form-item__label {
    width: 6vw;
    text-align: left;
    color: #fff;
    font-size: calc(100vw * 16 / 1920);
  }
  .el-input__inner {
    height: 4.6vh;
    background: #0f2455;
    border: 1px solid #286ae8;
    color: #fff;
    font-size: calc(100vw * 16 / 1920);
    border-radius: 6px;
    &::placeholder {
      color: #fff;
    }
  }
}
// ::v-deep .el-range-input {
//   background: none;
// }
// ::v-deep .el-date-editor .el-range-separator {
//   color: #fff;
//   font-size: calc(100vw * 17 / 1920);
//   line-height: 4.6vh;
//   height: 4.6vh;
// }
// ::v-deep .el-date-editor .el-range__icon {
//   font-size: calc(100vw * 17 / 1920);
//   line-height: 4.3vh;
//   height: 4.6vh;
// }
::v-deep .el-form-item__content {
  height: 4.6vh;
}
// ::v-deep .el-date-editor .el-range-input {
//   font-size: calc(100vw * 16 / 1920);
//   color: #fff;
//   &::placeholder {
//     color: #fff;
//   }
// }
.el-table--border::after,
.el-table--group::after,
.el-table::before {
  display: none;
}
::v-deep .el-pagination {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1vh;
}
::v-deep .el-pagination .el-select .el-input .el-input__inner,
::v-deep .el-pagination .btn-next,
::v-deep .el-pagination .btn-prev,
::v-deep .el-pager li,
::v-deep .el-pager li.btn-quicknext,
::v-deep .el-pager li.btn-quickprev,
::v-deep .el-pagination__total {
  background: #0f2455;
  border-radius: 6px;
  border: 1px solid #286ae8;
  color: #fff;
  text-align: center;
}
::v-deep .el-pager li,
::v-deep .el-pagination .btn-next,
::v-deep .el-pagination .btn-prev {
  margin-right: 0.5vw;
}
::v-deep .el-pagination__total {
  margin-right: 0;
  padding: 0 0.8vw;
  box-sizing: border-box;
}
::v-deep .el-pager li.active {
  color: #409EFF;
}
</style>
