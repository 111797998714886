<template>
  <div class="container">
    <div class="top-container">
      <div class="top-btn left">累计发电</div>
      <div class="top-btn right">累计发电收益</div>
    </div>
    <div class="middle-container">
      <div class="middle-left" v-loading="show2">
        <div
          class="num-box"
          v-for="(item, index) in cumulativePower"
          :key="index"
        >
          {{ item }}
        </div>
        <div>万度</div>
      </div>
      <div class="middle-right" v-loading="show2">
        <div class="num-box" v-for="(item, index) in profit" :key="index">
          {{ item }}
        </div>
        <div>万度</div>
      </div>
    </div>
    <div class="bottom-container">
      <div class="bottom-left">
        <div class="bottom-left-one">
          <div class="container-title">装机容量</div>
          <div
            style="
              width: 24vw;
              height: 22vh;
              padding-top: 2.5vh;
              box-sizing: border-box;
            "
          >
            <div id="oneChart" style="width: 100%; height: 100%"></div>
          </div>
        </div>
        <div class="bottom-left-one" v-loading="show3">
          <div class="container-title">设备统计</div>
          <table style="cursor: pointer" @click="goManagementDetail">
            <tr>
              <td>电站排名</td>
              <td>电站名</td>
              <td>日发电量</td>
              <td>状态</td>
            </tr>
            <tr v-for="(item, index) in ranking" :key="index">
              <td>{{ index + 1 }}</td>
              <td>{{ item.psName }}</td>
              <td>{{ item.num }}</td>
              <td>
                <span v-if="item.sta == true">正常</span>
                <span v-if="item.sta == false">异常</span>
              </td>
            </tr>
          </table>
        </div>
      </div>
      <div class="bottom-middle">
        <video
          ref="video"
          :class="{ 'video-hide': !playVideoTag }"
          @ended="onEnded('video')"
          autoplay
          loop
          muted="muted"
          :src="video"
          class="banner-inner-video"
        ></video>
      </div>
      <div>
        <div class="bottom-right-one" v-loading="show1">
          <div class="right-title">发电统计</div>
          <div
            style="
              width: 100%;
              height: 28vh;
              padding: 3vh 0;
              box-sizing: border-box;
            "
          >
            <div id="twoChart" style="width: 100%; height: 100%"></div>
          </div>
        </div>
        <div class="bottom-right-two" v-loading="show4">
          <div class="right-title">节能减排</div>
          <div class="four-content">
            <div class="four-content-item">
              <img src="../assets/images/car.png" />
              <div class="num">{{ normal }}吨</div>
              <div class="tip">节约标准煤</div>
            </div>
            <div class="four-content-item">
              <img src="../assets/images/co2.png" />
              <div class="num">{{ reduce }}吨</div>
              <div class="tip">CO2减排量</div>
            </div>
            <div class="four-content-item">
              <img src="../assets/images/tree.png" />
              <div class="num">{{ tree }}棵</div>
              <div class="tip">等效植树量</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getElectricity,
  getElectricityAndProfit,
  getCapacity,
  getStationRanking,
  getReduceEnergy,
  getVideoManage,
} from "@/api/green";
export default {
  name: "operationOverview",
  data() {
    return {
      windowWidth: 1920,
      windowHeight: 1080,
      cumulativePower: "",
      profit: "",
      installedCapacity: [], // 装机量
      ranking: [], // 排名
      normal: "", // 标准煤
      reduce: "", // 减排量
      tree: "", // 植树量
      video: "",
      playVideoTag: true,
      show1: true,
      show2: true,
      show3: true,
      show4: true,
    };
  },
  methods: {
    // 设备统计
    goManagementDetail() {
      this.$router.push("/stationManagement");
    },
    nowWidthSize(val) {
      let width = document.documentElement.clientWidth;
      let size = (width * val) / this.windowWidth;
      console.log(size, "size");
      return size;
    },
    nowHeightSize(val) {
      let height = document.documentElement.clientHeight;
      let size = (height * val) / this.windowHeight;
      console.log(size, "size");
      return size;
    },
    // 第一张图
    getEchartOne() {
      let myChart1 = this.$echarts.init(document.getElementById("oneChart"));
      getCapacity().then((res) => {
        this.installedCapacity = res.data;
        myChart1.setOption({
          legend: {
            left: this.nowWidthSize(20),
            bottom: this.nowHeightSize(0),
            icon: "circle",
            textStyle: {
              color: "#fff",
            },
          },
          series: [
            {
              type: "pie",
              radius: ["25%", "50%"],
              center: ["45%", "40%"],
              label: {
                normal: {
                  formatter: "{c}",
                  textStyle: {
                    color: "#17E0FE",
                  },
                },
              },
              color: ["#286AE8", "#06B4FC", "#0CD5F3"],
              data: this.installedCapacity,
            },
          ],
        });
      });
      let that = this;
      myChart1.on("click", function (param) {
        that.$router.push("/stationManagement");
      });

      window.addEventListener("resize", function () {
        myChart1.resize();
      });
    },
    // 第二张图
    getEchartTwo() {
      let myChart2 = this.$echarts.init(document.getElementById("twoChart"));
      getElectricity().then((res) => {
        this.show1 = false;
        let header = [];
        let val1 = [];
        let val2 = [];
        for (let i in res.data) {
          header.push(res.data[i].psName);
          val1.push(res.data[i].num);
          val2.push(res.data[i].priceNum);
        }
        myChart2.setOption({
          tooltip: {
            trigger: "axis",
            formatter: function (params) {
              return params[0].name + "：" + params[0].data + "kw/h";
            },
          },
          legend: {
            data: ["电量", "收益"],
            icon: "circle",
            textStyle: {
              color: "#fff",
            },
          },
          xAxis: [
            {
              type: "category",
              axisTick: { show: false },
              axisLine: {
                lineStyle: {
                  color: "#0B3A56",
                },
              },
              axisLabel: {
                color: "#fff",
              },
              data: header,
            },
          ],
          yAxis: [
            {
              name: "kw/h",
              axisTick: {
                show: false,
              },
              axisLine: {
                show: false,
              },
              nameTextStyle: {
                color: "#06B4FC",
              },
              type: "value",
              min: 0,
              max: 500,
              interval: 100,
              splitLine: {
                lineStyle: {
                  color: "#0B3A56",
                },
              },
              axisLabel: {
                color: "#06B4FC",
              },
            },
            {
              name: "金额万元",
              nameTextStyle: {
                color: "#06B4FC",
              },
              type: "value",
              splitLine: {
                lineStyle: {
                  color: "#0B3A56",
                },
              },
              axisLabel: {
                color: "#06B4FC",
              },
            },
          ],
          series: [
            {
              type: "bar",
              yAxisIndex: 0,
              name: "电量",
              barGap: 0,
              barWidth: 20,
              itemStyle: {
                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "#06B4FC" },
                  { offset: 1, color: "#052654" },
                ]),
              },
              emphasis: {
                focus: "series",
              },
              data: val1,
            },
            {
              name: "收益",
              type: "line",
              itemStyle: {
                color: "#286AE8",
              },
              symbol: "circle",
              yAxisIndex: 1,
              data: val2,
            },
          ],
          grid: {
            left: this.nowWidthSize(50),
            top: this.nowHeightSize(50),
            right: this.nowWidthSize(90),
            bottom: this.nowHeightSize(25),
          },
        });
      });
      let that = this;
      myChart2.on("click", function (param) {
        that.$router.push({
          path: "/powerDetail",
          query: {
            type: 3,
            title: param.name,
          },
        });
      });

      window.addEventListener("resize", function () {
        myChart2.resize();
      });
    },
    // 累计发电及收益
    getProfit() {
      getElectricityAndProfit().then((res) => {
        this.show2 = false;
        this.cumulativePower = res.sum;
        this.profit = res.sumPrice;
        this.cumulativePower = this.cumulativePower.toString().split("");
        this.profit = this.profit.toString().split("");
      });
    },
    // 电站排名
    getRanking() {
      getStationRanking().then((res) => {
        if (res.code == 200) {
          this.show3 = false;
        }
        this.ranking = res.data;
      });
    },
    // 节能减排
    getReduce() {
      getReduceEnergy().then((res) => {
        this.show4 = false;
        this.normal = res.tce;
        this.reduce = res.reduction;
        this.tree = res.plantation;
      });
    },
    getVideo() {
      getVideoManage().then((res) => {
        console.log(res, "的视角大家送");
        this.video = res.data.voPath;
      });
    },
    onEnded: function (e) {
      // 改变playVideoTag  实则是改变了video的class 是否显示在可视区域
      this.playVideoTag = false;
      // 第二个视频开始播放 即第一个视频播放完成后 开始播放第二个视频；
      // ... 第二个视频播放完毕时 调用该方法 再次播放 实现循环效果
      this.$refs.video.play();
      this.$emit("ended");
    },
    playVideo: function () {
      this.$refs.video.play(); // 第一个video 播放
      // 利用canplay事件 将第二个video暂停 实现预加载
      this.$refs.video.addEventListener(
        "canplay",
        function (e) {
          e.target.pause();
        },
        { once: true }
      );
    },
  },
  mounted() {
    this.windowHeight = window.screen.height;
    this.windowWidth = window.screen.width;
    this.getVideo();
    // this.playVideo()
    // setTimeout(() => {
    this.getEchartOne();
    this.getProfit();
    this.getRanking();
    this.getReduce();
    this.getEchartTwo();
    // }, 3000);
  },
};
</script>

<style lang="scss" scoped>
.container-title {
  color: #fff;
  font-size: calc(100vw * 16 / 1920);
  padding: 0.5vh 0 0 3.5vw;
  box-sizing: border-box;
}
.right-title {
  color: #fff;
  font-size: calc(100vw * 16 / 1920);
  padding: 0.5vh 0 0 17vw;
  box-sizing: border-box;
}
.container {
  width: 100%;
  height: 100%;
  padding: 3vh 3.2vw 0 3.2vw;
  box-sizing: border-box;
  .top-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .top-btn {
      height: 3.5vh;
      width: 12.5vw;
      text-align: center;
      line-height: 3.5vh;
      color: #fff;
      font-size: calc(100vw * 16 / 1920);
    }
    .left {
      background: url(@/assets/images/leftTitle.png);
      background-size: 100% 100%;
    }
    .right {
      background: url(@/assets/images/rightTitle.png);
      background-size: 100% 100%;
    }
  }
  .middle-container {
    margin: 2.7vh 0 4.1vh 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .middle-left,
    .middle-right {
      height: 3vh;
      display: flex;
      font-size: calc(100vw * 20 / 1920);
      color: #17e0fe;
      text-align: center;
      line-height: 3.7vh;
    }
    .num-box {
      width: 2vw;
      height: 3.7vh;
      border-radius: 2px;
      border: 1px solid rgba(255, 255, 255, 0.54);
      margin-right: 1.2vw;
    }
  }
  .bottom-container {
    display: flex;
    .bottom-left {
      width: 24vw;
      margin-right: 2vw;
      .bottom-left-one,
      .bottom-left-two {
        width: 100%;
        height: 26vh;
      }
      .bottom-left-one {
        background: url(@/assets/images/box2.png);
        background-size: 100% 100%;
        margin-bottom: 3.7vh;
        table {
          font-size: calc(100vw * 17 / 1920);
          width: 100%;
          padding-top: 3vh;
          box-sizing: border-box;
          color: #fff;
          height: 19vh;
          text-align: center;
        }
      }
    }
    .bottom-middle {
      width: 41.6vw;
      height: 55.7vh;
      background: url(@/assets/images/video.png);
      background-size: 100% 100%;
      margin-right: 2vw;
      color: #fff;
      text-align: center;
      line-height: 55.7vh;
      font-size: calc(100vw * 33 / 1920);
      position: relative;
      video {
        position: absolute;
        left: 0;
        width: 100%;
        height: 100%;
      }
      .video-hide {
        position: absolute;
        top: -10000px !important;
        left: -10000px !important;
      }
    }
    .bottom-right-one,
    .bottom-right-two {
      width: 24vw;
    }
    .bottom-right-one {
      height: 29.6vh;
      margin-bottom: 3.1vh;
      background: url(@/assets/images/box11.png);
      background-size: 100% 100%;
    }
    .bottom-right-two {
      height: 23.1vh;
      background: url(@/assets/images/box8.png);
      background-size: 100% 100%;
      .four-content {
        padding-top: 6.6vh;
        display: flex;
        justify-content: space-around;
        align-items: center;
        .four-content-item {
          text-align: center;
          img {
            width: 2.8vw;
            height: 5vh;
          }
          .num {
            margin: 0.3vh 0 0.2vh 0;
            color: #17e0fe;
            font-size: calc(100vw * 18 / 1920);
          }
          .tip {
            color: #fff;
            font-size: calc(100vw * 16 / 1920);
          }
        }
      }
    }
  }
}
::v-deep .el-loading-mask {
  background: url(../assets/images/123.gif) no-repeat;
  background-size: 100% 100%;
  // opacity: 0.5;
}
::v-deep .circular {
  display: none;
}
</style>
