<template>
  <div class="container">
    <div class="left-title">视频管理</div>
    <div class="right-container">
      <div class="right-top">
        <el-form ref="form" :model="form">
          <div
            style="width: 62.5vw; display: flex; justify-content: space-between"
          >
            <el-form-item label="全局搜索：">
              <el-input
                v-model="form.searchContent"
                placeholder="根据视频名称搜索"
                style="width: 15.6vw; margin-right: 2.5vw"
              ></el-input>
            </el-form-item>
            <div style="display: flex; height: 4.6vh">
              <div class="query" @click="addNew">添加</div>
              <el-drawer
                :show-close="false"
                :visible.sync="dialog"
                direction="rtl"
                custom-class="demo-drawer"
                ref="drawer"
              >
                <div class="demo-drawer__content">
                  <el-form :model="videoForm" ref="videoForm" :rules="rules">
                    <el-form-item label="视频标题：" prop="title">
                      <el-input
                        style="width: 15vw"
                        v-model="videoForm.title"
                        autocomplete="off"
                        placeholder="请输入视频标题"
                      ></el-input>
                    </el-form-item>
                    <el-form-item label="排序：" prop="sort">
                      <el-input
                        style="width: 15vw"
                        v-model="videoForm.sort"
                        placeholder="请输入排序"
                      >
                      </el-input>
                    </el-form-item>
                    <el-form-item label="备注：">
                      <el-input
                        style="width: 15vw"
                        v-model="videoForm.remark"
                        placeholder="请输入备注"
                      >
                      </el-input>
                    </el-form-item>
                    <el-form-item
                      style="height: 30vh; align-items: flex-start"
                      label="上传视频："
                    >
                      <el-upload
                        class="avatar-uploader"
                        action="https://admin.pvshield.cn/guang/common/upload"
                        :data="{
                          FoldPath: '上传目录',
                          SecretKey: '安全验证',
                        }"
                        :on-progress="uploadVideoProcess"
                        :on-success="handleVideoSuccess"
                        :before-upload="beforeUploadVideo"
                        :show-file-list="false"
                        :headers="uploadHeaders"
                      >
                        <video
                          v-if="videoForm.video != '' && !videoFlag"
                          :src="videoForm.video"
                          class="avatar video-avatar_second"
                          controls="controls"
                        >
                          您的浏览器不支持视频播放
                        </video>
                        <i
                          v-else-if="videoForm.video == '' && !videoFlag"
                          class="el-icon-plus avatar-uploader-icon"
                        ></i>
                        <el-progress
                          v-if="videoFlag == true"
                          type="circle"
                          :percentage="videoUploadPercent"
                        ></el-progress>
                      </el-upload>
                    </el-form-item>
                  </el-form>
                  <div class="demo-drawer__footer">
                    <div
                      v-if="id == ''"
                      class="query"
                      style="margin: 0 auto"
                      @click="submitVideo('form')"
                    >
                      完成
                    </div>
                    <div
                      v-if="id != ''"
                      class="query"
                      style="margin: 0 auto"
                      @click="modifyVideo('form')"
                    >
                      修改
                    </div>
                  </div>
                </div>
              </el-drawer>
              <div class="query" @click="query">查询</div>
              <div class="reset" @click="reset">重置</div>
            </div>
          </div>
        </el-form>
      </div>
      <el-table
        :data="powerGeneration"
        :header-cell-style="headerStyle"
        :cell-style="rowStyle"
      >
        <el-table-column type="index" label="序号" width="50"></el-table-column>
        <el-table-column prop="name" label="视频标题"></el-table-column>
        <el-table-column label="视频阅览">
          <template slot-scope="scope">
            <el-button type="text" @click="handleWatch(scope.$index)">
              点击查看
            </el-button>
            <el-dialog
            title="视频预览"
              :visible.sync="dialogVisible"
              width="50%"
              
            >
                <video
                  v-if="videoForm.video != '' && !videoFlag"
                  :src="videoForm.video"
                  class="avatar_ video-avatar"
                  controls="controls"
                >
                  您的浏览器不支持视频播放
                </video>
                <i
                  v-else-if="videoForm.video == '' && !videoFlag"
                  class="el-icon-plus avatar-uploader-icon"
                ></i>
                <el-progress
                  v-if="videoFlag == true"
                  type="circle"
                  :percentage="videoUploadPercent"
                ></el-progress>
            </el-dialog>
          </template>
        </el-table-column>
        <el-table-column prop="rank" label="排序"></el-table-column>
        <el-table-column prop="createTime" label="创建时间"></el-table-column>
        <el-table-column prop="itName" label="操作">
          <template slot-scope="scope">
            <el-button type="text" @click="modify(scope.row.id)"
              >修改</el-button
            >
            <el-button type="text" @click="handleDelete(scope.row.id)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[8, 16, 24, 32]"
        :page-size="pageSize"
        layout="sizes, prev, pager, next , total"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import {
  getVideoList,
  deleteVideo,
  addNewVideo,
  getVideoDetail,
  modifyVideo,
} from "@/api/jurisdiction";
export default {
  name: "videoPage",
  data() {
    return {
      uploadHeaders: { Authorization: window.localStorage.getItem("token") },
      videoFlag: false,
      //是否显示进度条
      videoUploadPercent: "",
      //进度条的进度，
      isShowUploadVideo: false,
      form: {
        searchContent: "", // 全局搜索
      },
      powerGeneration: [],
      currentPage: 1,
      pageSize: 8,
      total: 0,
      dialog: false,
      videoForm: {
        title: "",
        sort: "",
        video: "",
        remark: "",
      },
      id: "", // 视频id
      rules: {
        title: [
          { required: true, message: "视频标题不能为空", trigger: "blur" },
        ],
        sort: [{ required: true, message: "排序不能为空", trigger: "blur" }],
      },
      dialogVisible: false, // 点击查看弹出框
    };
  },
  methods: {
    headerStyle() {
      return "background:#0F2455;color:#fff;border: 1px solid #286AE8;text-align:center";
    },
    rowStyle() {
      return "background:#0F2455;color:#fff;border: 1px solid #286AE8;text-align:center";
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getList();
    },
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage;
      this.getList();
    },
    getList() {
      let data = {
        pageNum: this.currentPage,
        pageSize: this.pageSize,
        name: this.form.searchContent,
      };
      getVideoList(data).then((res) => {
        console.log(res, "时代精神多久哦");
        this.powerGeneration = res.rows;
        this.total = res.total;
      });
    },
    query() {
      this.currentPage = 1
      this.getList();
    },
    reset() {
      this.form.searchContent = "";
      this.getList();
    },
    handleDelete(id) {
      this.$confirm("是否确定删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deleteVideo(id).then((res) => {
          if (res.code == 200) {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
          }
          this.getList();
        });
      });
    },
    beforeUploadVideo(file) {
      var fileSize = file.size / 1024 / 1024 < 100; //控制大小  修改50的值即可
      if (
        [
          "video/mp4",
          "video/ogg",
          "video/flv",
          "video/avi",
          "video/wmv",
          "video/rmvb",
          "video/mov",
        ].indexOf(file.type) == -1 //控制格式
      ) {
        this.$message.error("请上传正确的视频格式");
        return false;
      }
      if (!fileSize) {
        this.$message.error("视频大小不能超过100MB");
        return false;
      }
      this.isShowUploadVideo = false;
    },
    //进度条
    uploadVideoProcess(event, file, fileList) {
      //注意在data中添加对应的变量名
      this.videoFlag = true;
      this.videoUploadPercent = file.percentage.toFixed(0) * 1;
    },
    //上传成功回调
    handleVideoSuccess(res, file) {
      this.isShowUploadVideo = true;
      this.videoFlag = false;
      this.videoUploadPercent = 0;

      console.log(res);
      //后台上传数据
      if (res.code == 200) {
        this.videoForm.video = res.url; //上传成功后端返回视频地址 回显
      } else {
        this.$message.error("上传失败！");
      }
    },
    // 添加
    addNew() {
      this.videoForm.title = "";
      this.videoForm.sort = "";
      this.videoForm.video = "";
      this.videoForm.remark = "";
      this.dialog = true;
    },
    // 提交新增
    submitVideo(form) {
      this.$refs[form].validate((valid) => {
        if (valid) {
          if (this.videoForm.video != "") {
            let data = {
              name: this.videoForm.title,
              rank: this.videoForm.sort,
              voPath: this.videoForm.video,
              remark: this.videoForm.remark,
            };
            addNewVideo(data).then((res) => {
              if (res.code == 200) {
                this.$message.success("新增成功");
                this.dialog = false;
                this.getList();
              }
            });
          } else {
            this.$message.error("请上传视频");
          }
        } else {
          return false;
        }
      });
    },
    modify(id) {
      this.id = id;
      this.dialog = true;
      getVideoDetail(this.id).then((res) => {
        console.log(res, "大实话");
        this.videoForm.title = res.data.name;
        this.videoForm.remark = res.data.remark;
        this.videoForm.sort = res.data.rank;
        this.videoForm.video = res.data.voPath;
      });
    },
    modifyVideo(form) {
      this.$refs[form].validate((valid) => {
        if (valid) {
          if (this.videoForm.video != "") {
            let data = {
              name: this.videoForm.title,
              rank: this.videoForm.sort,
              voPath: this.videoForm.video,
              remark: this.videoForm.remark,
              id: this.id,
            };
            modifyVideo(data).then((res) => {
              if (res.code == 200) {
                this.$message.success("修改成功");
                this.dialog = false;
                this.getList();
              }
            });
          } else {
            this.$message.error("请上传视频");
          }
        } else {
          return false;
        }
      });
    },
    // 点击查看
    handleWatch(index) {
      console.log(this.powerGeneration[index].voPath, "大家送");
      this.dialogVisible = true;
      this.videoForm.video = this.powerGeneration[index].voPath;
    },
  },
  mounted() {
    this.getList();
  },
};
</script>

<style lang="scss" scoped>
.container::v-deep {
  margin-top: 2vh;
  width: 100%;
  position: relative;
  .left-title {
    position: absolute;
    left: 0;
    top: 0;
    background: url(@/assets/images/box22.png);
    background-size: 100% 100%;
    width: 11.1vw;
    height: 5.5vh;
    color: #fff;
    font-size: calc(100vw * 20 / 1920);
    text-align: center;
    line-height: 5.5vh;
    margin-right: 7.5vw;
  }
  .right-container {
    width: 62.5vw;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    .right-top {
      margin: 0 auto;
      width: 62.5vw;
      margin-bottom: 1vh;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .el-form-item {
        display: flex;
        align-items: center;
        height: 4.6vh;
        .el-select-dropdown__item .selected,
        .el-select-dropdown__item {
          font-size: calc(100vw * 16 / 1920);
        }
        .el-input__inner {
          width: 100%;
          height: 4.6vh;
          font-size: calc(100vw * 16 / 1920);
          box-shadow: inset 0rem 0rem 0.43rem 0.17rem rgba(44, 98, 230, 0.28);
          border-radius: 6px;
          border: 0.02rem solid #286ae8;
          background: none;
          color: #fff;
          &::placeholder {
            color: #fff;
          }
        }
        .el-form-item__label {
          color: #fff;
          width: 7.2vw;
          text-align: left;
          font-size: calc(100vw * 16 / 1920);
        }
      }
      .query,
      .reset {
        width: 7.2vw;
        height: 100%;
        background: url(@/assets/images/updown.png);
        background-size: 100% 100%;
        color: #02b5ff;
        font-size: calc(100vw * 16 / 1920);
        line-height: 4.6vh;
        text-align: center;
        cursor: pointer;
      }
      .query {
        margin-right: 1.5vw;
      }
    }
  }
}
.el-table--border::after,
.el-table--group::after,
.el-table::before {
  display: none;
}
::v-deep .el-pagination {
  width: 100%;
  display: flex;
  margin-top: 1vh;
  justify-content: flex-end;
  margin-bottom: 1vh;
}
::v-deep .el-pagination .el-select .el-input .el-input__inner,
::v-deep .el-pagination .btn-next,
::v-deep .el-pagination .btn-prev,
::v-deep .el-pager li,
::v-deep .el-pager li.btn-quicknext,
::v-deep .el-pager li.btn-quickprev,
::v-deep .el-pagination__total {
  background: #0f2455;
  border-radius: 6px;
  border: 1px solid #286ae8;
  color: #fff;
  text-align: center;
}
::v-deep .el-pager li,
::v-deep .el-pagination .btn-next,
::v-deep .el-pagination .btn-prev {
  margin-right: 0.5vw;
}
::v-deep .el-pagination__total {
  margin-right: 0;
  padding: 0 0.8vw;
  box-sizing: border-box;
}
::v-deep .el-drawer.ltr,
::v-deep .el-drawer.rtl {
  background: #100c2a !important;
  padding: 0 2vw !important;
  box-sizing: border-box;
}
::v-deep .avatar-uploader .el-upload {
  border: 1px solid #d9d9d9;
  // border-radius: 6px;
  cursor: pointer;
  position: relative;
  // overflow: hidden;
  width: 15vw;
  height: 18vh;
}
::v-deep .avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
::v-deep .avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  line-height: 18vh;
  text-align: center;
}
::v-deep .avatar {
  width: 15vw;
  height: 18vh;
}
::v-deep .el-progress-circle__track {
  width: 10vw;
  height: 5vh !important;
}
::v-deep svg {
  width: 100%;
  height: 100% !important;
}
::v-deep .el-progress-circle {
  width: 100%;
  height: 18vh !important;
}
// ::v-deep .el-progress__text {
//   line-height: 18vh !important;
// }
.demo-drawer__footer {
  width: 100%;
  margin-top: 30vh;
}
::v-deep .el-dialog {
  height: 60vh;
  padding-left: 0;
  background: #0f2455;
}
::v-deep .el-table .avatar_ {
  width:38vw;
}
::v-deep .el-dialog__title {
  color: #fff;
}
::v-deep .el-pager li.active {
  color: #409EFF;
}
</style>
