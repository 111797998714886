<template>
  <div class="container">
    <div class="left-title">报修管理</div>
    <div class="right-container">
      <div class="right-top">
        <el-form ref="form" :model="form">
          <div style="width: 100%; display: flex">
            <el-form-item label="电站名称：">
              <el-input
              placeholder="请输入电站名称"
                v-model="form.stationName"
                style="width: 15.6vw; margin-right: 2vw"
              ></el-input>
            </el-form-item>
            <el-form-item label="区域：">
              <v-distpicker
                @province="provinceChange"
                @city="cityChange"
                @area="areaChange"
                :province="form.province"
                :city="form.city"
                :area="form.area"
              ></v-distpicker>
            </el-form-item>
          </div>

          <div style="display: flex; justify-content: space-between">
            <el-form-item label="维修时间：">
              <el-date-picker
                style="width: 11vw; margin-right: 2vw"
                v-model="form.beginTime"
                type="date"
                format="yyyy-MM-dd"
                :picker-options="pickerOptions0"
                placeholder="开始日期"
                @change="clearStartTime"
              >
              </el-date-picker>
              <el-date-picker
                style="width: 11vw"
                v-model="form.endTime"
                type="date"
                format="yyyy-MM-dd"
                :picker-options="pickerOptions1"
                placeholder="结束日期"
              >
              </el-date-picker>
            </el-form-item>
            <div style="display: flex; height: 4.6vh">
              <div class="query" @click="query">查询</div>
              <div class="query" @click="reset">重置</div>
              <div class="reset" @click="exportData">导出</div>
            </div>
          </div>
        </el-form>
      </div>
      <el-table
        :data="repairList"
        :header-cell-style="headerStyle"
        :cell-style="rowStyle"
      >
        <el-table-column type="index" label="序号" width="55"></el-table-column>
        <el-table-column prop="etName" label="电站名称"></el-table-column>
        <el-table-column prop="province" label="区域"></el-table-column>
        <el-table-column label="故障类型">
          <template slot-scope="scope">
            <span v-if="scope.row.problemType == 1">节点故障</span>
            <span v-if="scope.row.problemType == 2">逆变器故障</span>
            <span v-if="scope.row.problemType == 3">抄表器故障</span>
            <span v-if="scope.row.problemType == 4">断路器故障</span>
            <span v-if="scope.row.problemType == 5">自动开关故障</span>
            <span v-if="scope.row.problemType == 6">光伏组件故障</span>
          </template>
        </el-table-column>
        <el-table-column prop="problemContent" label="内容"></el-table-column>
        <el-table-column prop="companyName" label="运维公司"></el-table-column>
        <el-table-column prop="createTime" label="维修时间"></el-table-column>
        <el-table-column label="运维状态">
          <template slot-scope="scope">
            <span v-if="scope.row.status == 0">未处理</span>
            <span v-if="scope.row.status == 1">已处理</span>
          </template>
        </el-table-column>
        <el-table-column prop="realName" label="运维人员"></el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[6, 12, 18, 24]"
        :page-size="100"
        layout="sizes, prev, pager, next , total"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { getRepairList,getRepairExport } from '@/api/intelligence'
import VDistpicker from "v-distpicker";
export default {
  name: "repairManagement",
  components: { VDistpicker },
  data() {
    return {
      pickerOptions0: {
        disabledDate: (time) => {
            return time.getTime() > Date.now();
        },
      },
      pickerOptions1: {
        disabledDate: (time) => {
          return (
            time.getTime() < this.form.beginTime || time.getTime() > Date.now()
          );
        },
      },
      form: {
        province: "", // 省
        city: "", // 市
        area: "", // 区
        stationName: "", // 电站名称
        beginTime: "", // 维修时间
        endTime:""
      },
      repairList: [],
      currentPage: 1,
      pageSize: 6,
      total: 0,
    };
  },
  methods: {
    clearStartTime(){
      if(this.form.beginTime == ''){
        console.log(1111)
      }
    },
    headerStyle() {
      return "background:#0F2455;color:#fff;border: 1px solid #286AE8;text-align:center";
    },
    rowStyle() {
      return "background:#0F2455;color:#fff;border: 1px solid #286AE8;text-align:center";
    },
    handleSizeChange(val) {
      this.pageSize = val
      this.getList();
    },
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage;
      this.getList()
    },
    provinceChange(data) {
      //省
      console.log(data);
      this.form.province = data.value;
      this.form.city = '';
      this.form.area = '';
    },
    cityChange(data) {
      //市
      this.form.city = data.value;
      this.form.area = '';
    },
    areaChange(data) {
      //区、县
      this.form.area = data.value;
    },
    // 列表查询
    getList(){
      let date = new Date()
      if(this.form.beginTime != ''){
        this.form.beginTime = this.formateDate(this.form.beginTime);
      }else {
        this.form.beginTime = this.formateDate(date)
        console.log(this.form.beginTime,'大祭司哦按段骄傲')
      }
      if(this.form.endTime != ''){
        this.form.endTime = this.formateDate(this.form.endTime);
      }else {
        this.form.endTime = this.formateDate(date);
      }
      let data = {
        pageNum:this.currentPage,
        pageSize:this.pageSize,
        title:this.form.stationName,
        bTime:this.form.beginTime,
        eTime:this.form.endTime,
        province:this.form.province,
        city:this.form.city,
        district:this.form.area,
      }
      getRepairList(data).then(res => {
        this.repairList = res.rows
        this.total = res.total
      })
    },
    // 查询
    query(){
      this.getList()
    },
    // 重置
    reset(){
      this.form.stationName = ''
      this.form.beginTime = ''
      this.form.endTime = ''
      this.form.province = ''
      this.form.city = ''
      this.form.area = ''
      this.getList()
    },
    // 导出
    exportData() {
      let data = {
        title: this.form.stationName,
        bTime: this.form.beginTime,
        eTime: this.form.endTime,
        province: this.form.province,
        city: this.form.city,
        district: this.form.area,
      };
      getRepairExport(data).then((res) => {
        console.log(res, "的撒很激动i");
        let name = res.msg
        if (res.code == 200) {
          this.$axios({
            url:
              "https://admin.pvshield.cn/guang/common/download?fileName=" +
              name +
              "&delete=true",
            methods: "GET",
            responseType: "blob",
            headers: {
              "Content-Type": "application/json",
            },
          }).then((res) => {
            console.log(res, "sdhuahui");
            const blob = new Blob([res.data], {
              type: "application/vnd.ms-excel",
            });
            console.log(blob, "----------0990");
            const fileName = name + ".xls";
            const linkNode = document.createElement("a");

            linkNode.download = fileName; //a标签的download属性规定下载文件的名称
            linkNode.style.display = "none";
            linkNode.href = URL.createObjectURL(blob); //生成一个Blob URL
            document.body.appendChild(linkNode);
            linkNode.click(); //模拟在按钮上的一次鼠标单击

            URL.revokeObjectURL(linkNode.href); // 释放URL 对象
            document.body.removeChild(linkNode);
          });
        }
      });
    },
    formateDate(datetime) {
      function addDateZero(num) {
        return num < 10 ? "0" + num : num;
      }
      let d = new Date(datetime);
      let formatdatetime =
        d.getFullYear() +
        "-" +
        addDateZero(d.getMonth() + 1) +
        "-" +
        addDateZero(d.getDate());
      return formatdatetime;
    },
  },
  mounted() {
    this.getList()
  },
};
</script>

<style lang="scss" scoped>
.container::v-deep {
  margin-top: 2vh;
  width: 100%;
  position: relative;
  .left-title {
    position: absolute;
    left: 0;
    top: 0;
    background: url(@/assets/images/titleBg.png);
    background-size: 100% 100%;
    width: 11.1vw;
    height: 5.5vh;
    color: #fff;
    font-size: calc(100vw * 20 / 1920);
    text-align: center;
    line-height: 5.5vh;
    margin-right: 7.5vw;
  }
  .right-container {
    width: 70.2vw;
    position: absolute;
    left: 3vw;
    right: 0;
    margin: 0 auto;
    .right-top {
      margin: 0 auto;
      width: 64.5vw;
      margin-bottom: 3vh;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .el-form-item {
        display: flex;
        align-items: center;
        height: 4.6vh;
        .el-select-dropdown__item .selected,
        .el-select-dropdown__item {
          font-size: calc(100vw * 16 / 1920);
        }
        .el-input__inner {
          width: 100%;
          height: 4.6vh;
          font-size: calc(100vw * 16 / 1920);
          box-shadow: inset 0rem 0rem 0.43rem 0.17rem rgba(44, 98, 230, 0.28);
          border-radius: 6px;
          border: 0.02rem solid #286ae8;
          background: none;
          color: #fff;
          &::placeholder {
            color: #fff;
          }
        }
        .el-form-item__label {
          width: 6vw;
          text-align: left;
          color: #fff;
          font-size: calc(100vw * 16 / 1920);
        }
      }
      .query,
      .reset {
        width: 7.2vw;
        height: 100%;
        background: url(@/assets/images/updown.png);
        background-size: 100% 100%;
        color: #02b5ff;
        font-size: calc(100vw * 16 / 1920);
        line-height: 4.6vh;
        text-align: center;
        cursor: pointer;
      }
      .query {
        margin-right: 1.5vw;
      }
    }
  }
}
::v-deep .el-table {
  width: 99.9%;
  margin-bottom: 2.5vh;
  font-size: calc(100vw * 16 / 1920);
}
::v-deep .el-table__body {
  font-size: calc(100vw * 14 / 1920);
}
.el-table--border::after,
.el-table--group::after,
.el-table::before {
  display: none;
}
::v-deep .el-pagination {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1vh;
}
::v-deep .el-pagination .el-select .el-input .el-input__inner,
::v-deep .el-pagination .btn-next,
::v-deep .el-pagination .btn-prev,
::v-deep .el-pager li,
::v-deep .el-pager li.btn-quicknext,
::v-deep .el-pager li.btn-quickprev,
::v-deep .el-pagination__total {
  background: #0f2455;
  border-radius: 6px;
  border: 1px solid #286ae8;
  color: #fff;
  text-align: center;
}
::v-deep .el-pager li,
::v-deep .el-pagination .btn-next,
::v-deep .el-pagination .btn-prev {
  margin-right: 0.5vw;
}
::v-deep .el-pagination__total {
  margin-right: 0;
  padding: 0 0.8vw;
  box-sizing: border-box;
}
::v-deep .distpicker-address-wrapper select {
  width: 10.3vw;
  height: 4.6vh;
  background: #0f2455;
  border: 1px solid #286ae8;
  color: #fff;
  margin-left: 1vw;
  font-size: calc(100vw * 14 / 1920) !important;
}
::v-deep .el-pager li.active {
  color: #409EFF;
}
</style>
