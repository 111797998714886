<template>
  <div class="container">
    <div class="left-part">
      <div class="equipment-title">节点录入管理</div>
    </div>
    <div class="right-part">
      <div class="right-top">
        <el-form :model="form">
          <div style="display: flex;">
            <el-form-item label="全局搜索：">
            <el-input
              v-model="form.searchContent"
              placeholder="请输入企业名称或电站名称"
              style="width: 15.6vw;margin-right:7.2vw"
            ></el-input>
          </el-form-item>
            <el-form-item label="节点名称：">
              <el-input
                v-model="form.nodeName"
                placeholder="请输入节点名称"
                style="width: 15.6vw"
              ></el-input>
            </el-form-item>
          </div>
          <div style="display: flex;">
            <el-form-item label="节点MAC：">
              <el-input
                v-model="form.node"
                placeholder="请输入节点"
                style="width: 15.6vw; margin-right: 4.6vw"
              ></el-input>
            </el-form-item>
            <div class="add-btn" @click="addNew">添加</div>
            <div class="add-btn" @click="query">查询</div>
            <div class="add-btn" @click="reset">重置</div>
          </div>
        </el-form>
      </div>
      <el-table
        :data="nodeList"
        :header-cell-style="headerStyle"
        :cell-style="rowStyle"
      >
        <el-table-column type="index" label="序号"></el-table-column>
        <el-table-column prop="gfPowerStation.psName" label="电站名称"></el-table-column>
        <el-table-column prop="ndName" label="节点名称"></el-table-column>
        <el-table-column prop="ndMac" label="节点MAC码"></el-table-column>
        <el-table-column prop="ndSmcard" label="SIM卡号"></el-table-column>
        <el-table-column prop="ndSmSta" label="SIM卡状态">
            <template slot-scope="scope">
                <span v-if="scope.row.ndSmSta == 0">正常</span>
                <span v-if="scope.row.ndSmSta == 1">异常</span>
            </template>
        </el-table-column>
        <el-table-column prop="lastTime" label="最后刷新时间"></el-table-column>
        <el-table-column prop="ndTotalFlow" label="总流量"></el-table-column>
        <el-table-column prop="ndUsedFlow" label="已使用流量"></el-table-column>
        <el-table-column prop="dup" label="数据上传方式"></el-table-column>
        <el-table-column label="操作" width="110">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="text"
              @click="handleEdit(scope.row)"
              >编辑</el-button
            >
            <el-button
              size="mini"
              type="text"
              @click="handleDelete( scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[5, 10, 15, 20]"
        :page-size="100"
        layout="sizes, prev, pager, next , total"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { getNodeList,deleteNode } from '@/api/node'
export default {
  name: "nodeEntry",
  data() {
    return {
      form: {
        searchContent: "", // 搜索内容
        nodeName: "", // 节点名称
        node: "", // 节点
      },
      nodeList: [],
      currentPage: 1,
      pageSize: 5,
      total: 0,
    };
  },
  methods: {
    headerStyle() {
      return "background:#0F2455;color:#fff;border: 1px solid #286AE8;text-align:center;font-size:calc(100vw * 15 / 1920);";
    },
    rowStyle() {
      return "background:#0F2455;color:#fff;border: 1px solid #286AE8;text-align:center;font-size:calc(100vw * 14 / 1920);";
    },
    handleSizeChange(val) {
      this.pageSize = val
      this.getNode();
    },
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage;
      this.getNode()
    },
    // provinceChange(data) {
    //   //省
    //   console.log(data);
    //   this.form.province = data.value;
    // },
    // cityChange(data) {
    //   //市
    //   this.form.city = data.value;
    // },
    // areaChange(data) {
    //   //区、县
    //   this.form.area = data.value;
    // },
    // 获取节点列表
    getNode(){
        let data = {
            pageNum:this.currentPage,
            pageSize:this.pageSize,
            title:this.form.searchContent,
            ndName:this.form.nodeName,
            ndMac:this.form.node
        }
        getNodeList(data).then(res => {
            console.log(res,'和我断水')
            this.nodeList = res.rows
            this.total = res.total
        })
    },
    // 查询
    query(){
        this.getNode()
    },
    // 重置
    reset(){
        this.form.searchContent = ''
        this.form.nodeName = ''
        this.form.node = ''
        this.getNode()
    },
    // 新增
    addNew(){
        this.$router.push('/addNode')
    },
    // 编辑
    handleEdit(row){
        this.$router.push({
            path:'/addNode',
            query:{id:row.id}
        })
    },
    // 删除
    handleDelete(row){
      this.$confirm("是否确定删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deleteNode(row.id).then((res) => {
          if (res.code == 200) {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
          }
          this.getNode();
        });
      });
    }

  },
  mounted() {
    this.getNode()
  },
};
</script>

<style lang="scss" scoped>
.container::v-deep {
  display: flex;
  width: 100%;
  padding-top: 2vh;
  box-sizing: border-box;
  position: relative;
  .left-part {
    position: absolute;
    left: 0;
    .equipment-title {
      background: url(@/assets/images/titleBg.png);
      background-size: 100% 100%;
      width: 11.1vw;
      height: 5.5vh;
      color: #fff;
      font-size: calc(100vw * 20 / 1920);
      text-align: center;
      line-height: 5.5vh;
      margin-bottom: 1.8vh;
    }
  }
  .right-part {
    width: 81.9vw;
    position: absolute;
    right: 0;
    left: 0.7vw;
    margin: 0 auto;
    .right-top {
      margin-left: 10vw;
      .add-btn {
        background: url(@/assets/images/updown.png);
        background-size: 100% 100%;
        width: 7.2vw;
        height: 4.6vh;
        margin-left: 2.7vw;
        font-size: calc(100vw * 18 / 1920);
        color: #02b5ff;
        text-align: center;
        line-height: 4.6vh;
        cursor: pointer;
      }
    }
  }
}
.el-form-item::v-deep {
  display: flex;
  height: 4.6vh;
  align-items: center;
  .el-form-item__label {
    width: 6vw;
    text-align: left;
    color: #fff;
    font-size: calc(100vw * 16 / 1920);
  }
  .el-input__inner {
    height: 4.6vh;
    background: #0f2455;
    border: 1px solid #286ae8;
    color: #fff;
    font-size: calc(100vw * 16 / 1920);
    border-radius: 6px;
    &::placeholder {
      color: #fff;
    }
  }
}
::v-deep .el-table {
  width: 99.5%;
  margin-bottom: 2.5vh;
  font-size: calc(100vw * 16 / 1920);
}
::v-deep .el-table__body {
  font-size: calc(100vw * 14 / 1920);
}
.el-table--border::after,
.el-table--group::after,
.el-table::before {
  display: none;
}
::v-deep .el-button {
  background: none;
  color: #02b5ff;
  border: none;
  text-decoration: underline;
}
::v-deep .el-pagination {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1vh;
}
::v-deep .el-pagination .el-select .el-input .el-input__inner,
::v-deep .el-pagination .btn-next,
::v-deep .el-pagination .btn-prev,
::v-deep .el-pager li,
::v-deep .el-pager li.btn-quicknext,
::v-deep .el-pager li.btn-quickprev,
::v-deep .el-pagination__total {
  background: #0f2455;
  border-radius: 6px;
  border: 1px solid #286ae8;
  color: #fff;
  text-align: center;
}
::v-deep .el-pager li,
::v-deep .el-pagination .btn-next,
::v-deep .el-pagination .btn-prev {
  margin-right: 0.5vw;
}
::v-deep .el-pagination__total {
  margin-right: 0;
  padding: 0 0.8vw;
  box-sizing: border-box;
}
::v-deep .el-select .el-input.is-disabled .el-input__inner {
  background: #0f2455;
  border: 1px solid #286ae8;
  &::placeholder {
    color: #fff;
  }
}
::v-deep .distpicker-address-wrapper select {
  width: 10.3vw;
  background: #0f2455;
  border: 1px solid #286ae8;
  color: #fff;
  margin-right: 1vw;
  height: 4.6vh;
  font-size: calc(100vw * 16 / 1920) !important;
}
::v-deep .el-pager li.active {
  color: #409EFF;
}
</style>
