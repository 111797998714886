<template>
  <div class="container">
    <div class="left-title" v-if="type == 5">功率</div>
    <div class="left-title" v-if="type == 6">发电量</div>
    <div class="back" @click="backPrev">返回</div>
    <div class="right-container">
      <div class="right-top">
        <el-form ref="form" :model="form">
          <el-form-item label="电站名称：">
            <el-input
              v-model="form.searchContent"
              placeholder="请输入电站名称"
              style="width: 15.6vw; margin-right: 2.5vw"
            ></el-input>
          </el-form-item>
          <el-form-item label="报文时间：" v-if=" type == 6 && $route.query.date == '1'">
            <el-date-picker
              style="width: 15.5vw; margin-right: 2vw"
              v-model="form.beginTime"
              type="date"
              format="yyyy-MM-dd"
              placeholder="开始日期"
              :picker-options="pickerOptions0"
              disabled="disabled"
            >
            </el-date-picker>
            <el-date-picker
              style="width: 15.5vw"
              v-model="form.endTime"
              type="date"
              format="yyyy-MM-dd"
              placeholder="结束日期"
              disabled="disabled"
              :picker-options="pickerOptions1"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="报文时间：" v-if=" type == 6 && $route.query.date != '1'">
            <el-date-picker
              style="width: 15.5vw; margin-right: 2vw"
              v-model="form.beginTime"
              type="date"
              format="yyyy-MM-dd"
              placeholder="开始日期"
              :picker-options="pickerOptions0"
            >
            </el-date-picker>
            <el-date-picker
              style="width: 15.5vw"
              v-model="form.endTime"
              type="date"
              format="yyyy-MM-dd"
              placeholder="结束日期"
              :picker-options="pickerOptions1"
            >
            </el-date-picker>
          </el-form-item>
          <div
            style="width: 62.5vw; display: flex; justify-content: space-between"
          >
            <el-form-item label="电站容量：">
              <el-input
                v-model="form.installedCapacity"
                placeholder="请输入电站容量"
                style="width: 15.6vw"
              >
              </el-input>
            </el-form-item>
            <div style="display: flex; height: 4.6vh">
              <div class="query" @click="query">查询</div>
              <div class="query" @click="reset">重置</div>
              <div class="reset" @click="exportData">导出</div>
            </div>
          </div>
        </el-form>
      </div>
      <el-table
        :data="powerGeneration"
        :header-cell-style="headerStyle"
        :cell-style="rowStyle"
        v-if="type == 5"
      >
        <el-table-column type="index" label="序号" width="50"></el-table-column>
        <el-table-column prop="psName" label="电站名称"></el-table-column>
        <el-table-column prop="area" label="电站地址"></el-table-column>
        <el-table-column prop="itCapacity" label="电站容量"></el-table-column>
        <el-table-column prop="num" label="发电功率"></el-table-column>
        <el-table-column
          prop="itName"
          label="逆变器名称"
        ></el-table-column>
      </el-table>
      <el-table
        :data="powerGeneration"
        :header-cell-style="headerStyle"
        :cell-style="rowStyle"
        v-if="type == 6"
      >
        <el-table-column type="index" label="序号" width="50"></el-table-column>
        <el-table-column prop="psName" label="电站名称"></el-table-column>
        <el-table-column prop="area" label="电站地址"></el-table-column>
        <el-table-column prop="itCapacity" label="电站容量"></el-table-column>
        <el-table-column prop="sn" label="表号"></el-table-column>
        <el-table-column prop="num" label="发电量"></el-table-column>
        <el-table-column label="报文时间" v-if="$route.query.date == '1'">
          <template slot-scope="scope">
            <span v-if="scope.row.date != ''">
              {{scope.row.date}}
            </span >
            <span v-if="scope.row.hu != ''">
              {{scope.row.hu}}
            </span >
          </template>
        </el-table-column>
        <el-table-column prop="date" label="报文时间" v-if="$route.query.date != '1'"></el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[8, 16, 24, 32]"
        :page-size="100"
        layout="sizes, prev, pager, next , total"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import {
  getPowerList,
  exportPowerList,
  getPowerGenerationList,
  exportPower,
  getStationList,
} from "@/api/dataAnalysis";
export default {
  name: "power",
  data() {
    return {
      pickerOptions0: {
        disabledDate: (time) => {
            return time.getTime() > Date.now();
        },
      },
      pickerOptions1: {
        disabledDate: (time) => {
          return (
            time.getTime() < this.form.beginTime || time.getTime() > Date.now()
          );
        },
      },
      form: {
        searchContent: "", // 全局搜索
        installedCapacity: "", // 装机量
        beginTime:"",
        endTime:""
      },
      powerGeneration: [],
      currentPage: 1,
      pageSize: 8,
      total: 0,
      stationList: [],
    };
  },
  methods: {
    getStation() {
      getStationList().then((res) => {
        this.stationList = res.data;
        for (let i in this.stationList) {
          if (this.$route.query.psId == this.stationList[i].id) {
            this.form.searchContent = this.stationList[i].psName;
          }
        }
      });
    },
    headerStyle() {
      return "background:#0F2455;color:#fff;border: 1px solid #286AE8;text-align:center";
    },
    rowStyle() {
      return "background:#0F2455;color:#fff;border: 1px solid #286AE8;text-align:center";
    },
    handleSizeChange(val) {
      this.pageSize = val;
      if (this.type == 5) {
        this.getList();
      } else {
        this.getListS();
      }
    },
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage;
      if (this.type == 5) {
        this.getList();
      } else {
        this.getListS();
      }
    },
    backPrev() {
      let type = this.type;
      if(this.fromPage == '/stringOfflineRate' || this.fromPage == '/powerDetail'){
        this.$router.push('/equipmentOverview')
      }else {
        this.$router.push({
          path: "/rankingTotalEnergy",
          query: { type },
        });
      }
    },
    getList() {
      if (
        this.$route.query.psCapacity != undefined &&
        this.form.installedCapacity == ""
      ) {
        this.form.installedCapacity = this.$route.query.psCapacity;
      }
      if(this.form.searchContent == '' && this.$route.query.title != undefined){
        this.form.searchContent = this.$route.query.title
      }
      let data = {
        pageNum: this.currentPage,
        pageSize: this.pageSize,
        bTime: this.$route.query.date,
        title: this.form.searchContent,
        psCapacity: this.form.installedCapacity,
      };
      getPowerList(data).then((res) => {
        this.powerGeneration = res.rows;
        this.total = res.total;
      });
    },
    formateDate(datetime) {
      function addDateZero(num) {
        return num < 10 ? "0" + num : num;
      }
      let d = new Date(datetime);
      let formatdatetime =
        d.getFullYear() +
        "-" +
        addDateZero(d.getMonth() + 1) +
        "-" +
        addDateZero(d.getDate());
      return formatdatetime;
    },
    getListS() {
      let date = new Date()
      if(this.form.beginTime == ''){
        this.form.beginTime = this.$route.query.time
      }
      if(this.form.endTime == ''){
        this.form.endTime = this.$route.query.time
      }
      let data = {
        psId:this.$route.query.psId,
        pageNum: this.currentPage,
        pageSize: this.pageSize,
        bTime: this.form.beginTime,
        eTime:this.form.endTime,
        psCapacity: this.form.installedCapacity,
        type:this.$route.query.date
      };
      getPowerGenerationList(data).then((res) => {
        this.powerGeneration = res.data;
        this.total = res.total;
      });
    },
    query() {
      if (this.type == 5) {
      this.currentPage = 1
        this.getList();
      } else {
      this.currentPage = 1
        this.getListS();
      }
    },
    reset() {
      this.form.searchContent = "";
      this.form.installedCapacity = "";
      if (this.type == 5) {
        this.getList();
      } else {
        this.getListS();
      }
    },
    exportData() {
      if(this.form.installedCapacity == ''){
        this.form.installedCapacity = ''
      }
      if (this.type == 5) {
        let data = {
        itCapacity: this.form.installedCapacity,
        bTime: this.$route.query.date,
        title: this.form.searchContent,
      };
        exportPowerList(data).then((res) => {
          console.log(res, "的撒很激动i");
          let name = res.msg;
          if (res.code == 200) {
            this.$axios({
              url:
                "https://admin.pvshield.cn/guang/common/download?fileName=" +
                name +
                "&delete=true",
              methods: "GET",
              responseType: "blob",
              headers: {
                "Content-Type": "application/json",
              },
            }).then((res) => {
              console.log(res, "sdhuahui");
              const blob = new Blob([res.data], {
                type: "application/vnd.ms-excel",
              });
              console.log(blob, "----------0990");
              const fileName = name + ".xls";
              const linkNode = document.createElement("a");

              linkNode.download = fileName; //a标签的download属性规定下载文件的名称
              linkNode.style.display = "none";
              linkNode.href = URL.createObjectURL(blob); //生成一个Blob URL
              document.body.appendChild(linkNode);
              linkNode.click(); //模拟在按钮上的一次鼠标单击

              URL.revokeObjectURL(linkNode.href); // 释放URL 对象
              document.body.removeChild(linkNode);
            });
          }
        });
      } else {
        let data = {
        psCapacity: this.form.installedCapacity,
        bTime: this.form.beginTime,
        eTime: this.form.endTime,
        type: this.$route.query.date,
        psId:this.$route.query.psId
      };
        exportPower(data).then((res) => {
          console.log(res, "的撒很激动i");
          let name = res.msg;
          if (res.code == 200) {
            this.$axios({
              url:
                "https://admin.pvshield.cn/guang/common/download?fileName=" +
                name +
                "&delete=true",
              methods: "GET",
              responseType: "blob",
              headers: {
                "Content-Type": "application/json",
              },
            }).then((res) => {
              console.log(res, "sdhuahui");
              const blob = new Blob([res.data], {
                type: "application/vnd.ms-excel",
              });
              console.log(blob, "----------0990");
              const fileName = name + ".xls";
              const linkNode = document.createElement("a");

              linkNode.download = fileName; //a标签的download属性规定下载文件的名称
              linkNode.style.display = "none";
              linkNode.href = URL.createObjectURL(blob); //生成一个Blob URL
              document.body.appendChild(linkNode);
              linkNode.click(); //模拟在按钮上的一次鼠标单击

              URL.revokeObjectURL(linkNode.href); // 释放URL 对象
              document.body.removeChild(linkNode);
            });
          }
        });
      }
    },
  },
  created() {
    this.type = this.$route.query.type;
    console.log(this.$route.query.title,'大师赛哦')
    this.getStation();
    if (this.type == 5) {
      this.getList();
    } else {
      this.getListS();
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      console.log(from,'的教授及殴打')
      vm.fromPage = from.path;
    });
  },
};
</script>

<style lang="scss" scoped>
.container::v-deep {
  margin-top: 2vh;
  width: 100%;
  position: relative;
  .left-title {
    position: absolute;
    left: 0;
    top: 0;
    background: url(@/assets/images/box22.png);
    background-size: 100% 100%;
    width: 11.1vw;
    height: 5.5vh;
    color: #fff;
    font-size: calc(100vw * 20 / 1920);
    text-align: center;
    line-height: 5.5vh;
    margin-right: 7.5vw;
  }
  .back {
    position: absolute;
    top: 8vh;
    background: url(@/assets/images/box22.png);
    background-size: 100% 100%;
    width: 11.1vw;
    height: 5.5vh;
    color: #fff;
    font-size: calc(100vw * 20 / 1920);
    text-align: center;
    line-height: 5.5vh;
    cursor: pointer;
  }
  .right-container {
    width: 62.5vw;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    .right-top {
      margin: 0 auto;
      width: 62.5vw;
      margin-bottom: 3vh;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .el-form-item {
        display: flex;
        align-items: center;
        height: 4.6vh;
        .el-select-dropdown__item .selected,
        .el-select-dropdown__item {
          font-size: calc(100vw * 16 / 1920);
        }
        .el-input__inner {
          width: 100%;
          height: 4.6vh;
          font-size: calc(100vw * 16 / 1920);
          box-shadow: inset 0rem 0rem 0.43rem 0.17rem rgba(44, 98, 230, 0.28);
          border-radius: 6px;
          border: 0.02rem solid #286ae8;
          background: none;
          color: #fff;
        }
        .el-form-item__label {
          color: #fff;
          width: 7.2vw;
          text-align: left;
          font-size: calc(100vw * 16 / 1920);
        }
      }
      .query,
      .reset {
        width: 7.2vw;
        height: 100%;
        background: url(@/assets/images/updown.png);
        background-size: 100% 100%;
        color: #02b5ff;
        font-size: calc(100vw * 16 / 1920);
        line-height: 4.6vh;
        text-align: center;
        cursor: pointer;
      }
      .query {
        margin-right: 1.5vw;
      }
    }
  }
}
::v-deep .el-table {
  width: 99.9%;
  margin-bottom: 2.5vh;
  font-size: calc(100vw * 16 / 1920);
}
::v-deep .el-table__body {
  font-size: calc(100vw * 14 / 1920);
}
.el-table--border::after,
.el-table--group::after,
.el-table::before {
  display: none;
}
::v-deep .el-pagination {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1vh;
}
::v-deep .el-pagination .el-select .el-input .el-input__inner,
::v-deep .el-pagination .btn-next,
::v-deep .el-pagination .btn-prev,
::v-deep .el-pager li,
::v-deep .el-pager li.btn-quicknext,
::v-deep .el-pager li.btn-quickprev,
::v-deep .el-pagination__total {
  background: #0f2455;
  border-radius: 6px;
  border: 1px solid #286ae8;
  color: #fff;
  text-align: center;
}
::v-deep .el-pager li,
::v-deep .el-pagination .btn-next,
::v-deep .el-pagination .btn-prev {
  margin-right: 0.5vw;
}
::v-deep .el-pagination__total {
  margin-right: 0;
  padding: 0 0.8vw;
  box-sizing: border-box;
}
::v-deep .distpicker-address-wrapper select {
  width: 10.3vw;
  background: #0f2455;
  border: 1px solid #286ae8;
  color: #fff;
  margin-right: 1vw;
}
::v-deep .el-pager li.active {
  color: #409EFF;
}
</style>
