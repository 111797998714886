<template>
  <div>
    <el-container style="height: 100vh">
      <el-aside width="14.2vw" v-if="showMenu == true">
        <!-- default-expanded-keys="[Custom]" -->

        <!-- <el-tree
          :data="arr"
          :props="defaultProps"
          node-key="title"
          :default-expand-all="false"
          :expand-on-click-node="false"
          :default-expanded-keys="defaultShowNodes"
          :accordion="true"
        >
          <span
            class="custom-tree-node"
            @click="goNext(node)"
            slot-scope="{ node, arr }"
          >
            {{ node.label.first }}
          </span>
        </el-tree> -->
        <div class="aslide_content">
          <div class="aslide_content_">
            <div class="aslide_title">光伏大数据</div>
            <div class="aslide_body">
              <div v-for="(item, index) in aslideList" :key="index">
                <div
                  class="aslide_small_title"
                  v-if="!item.open"
                  @click="openMenuOne(item)"
                >
                  <img src="@/assets/images/aslideRight.png" />
                  <span>{{ item.meta.title }}</span>
                </div>
                <div v-if="item.open">
                  <div class="aslide_small_title" @click="openMenuOne(item)">
                    <img src="@/assets/images/aslideDown.png" />
                    <span>{{ item.meta.title }}</span>
                  </div>
                  <div class="margin_left">
                    <div v-for="(item1, index1) in item.children" :key="index1">
                      <div
                        class="aslide_second_title_none"
                        v-if="item1.children == null"
                        @click="goUrl(item1.path, item1.meta.title)"
                      >
                        <span>{{ item1.meta.title }}</span>
                      </div>
                      <div
                        class="aslide_second_title"
                        v-if="item1.children != null && item1.open"
                        @click="openMenuOne(item1)"
                      >
                        <img src="@/assets/images/aslideDown.png" />
                        <span>{{ item1.meta.title }}</span>
                      </div>
                      <div
                        class="aslide_second_title"
                        v-if="item1.children != null && !item1.open"
                        @click="openMenuOne(item1)"
                      >
                        <img src="@/assets/images/aslideRight.png" />
                        <span>{{ item1.meta.title }}</span>
                      </div>
                      <div class="margin_left" v-if="item1.open">
                        <div
                          v-for="(item2, index2) in item1.children"
                          :key="index2"
                        >
                          <div
                            class="aslide_third_title_none"
                            v-if="item2.children == null"
                            @click="goUrl(item2.path, item2.meta.title)"
                          >
                            {{ item2.meta.title }}
                          </div>
                          <div
                            class="aslide_third_title"
                            v-if="item2.children != null && item2.open"
                            @click="openMenuOne(item2)"
                          >
                            <img src="@/assets/images/aslideDown.png" />
                            <span>{{ item2.meta.title }}</span>
                          </div>
                          <div
                            class="aslide_third_title"
                            v-if="item2.children != null && !item2.open"
                            @click="openMenuOne(item2)"
                          >
                            <img src="@/assets/images/aslideRight.png" />
                            <span>{{ item2.meta.title }}</span>
                          </div>
                          <div class="margin_left" v-if="item2.open">
                            <div
                              v-for="(item3, index3) in item2.children"
                              :key="index3"
                            >
                              <div
                                class="aslide_four_title_none"
                                @click="goUrl(item3.path, item3.meta.title)"
                              >
                                {{ item3.meta.title }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-aside>
      <el-container style="margin-left: 14.2vw" v-if="showMenu == true">
        <el-header style="padding: 0; height: 11.8vh">
          <div class="header-second">
            <div
              class="item"
              v-for="(item, index) in list"
              :key="index"
              @click="jump(item.path, index)"
            >
              {{ item.title }}
            </div>
          </div>
          <div class="header">
            <img
              class="open-list"
              src="../assets/images/list.png"
              @click="openMenu"
            />
            <img
              class="enlarge"
              src="../assets/images/enlarge.png"
              @click="screen"
            />
            <el-badge :value="noticeNum" class="item">
              <i class="el-icon-bell" @click="openDialog"></i>
            </el-badge>
            <span class="notice_text">消息通知</span>
            <el-switch
              v-model="value1"
              active-color="#17e0fe"
              inactive-color="#bbb"
            >
            </el-switch>
            <noticeCon
              v-if="value1"
              ref="noticeConRefs"
              sound="message.mp3"
            ></noticeCon>
            <el-dialog
              :append-to-body="true"
              title="报警列表"
              :visible.sync="dialogTableVisible"
            >
              <el-table
                :data="noticeList"
                style="margin-bottom: 1.5vh"
                :header-cell-style="headerStyle"
                :cell-style="rowStyle"
              >
                <el-table-column
                  property="psName"
                  label="电站名称"
                ></el-table-column>
                <el-table-column property="sn" label="sn"></el-table-column>
                <el-table-column
                  property="snName"
                  label="电表名称"
                ></el-table-column>
                <el-table-column
                  property="gfTypeName"
                  label="电表类型"
                ></el-table-column>
                <el-table-column
                  property="createTime"
                  label="时间"
                ></el-table-column>
              </el-table>
              <span class="more" @click="goMore">点击查看更多</span>
            </el-dialog>
            <div class="title">
              <img class="title-img" :src="logo" />
              <div>{{ title }}</div>
            </div>
            <div class="time">
              <img class="time-img" src="../assets/images/time.png" />
              <div class="time-date" style="margin-right: 0.3vw">
                {{ info.date }}
              </div>
              <div class="time-date">{{ info.time }}</div>
              <div class="time-date">{{ info.week }}</div>
              <div class="time-date">{{ info.city }}</div>
            </div>
            <div class="name">
              <!-- <div class="name-left">{{ info.name }}</div> -->
              <el-dropdown>
                <div class="name-left">{{ info.name }}</div>
                <el-dropdown-menu slot="dropdown" placement="top-end">
                  <el-dropdown-item @click.native="goUser"
                    >个人中心</el-dropdown-item
                  >
                  <el-dropdown-item @click.native="logout"
                    >退出登录</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
              <div class="name-right">{{ info.identity }}</div>
            </div>
          </div>
        </el-header>
        <el-main style="padding: 0; overflow: visible">
          <router-view :key="dateKey"></router-view>
        </el-main>
      </el-container>
      <el-container v-if="showMenu == false">
        <el-header style="padding: 0; height: 11.8vh">
          <div class="header-second">
            <div
              class="item"
              v-for="(item, index) in list"
              :key="index"
              @click="jump(item.path, index)"
            >
              {{ item.title }}
            </div>
          </div>
          <div class="header">
            <img
              class="open-list"
              src="../assets/images/list.png"
              @click="openMenu"
            />
            <img
              class="enlarge"
              src="../assets/images/enlarge.png"
              @click="screen"
            />
            <el-badge :value="noticeNum" class="item">
              <i class="el-icon-bell" @click="openDialog"></i>
            </el-badge>
            <span class="notice_text">消息通知</span>
            <el-switch
              v-model="value1"
              active-color="#17e0fe"
              inactive-color="#bbb"
            >
            </el-switch>
            <noticeCon
              v-if="value1"
              ref="noticeConRefs"
              sound="message.mp3"
            ></noticeCon>
            <el-dialog
              :append-to-body="true"
              title="报警列表"
              :visible.sync="dialogTableVisible"
            >
              <el-table
                :data="noticeList"
                style="margin-bottom: 1.5vh"
                :header-cell-style="headerStyle"
                :cell-style="rowStyle"
              >
                <el-table-column
                  property="psName"
                  label="电站名称"
                ></el-table-column>
                <el-table-column property="sn" label="sn"></el-table-column>
                <el-table-column
                  property="snName"
                  label="电表名称"
                ></el-table-column>
                <el-table-column
                  property="gfTypeName"
                  label="电表类型"
                ></el-table-column>
                <el-table-column
                  property="createTime"
                  label="时间"
                ></el-table-column>
              </el-table>
              <span class="more" @click="goMore">点击查看更多</span>
            </el-dialog>
            <div class="title">
              <img class="title-img" :src="logo" />
              <div>{{ title }}</div>
            </div>
            <div class="time">
              <img class="time-img" src="../assets/images/time.png" />
              <div class="time-date" style="margin-right: 0.3vw">
                {{ info.date }}
              </div>
              <div class="time-date">{{ info.time }}</div>
              <div class="time-date">{{ info.week }}</div>
              <div class="time-date">{{ info.city }}</div>
            </div>
            <div class="name">
              <!-- <div class="name-left">{{ info.name }}</div> -->
              <el-dropdown>
                <div class="name-left">{{ info.name }}</div>
                <el-dropdown-menu slot="dropdown" placement="top-end">
                  <el-dropdown-item @click.native="goUser"
                    >个人中心</el-dropdown-item
                  >
                  <el-dropdown-item @click.native="logout"
                    >退出登录</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
              <div class="name-right">{{ info.identity }}</div>
            </div>
          </div>
        </el-header>
        <el-main style="padding: 0; overflow: visible">
          <router-view :key="dateKey"></router-view>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import noticeCon from "./notice.vue";
import {
  getUserInfo,
  getUserRouter,
  getNotice,
  getNoticeList,
} from "@/api/index.js";
export default {
  name: "Header",
  components: { noticeCon },
  data() {
    // const arr = [];
    return {
      // defaultProps: {
      //   children: "children",
      //   label(a, b) {
      //     console.log(a, 111);
      //     return {
      //       first: a.meta.title,
      //       second: a.bbb,
      //     };
      //   },
      // },
      value1: false, // 消息通知
      // arr: JSON.parse(JSON.stringify(arr)),
      dateKey: JSON.stringify(new Date()),
      list: [
        { title: "服务中心", path: "/index" },
        { title: "绿色能源", path: "/operationOverview" },
        { title: "数据分析", path: "/energyConsumption" },
        { title: "设备管理", path: "/equipmentType" },
        { title: "智能运维", path: "/indexOverview" },
        { title: "系统设置", path: "/userJurisdiction" },
      ],
      info: {
        date: "",
        week: "",
        time: "",
        city: "",
        name: "",
        identity: "",
      },
      defaultShowNodes: [],
      title: "",
      logo: "",
      showMenu: false,
      showSecond: true,
      showThird: true,
      fullscreen: false,
      noticeNum: 0, // 消息数量
      noticeList: [], // 消息列表
      dialogTableVisible: false,
      isReceive: false,
      aslideList: [], // 侧边栏
    };
  },
  methods: {
    goUrl(path, title) {
      this.menuSelect(); // 加了这个跳转同一个页面就会重新渲染了
      // if (path == "/nodeOfflineRate") {
      //   switch (title) {
      //     case "电站离线率":
      //       this.$router.push({
      //         path: path,
      //         query: {
      //           type: 1,
      //         },
      //       });
      //       break;
      //     case "节点离线时长":
      //       this.$router.push({
      //         path: path,
      //         query: {
      //           type: 2,
      //         },
      //       });
      //       break;
      //   }
      // } else if (path == "/rankingTotalEnergy") {
      //   switch (title) {
      //     case "发电量统计":
      //       this.$router.push({
      //         path: path,
      //         query: {
      //           type: 3,
      //         },
      //       });
      //       break;
      //     case "同区域发电量对比":
      //       this.$router.push({
      //         path: path,
      //         query: {
      //           type: 4,
      //         },
      //       });
      //       break;
      //     case "功率":
      //       this.$router.push({
      //         path: path,
      //         query: {
      //           type: 5,
      //         },
      //       });
      //       break;
      //     case "发电量":
      //       this.$router.push({
      //         path: path,
      //         query: {
      //           type: 6,
      //         },
      //       });
      //       break;
      //     case "组串离散率":
      //       this.$router.push({
      //         path: path,
      //         query: {
      //           type: 7,
      //         },
      //       });
      //       break;
      //     case "逆变器温度":
      //       this.$router.push({
      //         path: path,
      //         query: {
      //           type: 8,
      //         },
      //       });
      //       break;
      //     case "逆变器等效时长":
      //       this.$router.push({
      //         path: path,
      //         query: {
      //           type: 9,
      //         },
      //       });
      //       break;
      //     case "总用能排行图标":
      //       this.$router.push({
      //         path: path,
      //         query: {
      //           type: 12,
      //         },
      //       });
      //       break;
      //     case "市电太阳能占比":
      //       this.$router.push({
      //         path: path,
      //         query: {
      //           type: 13,
      //         },
      //       });
      //       break;
      //     case "分时用能":
      //       this.$router.push({
      //         path: path,
      //         query: {
      //           type: 14,
      //         },
      //       });
      //       break;
      //     case "分时电费":
      //       this.$router.push({
      //         path: path,
      //         query: {
      //           type: 15,
      //         },
      //       });
      //       break;
      //     case "逆变器发电量":
      //       this.$router.push({
      //         path: path,
      //         query: {
      //           type: 18,
      //         },
      //       });
      //       break;
      //     case "直流电流":
      //       this.$router.push({
      //         path: path,
      //         query: {
      //           type: 19,
      //         },
      //       });
      //       break;
      //     case "直流电压":
      //       this.$router.push({
      //         path: path,
      //         query: {
      //           type: 20,
      //         },
      //       });
      //       break;
      //     case "损耗分析":
      //       this.$router.push({
      //         path: path,
      //         query: {
      //           type: 21,
      //         },
      //       });
      //       break;
      //   }
      // } else if (path == "/readerEquipment") {
      //   if (title == "抄表器设备") {
      //     this.$router.push({
      //       path: path,
      //       query: {
      //         type: 16,
      //       },
      //     });
      //   }
      // } else {
        this.$router.push({
          path: path
        });
      // }
    },
    openMenuOne(item) {
      item.open = !item.open;
      console.log(this.aslideList, "大祭司阿娇");
    },
    headerStyle() {
      return "text-align:center;font-size:calc(100vw * 16 / 1920);";
    },
    rowStyle() {
      return "text-align:center;";
    },
    goMore() {
      this.dialogTableVisible = false;
      this.$router.push("/alarm");
    },
    // 全屏放大缩小
    screen() {
      let element = document.documentElement;
      if (this.fullscreen) {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
      } else {
        if (element.requestFullscreen) {
          element.requestFullscreen();
        } else if (element.webkitRequestFullScreen) {
          element.webkitRequestFullScreen();
        } else if (element.mozRequestFullScreen) {
          element.mozRequestFullScreen();
        } else if (element.msRequestFullscreen) {
          // IE11
          element.msRequestFullscreen();
        }
      }
      this.fullscreen = !this.fullscreen;
    },
    // 退出登录
    logout() {
      console.log(111);
      this.$confirm("是否确定退出登录?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        sessionStorage.clear();
        this.$router.push("/login");
      });
    },
    // 头部跳转
    jump(path, index) {
      this.$router.push({
        path: path,
      });
    },
    // 获取路由
    getRouter() {
      getUserRouter().then((res) => {
        console.log(res, 111);
        // this.arr = res.data;
        this.aslideList = res.data[0].children;
        for (let i in this.aslideList) {
          this.aslideList[i]["open"] = false;
          for (let j in this.aslideList[i].children) {
            this.aslideList[i].children[j]["open"] = false;
            for (let a in this.aslideList[i].children[j].children) {
              this.aslideList[i].children[j].children[a]["open"] = false;
            }
          }
        }
        console.log(this.aslideList, "侧边栏");
      });
    },
    // 跳转至个人中心页面
    goUser() {
      this.$router.push("/user");
    },
    // 获取用户信息
    getUser() {
      getUserInfo().then((res) => {
        this.info.name = res.user.nickName;
        this.info.identity = res.user.remark;
        if (res.emp) {
          this.info.city = res.emp.etProvince;
          this.title = res.user.logoName;
          this.logo = res.user.logoVar;
        } else {
          this.info.city = "";
        }
      });
    },
    openMenu() {
      this.showMenu = !this.showMenu;
      console.log(this.showMenu);
    },
    menuSelect() {
      let m = this;
      m.dateKey = JSON.stringify(new Date());
    },
    getdataTime() {
      let wk = new Date().getDay();
      let yy = new Date().getFullYear();
      let mm = new Date().getMonth() + 1;
      let dd = new Date().getDate();
      let weeks = ["周日", "周一", "周二", "周三", "周四", "周五", "周六"];
      this.info.week = weeks[wk];
      this.info.date = yy + "年" + mm + "月" + dd + "日";
    },
    // 获取当前系统的时间
    getnewTime() {
      let hh = new Date().getHours();
      let mf =
        new Date().getMinutes() < 10
          ? "0" + new Date().getMinutes()
          : new Date().getMinutes();
      let ss =
        new Date().getSeconds() < 10
          ? "0" + new Date().getSeconds()
          : new Date().getSeconds();
      this.info.time = hh + ":" + mf + ":" + ss;
    },
    // 获取消息数量
    getNum() {
      getNotice().then((res) => {
        // console.log(res, "的交接送i");
        this.noticeNum = res.data;
      });
    },
    // 消息列表
    getList() {
      getNoticeList().then((res) => {
        this.noticeList = res.data;
      });
    },
    // 打开消息列表
    openDialog() {
      this.dialogTableVisible = true;
      this.getList();
    },
  },
  mounted() {
    this.getUser();
    this.getdataTime();
    // 读秒
    setInterval(() => {
      this.getnewTime();
    }, 1000);
    this.getRouter();
    setInterval(this.getNum, 5000);
    // setInterval(this.getList, 5000);
    setInterval(() => {
      if (this.noticeList.length != 0) {
        if (this.value1) {
          this.$notify.info({
            title: "报警提醒",
            message: "您有一条新消息",
          });
        }
        if (this.value1) {
          this.$refs.noticeConRefs.tip("您有新消息");
        }
      }
    }, 5000);
  },
};
</script>

<style></style>
<style lang="scss" scoped>
.margin_left {
  margin-left: 1vw;
}
::-webkit-scrollbar {
  width: 0 !important;
}
::-webkit-scrollbar {
  width: 0 !important;
  height: 0;
}
::v-deep .el-aside {
  // min-height: 100%;
  z-index: 100;
  height: 100%;
  position: fixed;
  // background: #0c3688 !important;
  padding: 4vh 0.5vw 4.8vh 0.5vw;
  box-sizing: border-box;
  .aslide_content {
    width: 100%;
    height: 100%;
    // background: url('https://s1.ax1x.com/2023/03/31/ppR8JSS.png') no-repeat;
    background: url(@/assets/images/aslideBg.png) no-repeat;
    background-size: 100% 100%;
    padding: 3.4vh 1.4vw;
    box-sizing: border-box;
    color: #fff;
    .aslide_content_ {
      width: 100%;
      height: 100%;
      overflow-y: scroll;
      .aslide_title {
        font-size: 0.9vw;
        width: 100%;
        height: 4.6vh;
        background: url(@/assets/images/aslideTitle.png) no-repeat;
        background-size: 100% 100%;
        text-align: center;
        line-height: 4.6vh;
        margin-bottom: 0.5vh;
      }
      .aslide_body {
        .aslide_small_title {
          width: 100%;
          height: 4.6vh;
          display: flex;
          align-items: center;
          background: url(@/assets/images/aslideContent.png) no-repeat;
          background-size: 100% 100%;
          padding: 0 0.5vw;
          box-sizing: border-box;
          margin-bottom: 0.5vh;
          > img {
            width: 1vw;
            height: 1.8vh;
            margin-right: 0.3vw;
          }
          > span {
            font-size: 0.8vw;
          }
        }
        .aslide_second_title,
        .aslide_second_title_none,
        .aslide_third_title_none,
        .aslide_third_title,
        .aslide_four_title_none {
          height: 4.6vh;
          background: url(@/assets/images/aslideContent.png) no-repeat;
          background-size: 100% 100%;
          padding: 0 0.5vw;
          box-sizing: border-box;
          margin-bottom: 0.5vh;
          display: flex;
          align-items: center;
          cursor: pointer;
          > img {
            width: 1vw;
            height: 1.8vh;
            margin-right: 0.3vw;
          }
        }
        .aslide_second_title_none,
        .aslide_four_title_none,
        .aslide_third_title_none {
          padding: 0 1vw;
        }
        .aslide_second_title_none,
        .aslide_second_title {
          font-size: 0.8vw;
        }
        .aslide_third_title_none,
        .aslide_third_title {
          font-size: 0.7vw;
        }
        .aslide_four_title_none {
          font-size: 0.6vw;
        }
      }
    }
  }
}
.btn {
  width: 8vw;
  height: 4.6vh;
  background: url(@/assets/images/updown.png);
  background-size: 100% 100%;
  color: #fff;
  text-align: center;
  line-height: 4.6vh;
  margin: 0 auto;
  margin-bottom: 2vh;
  font-size: calc(100vw * 16 / 1920);
}
.header {
  z-index: 99;
  width: 100%;
  height: 5.8vh;
  background: url(../assets/images/header.png);
  background-size: 100% 100%;
  position: relative;
  .open-list,
  .enlarge {
    width: 1.6vw;
    height: 3vh;
    position: absolute;
    bottom: 0.2vh;
    cursor: pointer;
  }
  .open-list {
    left: 3.1vw;
  }
  .enlarge {
    left: 6.3vw;
  }
  .el-badge {
    position: absolute;
    left: 10vw;
    bottom: 0.2vh;
  }

  .notice_text {
    position: absolute;
    left: 13.8vw;
    bottom: 0.4vh;
    font-size: 0.8vw;
    color: #fff;
  }
  .el-switch {
    position: absolute;
    left: 17.5vw;
    bottom: 0.3vh;
  }

  .el-icon-bell {
    // position: absolute;
    // left: 10vw;
    // bottom: 0.2vh;
    color: #17e0fe;
    font-size: 1.3vw;
    cursor: pointer;
  }
  .time {
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 0.2vh;
    right: 11.5vw;
    .time-img {
      width: 1vw;
      height: 1.8vh;
      margin-right: 0.5vw;
    }
    .time-date {
      color: #fff;
      font-size: calc(100vw * 16 / 1920);
      margin-right: 0.5vw;
    }
  }
  .title {
    position: absolute;
    top: 0.3vh;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #17e0fe;
    font-size: calc(100vw * 23 / 1920);
    text-align: center;
    width: 100%;
    .title-img {
      width: 1.7vw;
      height: 3.6vh;
      margin-right: 1vw;
    }
  }
  .name {
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 0.2vh;
    right: 2.8vw;
    .name-left {
      width: 5vw;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: calc(100vw * 18 / 1920);
      color: #fff;
      margin-right: 0.5vw;
      cursor: pointer;
    }
    .name-right {
      font-size: calc(100vw * 16 / 1920);
      color: #17e0fe;
      cursor: pointer;
    }
  }
}
.header-second {
  width: 41.3vw;
  height: 3.1vh;
  margin: 1.2vh auto;
  display: flex;
  align-items: center;
  justify-content: space-around;
  .item {
    color: #17e0fe;
    font-size: calc(100vw * 16 / 1920);
    cursor: pointer;
  }
}
.el-menu::v-deep {
  background: none;
  color: #17e0fe;
  width: 9.9vw;
  border-right: 0;
  .el-menu--inline {
    width: 100%;
    background: none;
  }
  .el-submenu__title,
  .el-submenu__icon-arrow,
  .el-icon-arrow-down {
    color: #17e0fe;
    font-size: calc(100vw * 15 / 1920);
  }
  .el-menu-item {
    display: flex;
    flex-wrap: wrap;
    padding-left: 1vw !important;
    color: #17e0fe;
    min-width: 0;
  }
  .el-submenu__title {
    padding-left: 1vw !important;
    box-sizing: border-box;
  }
}
.el-tree::v-deep {
  width: 100%;
  height: 100%;
  background: #0c3688;
  color: #02b5ff;

  .el-tree-node__content {
    padding-left: 0.5vw;
    box-sizing: border-box;
  }
  // .el-tree-node__children {
  //   font-size: calc(100vw * 16 / 1920);
  // }
}
::v-deep .el-aside::-webkit-scrollbar {
  display: none;
}
</style>

<style lang="scss">
.el-tree-node__content:hover {
  background: transparent;
}
.el-tree[data-v-29e8c3c6] .el-tree-node__content {
  background: transparent !important;
}
.custom-tree-node {
  font-size: calc(100vw * 16 / 1920);
  color: #fff;
  &:hover {
    background: transparent;
  }
}
.el-tree-node .is-current > .el-tree-node__content {
  background: transparent !important;
}
.el-tree-node:focus > .el-tree-node__content {
  background: transparent !important;
}
.el-dropdown-link {
  cursor: pointer;
  color: #409eff;
}
// .item {
//   margin-top: 10px;
//   margin-right: 40px;
// }
.more {
  font-size: calc(100vw * 14 / 1920);
  margin-top: 1.5vh;
  color: #02b5ff;
  cursor: pointer;
}
</style>
