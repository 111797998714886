<template>
  <div class="container">
    <div class="form">
      <el-form :model="form" ref="form" :rules="rules">
        <el-form-item label="新密码：" prop="password">
          <el-input v-model="form.password" placeholder="请输入密码"></el-input>
        </el-form-item>
        <el-form-item label="确认新密码：" prop="newPassword">
          <el-input
            v-model="form.newPassword"
            placeholder="请确定新密码"
          ></el-input>
        </el-form-item>
      </el-form>
    </div>
    <div class="submit-btn" @click="modify('form')">修改</div>
  </div>
</template>

<script>
import { resetPsd } from "@/api/jurisdiction";
export default {
  name: "modifyPsd",
  data() {
    return {
      form: {
        password: "", // 密码
        newPassword: "",
      },
      rules: {
        password: [
          { required: true, message: "新密码不能为空", trigger: "blur" },
        ],
        newPassword: [
          { required: true, message: "确认新密码不能为空", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    modify(form) {
      this.$refs[form].validate((valid) => {
        if (valid) {
          if(this.form.password == this.form.newPassword){
            this.form.userId = this.$route.query.id * 1
          resetPsd(this.form).then((res) => {
            console.log(res);
            if (res.code == 200) {
              this.$message({
                showClose: false,
                message: "修改成功",
                type: "success",
              });
              this.$router.push("/userJurisdiction");
            }
          });
          }else {
            this.$message({
                showClose: false,
                message: "两次密码输入不正确",
                type: "warning",
              });
          }
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.container::v-deep {
  width: 100%;
  position: relative;
  .form {
    position: absolute;
    top: 3.7vh;
    left: 18.75vw;
    .el-form-item {
      display: flex;
      height: 4.6vh;
      align-items: center;
      margin-bottom: 3vh;
      .el-form-item__label {
        width: 7vw;
        text-align: left;
        color: #fff;
        font-size: calc(100vw * 16 / 1920);
      }
    }
    .el-input__inner {
      width: 20vw;
      height: 4.6vh;
      background: #0f2455;
      border: 1px solid #286ae8;
      color: #fff;
      font-size: calc(100vw * 16 / 1920);
      &::placeholder {
        color: #fff;
      }
    }
  }
  .submit-btn {
    position: absolute;
    top: 30vh;
    right: 18.75vw;
    color: #fff;
    width: 7.2vw;
    height: 4.6vh;
    background: url(@/assets/images/updown.png);
    background-size: 100% 100%;
    color: #02b5ff;
    font-size: calc(100vw * 18 / 1920);
    text-align: center;
    line-height: 4.6vh;
    cursor: pointer;
  }
}
::v-deep .el-tree-node:focus > .el-tree-node__content,
::v-deep .el-tree-node:hover > .el-tree-node__content {
  background-color: transparent !important;
}
::v-deep .el-input__prefix {
  left: 18vw;
  font-size: calc(100vw * 20 / 1920);
}
::v-deep .el-input--prefix .el-input__inner {
  padding-left: 0.8vw;
}
</style>
