import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import axios from "axios";
Vue.prototype.$axios = axios;

import utilsMethods from "@/utils/utils";
Vue.prototype.$utilsMethods = utilsMethods;

//  引入Echarts
import * as echarts from "echarts";
Vue.prototype.$echarts = echarts;

import VueAMap from "vue-amap";
Vue.use(VueAMap);
VueAMap.initAMapApiLoader({
  key: "d80a7a92446ba0532e1e9db497471994",
  plugin: [
    "AMap.Autocomplete",
    "AMap.PlaceSearch",
    "AMap.Scale",
    "AMap.OverView",
    "AMap.ToolBar",
    "AMap.MapType",
    "AMap.Geolocation",
    "AMap.Geocoder",
    "AMap.AMapManager",
    "AMap.Marker",
  ],
  v: "1.4.4",
  uiVersion: "1.0",
});

import "element-ui/lib/theme-chalk/index.css";
import {
  Form,
  FormItem,
  Input,
  Select,
  Option,
  Table,
  TableColumn,
  Pagination,
  DatePicker,
  Dialog,
  Button,
  Container,
  Aside,
  Header,
  Main,
  Checkbox,
  Tree,
  Menu,
  Submenu,
  MenuItem,
  MessageBox,
  Message,
  Upload,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Tabs,
  TabPane,
  Switch,
  Drawer,
  Progress,
  Loading,
  Badge,
  Notification,
} from "element-ui";

Vue.use(Form);
Vue.use(FormItem);
Vue.use(Input);
Vue.use(Select);
Vue.use(Option);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Pagination);
Vue.use(DatePicker);
Vue.use(Dialog);
Vue.use(Button);
Vue.use(Container);
Vue.use(Header);
Vue.use(Aside);
Vue.use(Main);
Vue.use(Checkbox);
Vue.use(Tree);
Vue.use(Menu);
Vue.use(MenuItem);
Vue.use(Submenu);
Vue.use(Upload);
Vue.use(Dropdown);
Vue.use(DropdownItem);
Vue.use(DropdownMenu);
Vue.use(Tabs);
Vue.use(TabPane);
Vue.use(Switch);
Vue.use(Drawer);
Vue.use(Progress);
Vue.use(Loading);
Vue.use(Badge);
Vue.prototype.$message = Message;
Vue.prototype.$msgbox = MessageBox;
Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$notify = Notification;

import { formateDate, division, formateMonth, formateYear } from "@/utils/date";
Vue.prototype.$formateDate = formateDate;
Vue.prototype.$division = division;
Vue.prototype.$formateMonth = formateMonth;
Vue.prototype.$formateYear = formateYear;

// 省市区三级联动
import VDistpicker from "v-distpicker";
Vue.component("v-distpicker", VDistpicker);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
