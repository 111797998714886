/*
 * @Author: ‘巴博尔’ 2164119982@qq.com
 * @Date: 2023-04-06 17:48:05
 * @LastEditors: ‘巴博尔’ 2164119982@qq.com
 * @LastEditTime: 2023-04-19 20:49:56
 * @FilePath: \guangfu-pc\src\api\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import requests from './request'

// 获取验证码
export function getCode(){
    return requests.get('/captchaImage')
}

// 登录
export function getLogin(data){
    return requests.post('/login',data)
}

// 获取用户信息
export function getUserInfo(){
    return requests.get('/getInfo')
}

// 获取用户路由信息
export function getUserRouter(){
    return requests.get('/getGfRouters')
}

// 新增企业信息
export function addCompanyInfo(data){
    return requests.post('/custom/enterprise',data)
}

// 修改企业信息
export function modifyCompanyInfo(data){
    return requests.put('/custom/enterprise',data)
}

// 获取企业信息列表
export function getCompanyInfoList(data){
    return requests.get('/custom/enterprise/list?pageNum=' + data.pageNum + '&pageSize=' + data.pageSize + '&etProvince=' + data.etProvince + '&etName=' + data.etName,data)
}

// 服务商
export function getRepairCompanyList(){
    return requests.get('/custom/enterprise/getCaseEmp')
}

// 获取电站信息列表
export function getStationInfoList(data){
    return requests.get('/custom/station/list?pageNum=' + data.pageNum + '&pageSize=' + data.pageSize + '&psProvince=' + data.psProvince + '&psName=' + data.psName + '&etId=' + data.etId,data)
}

// 新增电站信息
export function addStationInfo(data){
    return requests.post('/custom/station',data)
}

// 修改电站信息
export function modifyStationInfo(data){
    return requests.put('/custom/station',data)
}

// 删除企业
export function deleteCompany(id){
    return requests.get('/custom/enterprise/del/' + id)
}

// 删除电站
export function deleteStation(id){
    return requests.get('/custom/station/del/' + id)
}

// 修改密码获取验证码
export function getCodeM(useName){
    return requests.get('/custom/ccu/pho/getCodeMsg?useName=' + useName)
}

// 修改密码
export function modifyPassword(data){
    return requests.get('/custom/ccu/pho/saveCodeMsg?userName=' + data.userName + '&password=' + data.password + '&email=' + data.email,data)
}

// 登录后修改密码
export function modifyUserPass(data){
    console.log(data,'2222222')
    // return requests.put('/system/user/profile/updatePwd',data)
    return requests.get(`/system/user/profile/updatePwd?oldPassword=${data.oldPassword}&newPassword=${data.newPassword}`)
}

// 登录后修改基本资料
export function modifyUserInfo(data){
    return requests.put('/system/user/profile',data)
}

// 消息数量
export function getNotice(){
    return requests.get('/system/alerter/getViewAlerterCount')
}

// 消息列表
export function getNoticeList(){
    return requests.get('/system/alerter/getViewAlerter')
}
