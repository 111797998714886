import requests from "./request";

// 获取设备
export function getEquipmentList(data) {
  return requests.get(
    "/custom/type/list?pageNum=" + data.pageNum + "&pageSize=" + data.pageSize,
    data
  );
}

// 新增设备类型
export function addEquipment(data) {
  return requests.post("/custom/type", data);
}

// 修改设备类型
export function modifyEquipment(data) {
  return requests.put("/custom/type", data);
}

// 根据id获取设备详情
export function byEquipmentId(id) {
  return requests.get("/custom/type/" + id);
}

// 根据id删除设备
export function deleteEquipment(id) {
  return requests.get("/custom/type/del/" + id);
}

// 逆变器实时数据
export function getInverterData(data) {
  return requests.get(
    "/custom/data/list?pageNum=" +
      data.pageNum +
      "&pageSize=" +
      data.pageSize +
      "&mac=" +
      data.mac +
      "&sn=" +
      data.sn +
      "&bTime=" +
      data.bTime +
      "&eTime=" +
      data.eTime,
    data
  );
}

// 抄表器分页查询
export function getReaderEquipment(data) {
  return requests.get(
    "/custom/reading/list?pageNum=" +
      data.pageNum +
      "&pageSize=" +
      data.pageSize +
      "&title=" +
      data.title +
      "&mrType=" +
      data.mrType+
      "&mrMac=" +
      data.mrMac+
      "&mrMeCard=" +
      data.mrMeCard,
    data
  );
}

// 抄表器设备类型
export function getEquipmentType() {
  return requests.get("/custom/type/listAll");
}

// 抄表器设备删除
export function deleteReaderEquipment(id) {
  return requests.get("/custom/reading/del/" + id);
}

// 获取企业信息列表
export function getCompanyList() {
  return requests.get("/custom/enterprise/listAll");
}

// 获取电站列表
export function getStationList(id) {
  return requests.get("/custom/station/listAll/" + id);
}

// 新增抄表器
export function addNewReader(data) {
  return requests.post("/custom/reading", data);
}

// 根据id获取抄表器详情
export function getDetailReader(id) {
  return requests.get("/custom/reading/" + id);
}

// 修改抄表器
export function modifyReader(data) {
  return requests.put("/custom/reading", data);
}

// 逆变器查询
export function getInverter(data) {
  return requests.get(
    "/custom/inverter/list?pageNum=" +
      data.pageNum +
      "&pageSize=" +
      data.pageSize +
      "&title=" +
      data.title +
      "&itType=" +
      data.itType +
      "&itProvince=" +
      data.itProvince +
      "&itCity=" +
      data.itCity +
      "&itArea=" +
      data.itArea +
      "&itMac=" +
      data.itMac +
      "&itMeCard=" +
      data.itMeCard,
    data
  );
}

// 根据电站id查询表名称
export function getWattHourMeter(id) {
  return requests.get("/custom/reading/listAll/" + id);
}

// 逆变器新增
export function addNewInverter(data) {
  return requests.post("/custom/inverter", data);
}

// 逆变器修改
export function modifyInverter(data) {
  return requests.put("/custom/inverter", data);
}

// 逆变器查看详情
export function getInverterDetail(id) {
  return requests.get("/custom/inverter/" + id);
}

// 逆变器设备删除
export function deleteInverterEquipment(id) {
  return requests.get("/custom/inverter/del/" + id);
}

// 电价查询
export function getElePrice(data) {
  return requests.get(
    "/custom/price/list?pageNum=" +
      data.pageNum +
      "&pageSize=" +
      data.pageSize +
      "&title=" +
      data.title,
    data
  );
}

// 新增电价
export function addElePrice(data) {
  return requests.post("/custom/price", data);
}

// 修改电价
export function modifyElePrice(data) {
  return requests.put("/custom/price", data);
}

// 获取电价详情
export function getPriceDetail(id) {
  return requests.get("/custom/price/" + id);
}

// 删除电价
export function deletePrice(id) {
  return requests.get("/custom/price/del/" + id);
}

// 抄表器实时数据
export function getReaderData(data) {
  return requests.get(
    "/custom/cbq/groupList?pageNum=" +
      data.pageNum +
      "&pageSize=" +
      data.pageSize +
      "&mac=" +
      data.mac +
      "&bTime=" +
      data.bTime +
      "&enTime=" +
      data.enTime +
      "&title=" +
      data.title,
    data
  );
}

// 抄表器实时数据升级
export function getReaderDataNew(data) {
  return requests.get(
    "/custom/cbq/gfList?pageNum=" +
      data.pageNum +
      "&pageSize=" +
      data.pageSize +
      "&mac=" +
      data.mac +
      "&bTime=" +
      data.bTime +
      "&eTime=" +
      data.eTime +
      "&title=" +
      data.title +
      "&sn=" +
      data.sn,
    data
  );
}

// 查询规则列表
const regulationListGET = (params) => {
  return requests.get("/custom/electric/regulation/list", {
    params,
  });
};

// 查询标准规则
const regulationStandardGET = (params) => {
  return requests.get("/custom/electric/regulation/standard", {
    params,
  });
};

// 新增映射规则
const regulationPOST = (params) => {
  return requests.post("/custom/electric/regulation", params);
};

// 删除规则
const regulationDELETE = (params) => {
  return requests.delete(`/custom/electric/regulation/${params.id}`);
};

// 修改映射规则
const regulationPUT = (params) => {
  return requests.put("/custom/electric/regulation", params);
};

// 获取指定ID规则信息
const electricRegulationGET = (params) => {
  return requests.get(`/custom/electric/regulation/${params.id}`, {
    params,
  });
};

// 规则列表下拉菜单数据接口
const regulationSelectListGET = () => {
  return requests.get(`/custom/electric/regulation/select/list`);
};

export {
  regulationSelectListGET,
  electricRegulationGET,
  regulationListGET,
  regulationStandardGET,
  regulationPOST,
  regulationDELETE,
  regulationPUT,
};
