<template>
  <div class="container">
    <div class="equipment-title">抄表器</div>
    <div class="form-content">
      <el-form :model="form" style="margin-left: 5.9vw; margin-bottom: 1.8vh">
        <div style="display: flex">
          <el-form-item label="节点MAC码：">
            <el-input v-model="form.MAC" style="width: 16.5vw" placeholder="请输入节点MAC码"></el-input>
          </el-form-item>
          <el-form-item label="全局搜索：">
            <el-input
              style="width: 16.5vw"
              v-model="form.searchContent"
              placeholder="请输入电站名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="sn：">
            <el-input
              style="width: 16.5vw"
              v-model="form.sn"
              placeholder="请输入表号"
            ></el-input>
          </el-form-item>
        </div>
        <div style="display: flex">
          <el-form-item label="上报时间：">
            <el-date-picker
              style="width: 11vw; margin-right: 2vw"
              v-model="form.beginTime"
              type="date"
              format="yyyy-MM-dd"
              :picker-options="pickerOptions0"
              placeholder="开始日期"
            >
            </el-date-picker>
            <el-date-picker
              style="width: 11vw"
              v-model="form.endTime"
              type="date"
              format="yyyy-MM-dd"
              :picker-options="pickerOptions1"
              placeholder="结束日期"
            >
            </el-date-picker>
          </el-form-item>
          <div class="add-btn" @click="query">查询</div>
          <div class="add-btn" @click="reset">重置</div>
          <div class="add-btn">导出</div>
        </div>
      </el-form>
    </div>
    <div class="bottom-part">
      <el-table
        :data="readerList"
        :header-cell-style="headerStyle"
        :cell-style="rowStyle"
      >
        <el-table-column type="index" label="序号" width="60"></el-table-column>
        <el-table-column
          prop="gr.gfPowerStation.psName"
          label="电站名称"
        ></el-table-column>
        <el-table-column prop="gr.mrMac" label="节点MAC码"></el-table-column>
        <el-table-column label="设备类型">
          <template slot-scope="scope">
            <span v-if="scope.row.gr != null && scope.row.gr.mrWhType == '1'">总电表</span>
            <span v-else-if="scope.row.gr != null && scope.row.gr.mrWhType == '2'">光伏电表</span>
            <span v-else></span>
          </template>
        </el-table-column>
        <el-table-column prop="gr.mrMeName" label="设备名称"></el-table-column>
        <el-table-column prop="sn" label="表号"></el-table-column>
        <el-table-column label="电表状态">
          <template slot-scope="scope">
            <span v-if="scope.row.gr != null && scope.row.gr.sta == 0">在线</span>
            <span v-else-if="scope.row.gr != null && scope.row.gr.sta == 1">离线</span>
            <span v-else></span>
          </template>
        </el-table-column>
        <el-table-column prop="psInverterCount" label="链接状态">
          <template slot-scope="scope">
            <span v-if="scope.row.gr != null && scope.row.gr.sta == 0">在线</span>
            <span v-else-if="scope.row.gr != null && scope.row.gr.sta == 1">离线</span>
            <span v-else></span>
          </template></el-table-column
        >
        <el-table-column
          prop="gr.mrMagnification"
          label="倍率"
        ></el-table-column>
        <el-table-column prop="teStr" label="时段"></el-table-column>
        <el-table-column prop="v" label="正向总电量"></el-table-column>
        <el-table-column label="上报时间">
          <template slot-scope="scope">
            {{ scope.row.date }} {{ scope.row.time }}
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[6, 12, 18, 24]"
        :page-size="100"
        layout="sizes, prev, pager, next , total"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { getReaderDataNew } from "@/api/equipment";
export default {
  name: "realTimeMeterReader",
  data() {
    return {
      pickerOptions0: {
        disabledDate: (time) => {
            return time.getTime() > Date.now();
        },
      },
      pickerOptions1: {
        disabledDate: (time) => {
          return (
              time.getTime() < this.form.beginTime || time.getTime() > Date.now()
          );
        },
      },
      form: {
        searchContent: "", // 全局搜索
        MAC: "", // MAC
        beginTime: "",
        endTime: "",
        sn: "",
      },
      currentPage: 1,
      pageSize: 6,
      total: 0,
      readerList: [],
      num: 0,
      NOW_MONTHS_AGO: "", // 当前日期
    };
  },
  methods: {
    headerStyle() {
      return "background:#0F2455;color:#fff;border: 1px solid #286AE8;text-align:center";
    },
    rowStyle() {
      return "background:#0F2455;color:#fff;border: 1px solid #286AE8;text-align:center";
    },
    handleSizeChange(val) {
      this.pageSize = val
      this.getList();
    },
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage;
      this.getList();
    },
    formateDate(datetime) {
      function addDateZero(num) {
        return num < 10 ? "0" + num : num;
      }
      let d = new Date(datetime);
      let formatdatetime =
        d.getFullYear() +
        "-" +
        addDateZero(d.getMonth() + 1) +
        "-" +
        addDateZero(d.getDate());
      return formatdatetime;
    },
    getList() {
      console.log(this.NOW_MONTHS_AGO, "是多久啊是");
      let date = new Date()
      if(this.form.beginTime != ''){
        this.form.beginTime = this.formateDate(this.form.beginTime);
      }else {
        this.form.beginTime = this.formateDate(date)
      }
      if(this.form.endTime != ''){
        this.form.endTime = this.formateDate(this.form.endTime);
      }else {
        this.form.endTime = this.formateDate(date)
      }

      let data = {
        pageNum: this.currentPage,
        pageSize: this.pageSize,
        mac: this.form.MAC,
        bTime: this.form.beginTime,
        eTime: this.form.endTime,
        title: this.form.searchContent,
        sn: this.form.sn,
      };
      getReaderDataNew(data).then((res) => {
        console.log(res, "晒得是哦");
        this.readerList = res.rows;
        this.total = res.total;
      });
    },
    // 查询
    query() {
      this.currentPage = 1
      this.getList();
      console.log(this.NOW_MONTHS_AGO, "的话会的撒");
    },
    // 重置
    reset() {
      this.form.searchContent = "";
      this.form.beginTime = "";
      this.form.endTime = "";
      this.form.MAC = "";
      this.form.sn = "";
      this.getNowDate();
      this.form.beginTime = this.NOW_MONTHS_AGO;
      this.form.endTime = this.NOW_MONTHS_AGO;
      this.getList();
    },
    // 获取当前时间
    getNowDate() {
      const timeOne = new Date();
      const year = timeOne.getFullYear();
      let month = timeOne.getMonth() + 1;
      let day = timeOne.getDate();
      month = month < 10 ? "0" + month : month;
      day = day < 10 ? "0" + day : day;
      this.NOW_MONTHS_AGO = `${year}-${month}-${day}`;
      // return NOW_MONTHS_AGO
      console.log(this.NOW_MONTHS_AGO, "和杜萨会对撒");
    },
  },
  mounted() {
    this.getNowDate();
    this.form.beginTime = this.NOW_MONTHS_AGO;
    this.form.endTime = this.NOW_MONTHS_AGO;
    this.getList();
  },
};
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  height: 100%;
  padding-top: 2vh;
  box-sizing: border-box;
  position: relative;
  .equipment-title {
    position: absolute;
    left: 0;
    background: url(@/assets/images/titleBg.png);
    background-size: 100% 100%;
    width: 11.1vw;
    height: 5.5vh;
    color: #fff;
    font-size: calc(100vw * 20 / 1920);
    text-align: center;
    line-height: 5.5vh;
    margin-bottom: 1.8vh;
  }
  .form-content {
    position: absolute;
    left: 6vw;
    display: flex;
    flex-wrap: wrap;
    .add-btn {
      width: 7.2vw;
      height: 4.6vh;
      background: url(@/assets/images/updown.png);
      background-size: 100% 100%;
      margin-right: 1.5vw;
      text-align: center;
      line-height: 4.6vh;
      color: #02b5ff;
      font-size: calc(100vw * 18 / 1920);
      cursor: pointer;
    }
    // table {
    //   width: 75.5vw;
    //   box-shadow: inset 0rem 0rem 0.33rem 0.35rem rgba(15, 53, 116, 0.6);
    //   border: 0.02rem solid #286ae8;
    //   margin-bottom: 1.5vh;
    //   color: #fff;
    //   font-size: calc(100vw * 16 / 1920);
    //   border-collapse: collapse;
    //   text-align: center;
    //   tr {
    //     height: 5vh;
    //   }
    // }
  }
  .bottom-part {
    width: 75.7vw;
    position: absolute;
    top: 16vh;
    left: 0.6vw;
    right: 0;
    margin: 0 auto;
    .el-table {
      margin-bottom: 1vh;
    }
  }
}
.el-form-item::v-deep {
  display: flex;
  height: 4.6vh;
  align-items: center;
  margin-right: 1vw;
  .el-form-item__label {
    width: 6.9vw;
    text-align: left;
    color: #fff;
    font-size: calc(100vw * 16 / 1920);
  }
  .el-input__inner {
    height: 4.6vh;
    background: #0f2455;
    border: 1px solid #286ae8;
    color: #fff;
    font-size: calc(100vw * 16 / 1920);
    border-radius: 6px;
    &::placeholder {
      color: #fff;
    }
  }
}

::v-deep .el-table {
  width: 99.9%;
  margin-bottom: 2.5vh;
  font-size: calc(100vw * 16 / 1920);
}
::v-deep .el-table__body {
  font-size: calc(100vw * 14 / 1920);
}

::v-deep .el-pagination {
  width: 75.5vw;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1vh;
}
::v-deep .el-pagination .el-select .el-input .el-input__inner,
::v-deep .el-pagination .btn-next,
::v-deep .el-pagination .btn-prev,
::v-deep .el-pager li,
::v-deep .el-pager li.btn-quicknext,
::v-deep .el-pager li.btn-quickprev,
::v-deep .el-pagination__total {
  background: #0f2455;
  border-radius: 6px;
  border: 1px solid #286ae8;
  color: #fff;
  text-align: center;
}
::v-deep .el-pager li,
::v-deep .el-pagination .btn-next,
::v-deep .el-pagination .btn-prev {
  margin-right: 0.5vw;
}
::v-deep .el-pagination__total {
  margin-right: 0;
  padding: 0 0.8vw;
  box-sizing: border-box;
}
.el-table--border::after,
.el-table--group::after,
.el-table::before {
  display: none;
}
::v-deep .el-pager li.active {
  color: #409EFF;
}
</style>
